@use "../global/" as g;

.c-text-link {
  color: g.$blue02;
  position: relative;

  a {
    transition: 0.3s ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: g.$blue02;
    transition: 0.3s ease-in-out;
  }

  &:hover {
    &::after {
      opacity: 0.8;
    }
  }
}
