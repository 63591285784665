@use "../global/"as g;

.c-slist01 {
  display: flex;
  flex-wrap: wrap;

  @include g.mq('md') {
    flex-wrap: nowrap;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin: 0 0 50px 0;
    border-bottom: solid 1px #D1D4D7;
    width: 100%;

    @include g.mq('md') {
      margin: 0 2% 0 0;
      width: 32%;

      img {
        transition: all 0.5s;
      }

      &:hover {
        img {
          transform: scale(1.2);
        }

        .c-slist01__arrow {
          transform: translateX(5px);
        }
      }
    }

    @include g.mq('lg') {
      margin: 0 3.45% 0 0;
      width: 22.414%;
    }

    &:last-child {
      margin: 0;
    }
  }

  &__img {
    position: relative;
    overflow: hidden;
    text-align: center;

    @include g.mq('md') {
      height: 146px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 0;
    padding: 15px 0;

    @include g.mq('md') {
      padding: 21px 0 17px;
      height: calc(100% - 146px);
    }

    .c-circle-arrow01__arrow {
      transition: all 0.5s;
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      @include g.mq('md') {
        width: 34px;
        height: 34px;
      }

      &::after {
        top: calc(50% - 3px);
        left: calc(50% - 10px);
      }
    }
  }

  &__txt {
    display: inline-block;
    line-height: 1.4em;
    color: #333;
    font-weight: bold;
    letter-spacing: 0.02em;
    font-size: g.rem(16);

    @include g.mq('lg') {
      font-size: g.rem(18);
    }
  }

  &.line01 {
    .c-slist01__info {
      @include g.mq('md') {
        padding: 30px 0 25px;
      }
    }
  }
}

.c-slist02 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include g.mq('md') {
    justify-content: center;
  }
  &.no-desc{
    .c-slist02__img{
      margin-bottom: 0;
    }
  }
  &.has-5item {
    .c-slist02__item {
      @include g.mq('md') {
        padding: 16px 19px;
      }
      @include g.mq('lg') {
        width: 24.075%;
        min-height: 405px;
        margin: 0 1.205% 34px 0;
      }
      &:last-child {
        @include g.mq('lg') {
          margin: 0 0 34px 0;
        }
      }
      &:nth-child(2n + 2) {
        @include g.mq('md') {
          margin: 0 0 34px 0;
  
          &::after {
            content: none;
          }
        }
  
        @include g.mq('lg') {
          margin:0 1.121% 34px 0;
  
          &::after {
            content: '';
          }
        }
      }
      &:nth-child(4n + 4) {
        @include g.mq('lg') {
          margin: 0 0 34px 0;
  
          &::after {
            content: none;
          }
        }
      }
    }
  }
  &__item {
    position: relative;
    width: 89.66%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin: 0 auto 14px;
    border: solid 1px #2995E5;

    * {
      position: relative;
      z-index: 2;
    }


    @include g.mq('md') {
      padding: 16px 14px;
      width: 47%;
      margin: 0 13px 34px 0;
    }
   
    @include g.mq('lg') {
      margin-bottom: 34px;
      width: 24.138%;
      margin:0 1.121% 34px 0;
    }
    &:nth-child(2n + 2) {
      @include g.mq('md') {
        margin: 0 0 34px 0;

        &::after {
          content: none;
        }
      }

      @include g.mq('lg') {
        margin:0 1.121% 34px 0;

        &::after {
          content: '';
        }
      }
    }
    &:nth-child(4n + 4) {
      @include g.mq('lg') {
        margin: 0 0 34px 0;

        &::after {
          content: none;
        }
      }
    }
    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      background: #fff;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    &::after {
      position: absolute;
      content: '';
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      background: #2995E5;
      z-index: -1;
      width: 1px;
      height: calc(100% + 30px);

      @include g.mq('md') {
        left: 0;
        top: 50%;
        width: 150%;
        height: 1px;
        transform: translate(0, -50%);
      }
    }

    &:last-child {
      margin: 0 auto;
      @include g.mq('md') {
        margin: 0 0 34px 0;
      }
      &::after {
        content: none;
      }
    }
  }

  &__ttl {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .ttl,
    .num {
      font-weight: bold;
      letter-spacing: 0.02em;
      display: inline-block;
    }

    .ttl {
      text-align: center;
      position: relative;
      color: #005BAC;
      font-size: g.rem(20);
      line-height: 1.54em;
      width: 100%;
    }

    .num {
      text-align: left;
      position: absolute;
      left:0;
      top: -2px;
      color: #2995E5;
      font-family: g.$JosefinSlab;
      font-size: g.rem(40);
      line-height: 1em;
    }
  }

  &__img {
    width: 100%;
    text-align: center;
    margin: 0 auto 15px;

    @include g.mq('md') {
      margin: 0 auto 21px;
    }
  }

  &__desc {
    .desc {
      color: #232536;
      font-size: g.rem(14);
      font-weight: 400;
      letter-spacing: 0.02em;
      line-height: 1.7em;

      @include g.mq('md') {
        font-size: g.rem(16);
      }
    }
  }
}