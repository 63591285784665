@use "../global/" as g;

.c-slist-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include g.mq('sm') {
        flex-wrap: nowrap;
    }

    @include g.mq('md') {
        justify-content: center;
    }

    .c-link-btn01 {
        max-width: 100%;
        margin: 0 0 20px;

        @include g.mq('sm') {
            max-width: 49%;
        }

        @include g.mq('md') {
            margin: 0 20px;
            max-width: 400px;
            transition: all 0.5s ease;
            &:hover{
                .c-link-btn01__link-box{
                    background-color: #fff;
                    color: #005BAC;
                    border: solid 1px #005BAC;
                    &::after{
                        border-color: #005BAC;
                    }
                }
            }
        }

        &:last-child {
            margin: 0;

            @include g.mq('md') {
                margin: 0 20px;
            }
        }

        &.icon-pdf,  &.icon-pdf01 {
            .c-link-btn01__link-box::after {
                border: 0;
                width: 20px;
                height: 21px;
                top: 50%;
                right: 25px;
                transform: translateY(-50%);
                @include g.mq('md') {
                    right: 20px;
                    width: 24px;
                    height: 25px;
                }
                @include g.mq('lg') {
                    right: 32px;
                }
            }
       
        }
        &.icon-pdf{
            .c-link-btn01__link-box::after {
                background: url("../../images/common/icon_pdf.svg") no-repeat center/contain;
            }
            @include g.mq('md'){
                &:hover{
                    .c-link-btn01__link-box::after{
                        background: url("../../images/common/icon_pdf_01.svg") no-repeat center/contain;
                    }
                }
            }
        }
        &.icon-pdf01 {
            .c-link-btn01__link-box::after {
                background: url("../../images/common/icon_pdf_01.svg") no-repeat center/contain;
            }
            @include g.mq('md'){
                &:hover{
                    .c-link-btn01__link-box::after{
                        background: url("../../images/common/icon_pdf.svg") no-repeat center/contain;
                    }
                }
            }
        }
        &.icon-play {
            .c-link-btn01__link-box::after {
                border: 0;
                background: url("../../images/common/icon_play.svg") no-repeat center/contain;
                width: 28px;
                height: 19px;
                top: 50%;
                right: 25px;
                transform: translateY(-50%); 
                @include g.mq('md') {
                    right: 29px;
                    width: 30px;
                    height: 21px;
                }
            }
            @include g.mq('md'){
                &:hover{
                    .c-link-btn01__link-box::after{
                        background: url("../../images/common/icon_play_01.svg") no-repeat center/contain;
                    }
                }
            }
        }
        &.icon-blank{
            @include g.mq('md'){
                &:hover{
                    .c-link-btn02__link-box{
                        background-color: #005BAC;
                        border-color: #fff;
                        color: #fff;
                        &::after{
                            background: url("../../images/common/icon_blank-white.svg") no-repeat center/contain;
                        }
                    }
                }
            }
        }
    }
    .c-link-btn01__link-box {
        background-color: #005BAC;
        border: solid 1px #005BAC;
        color: #fff;
        padding: 10px;
        letter-spacing: 0.02em;
        font-size: g.rem(16);
        &::after{
            border-color: #fff;
        }
        @include g.mq('md') {
            padding: 16px;
            font-size: g.rem(18);
        }
    }
}
