@use "../global/" as g;

.c-stbl {
  &__row {
    display: flex;
    flex-wrap: wrap;
    &:first-child {
      .c-stbl__head {
        border-top: solid 1px #d1d4d7;
      }
      .c-stbl__head,
      .c-stbl__content {
        @include g.mq("md") {
          border-top: solid 1px #d1d4d7;
        }
      }
    }
    @include g.mq("md") {
      flex-wrap: nowrap;
    }
  }
  &__head,
  &__content {
    width: 100%;
    padding: 15px;
    border-bottom: solid 1px #d1d4d7;
  }
  &__content {
    margin-top: -15px;

    .p-logistics-system-specification__tbl & {
      margin-top: 0px;
    }

    @include g.mq(md) {
      margin-top: 0;
    }
  }
  &__head {
    border-bottom: none;
    @include g.mq("md") {
      border-bottom: solid 1px #d1d4d7;
      width: 24%;
      padding: 39px 40px 38px;
    }
    .head {
      font-family: "Noto Sans JP", sans-serif;
      display: inline-block;
      font-size: g.rem(14);
      font-weight: 500;
      letter-spacing: 0.02em;
      color: #005bac;
      line-height: 1.5em;
      @include g.mq("md") {
        font-size: g.rem(16);
      }
    }
    &.bg01 {
      background: #f8faf9;
    }
  }
  &__content {
    @include g.mq("md") {
      width: 76%;
      padding: 41px 20px 37px;
    }
    .desc {
      font-size: g.rem(14);
      font-weight: 400;
      letter-spacing: 0.02em;
      color: #232536;
      line-height: 1.5em;
      margin-bottom: 8px;
      @include g.mq("md") {
        margin-bottom: 10px;
        font-size: g.rem(16);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .txt-medium {
    font-weight: 500;
  }
  &__style01 {
    .c-stbl__head {
      border-left: solid 1px #d1d4d7;
      border-right: solid 1px #d1d4d7;
      border-bottom: solid 1px #d1d4d7;
    }
    .c-stbl__content {
      border-left: solid 1px #d1d4d7;
      border-right: solid 1px #d1d4d7;
      @include g.mq("md") {
        border-left: 0;
      }
    }
  }
  &__blocks {
    width: 100%;
    &.has-2block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include g.mq("md") {
        flex-wrap: nowrap;
      }
      .c-stbl__block {
        width: 100%;
        margin-bottom: 15px;
        @include g.mq("md") {
          width: 48.3%;
          margin-bottom: 0;
        }
      }
    }
  }
  &__block {
    width: 100%;
    margin-bottom: 15px;
    @include g.mq("md") {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.w50 {
      @include g.mq("md") {
        width: 48.3%;
      }
    }
    &.w75 {
      @include g.mq("md") {
        width: 75.3%;
      }
    }
    &.w80 {
      @include g.mq("md") {
        width: 83.5%;
      }
    }
  }
  &__style02 {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    th,
    td {
      font-family: "Noto Sans JP", sans-serif;
      font-size: g.rem(14);
      letter-spacing: 0.02em;
      line-height: 1.5em;
      padding: 15px;
      border-spacing: 0;
      border-top: solid 1px #d1d4d7;
      border-right: solid 1px #d1d4d7;
      vertical-align: middle;
      @include g.mq("md") {
        font-size: g.rem(16);
      }
      &.color01 {
        color: #232536;
      }
    }
    th {
      background: #f8faf9;
      color: #005bac;
      font-weight: 500;
      text-align: center;
      @include g.mq("xl") {
        padding: 15px 29px 15px;
      }
      &:first-child {
        text-align: left;
        border-left: solid 1px #d1d4d7;
      }
    }
    td {
      background: #fff;
      font-weight: 400;
      color: #232536;

      @include g.mq("xl") {
        padding: 15px 19px 15px;
      }
    }
    tr {
      &:first-child {
        th {
          border-top: solid 1px #d1d4d7;
        }
      }
      &:last-child {
        th,
        td {
          border-bottom: solid 1px #d1d4d7;
        }
      }
    }
  }
  &__note {
    font-size: g.rem(14);
    font-weight: 400;
    color: #232536;
    letter-spacing: 0.02em;
    line-height: 1.5em;
    margin: 10px 0 0;
    @include g.mq("md") {
      font-size: g.rem(16);
      margin: 15px 0 0;
    }
    &.color01 {
      color: #ff0000;
    }
  }
  &__note01 {
    font-weight: 400;
    font-size: g.rem(12);
    color: #232536;
    letter-spacing: 0.02em;
    line-height: 2.67em;
    margin-bottom: 5px;
    @include g.mq("md") {
      display: none;
    }

    &.color02 {
      color: #8e8e8e;
    }
  }
  &__scroll {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 15px;
    &::-webkit-scrollbar {
      width: 100%;
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #eee;
    }
    &::-webkit-scrollbar-thumb {
      background: #005bac;
    }
    @include g.mq("md") {
      margin-bottom: 0;
      overflow: hidden;
      padding-bottom: 0;
    }
    table {
      width: 1000px;
      @include g.mq("md") {
        width: 100%;
      }
    }
    figure {
      width: 767px;
      position: relative;
      @include g.mq("sm") {
        width: 100%;
      }
      &::before {
        content: "※ 横スクロールでご覧いただけます。";
        font-size: g.rem(12);
        color: #8e8e8e;
        position: absolute;
        top: 10px;
        left: 5px;
        line-height: 1;
        @include g.mq("sm") {
          content: "";
        }
      }
    }
  }
}
