@use "../../global/" as g;

.p-business-content {
  &__header {
    @include g.mq(md) {
      display: flex;
    }
  }
  &__title {
    color: g.$white;
    flex: 1;
  }

  &__header-text {
    color: g.$white;
    font-size: g.rem(14);
    letter-spacing: 0.02em;
    @include g.lh(18, 36);
    margin-top: 20px;

    @include g.mq(md) {
      width: calc(700 / 1160 * 100%);
      font-size: g.rem(18);
      margin-top: 0;
    }
  }

  &__main {
    margin-top: 40px;

    @include g.mq(md) {
      margin-top: 85px;
    }
  }

  &__main-lists {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;

    @include g.mq(md) {
      column-gap: calc(40 / 1160 * 100%);
      row-gap: 40px;
    }
  }

  &__main-item {
    @include g.mq(md) {
      width: calc(560 / 1160 * 100%);
      background-color: g.$white;

      &:nth-of-type(3) {
        .c-list-item01__body {
          padding-bottom: 14px;
        }
      }
    }
  }

  //   ボタン

  &__link-area {
    margin-top: 40px;

    @include g.mq(md) {
      margin-top: 80px;
    }
  }
}
