@use "../../global/" as g;

.p-company-content {
  &__title {
    color: g.$white;
  }

  &__link-lists {
    margin-top: 40px;

    @include g.mq(md) {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
    }
  }

  &__link-item {
    border-bottom: 1px solid g.$white;
    padding-bottom: 20px;

    + .p-company-content__link-item {
      margin-top: 12px;

      @include g.mq(md) {
        margin-top: 0;
      }
    }

    @include g.mq(md) {
      width: calc(350 / 1160 * 100%);
      padding-bottom: 25px;
    }

    &:hover {
      .c-circle-arrow01 {
        transform: translateX(5px);
      }
    }
  }

  &__link-item-box {
    position: relative;
    font-weight: 700;
    font-size: g.rem(18);
    letter-spacing: 0.02em;
    color: g.$white;

    .c-circle-arrow01__arrow {
      background-color: g.$white;

      &::after {
        border-bottom: 1px solid g.$blue02;
        border-right: 1px solid g.$blue02;
      }
    }
  }
}
