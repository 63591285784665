@use "sass:map"; // mapを使用する際に記述する

// //メディアクエリの設定
// /* ブレークポイント
// _______________________________________________________________________________________________________*/ 

$breakpoints: (
  'xs': (min-width: 0), // モバイル(0〜767),
  'sm': (min-width: 576px), 
  'md': (min-width: 768px), // タブレット(768〜1024),
  'lg': (min-width: 1025px), // デスクトップ(1025〜)
  'xl': (min-width: 1200px),
  'xxl': (min-width: 1400px)
) !default;


@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map.get($breakpoints, $breakpoint))} {
      @content;
    }
  }
 
  // マップ型で定義されていない値が呼び出された時はエラーを返す
  @else {
    @error "指定されたブレークポイントは定義されていません。" + "指定できるブレークポイントは次のとおりです。 -> #{map-keys($breakpoints)}";
  }
  
}






