@use "../../global/" as g;

.p-case-content {
  &__header {
    @include g.mq(md) {
      display: flex;
    }
  }

  &__title {
    flex: 1;
    margin-top: 16px;
  }

  &__title-en {
    color: g.$white;

    @include g.mq(md) {
      color: g.$blue02;
    }
  }
  &__title-ja {
    color: g.$white;
    @include g.mq(md) {
      color: g.$black-main;
    }
  }

  &__header-text {
    color: g.$white;
    letter-spacing: 0.02em;
    @include g.lh(18, 36);
    margin-top: 20px;

    @include g.mq(md) {
      width: calc(700 / 1160 * 100%);
      font-size: g.rem(18);
      margin-top: 0;
    }
  }

  &__main {
    margin-top: 32px;

    @include g.mq(md) {
      margin-top: 54px;
    }
  }

  &__slider-area {
    @include g.mq(md) {
      margin-right: calc(50% - 50vw);
    }
  }

  &__item {
    // .c-list-item02__title {
    //   min-height: 60px;
    // }

    // .c-list-item02__text {
    //   min-height: 140px;

    //   @include g.mq(md) {
    //     min-height: 160px;
    //   }
    // }

    // .c-list-item02__category {
    //   min-height: 114px;
    //   @include g.mq(md) {
    //     min-height: 84px;
    //   }
    // }
    .c-list-item02__category {
      position: static;
    }
    .c-circle-arrow01 {
      bottom: 20px;
      right: 20px;

      @include g.mq(md) {
        bottom: 32px;
        right: 38px;
      }
    }
  }

  //スライダーを上書き
  &__slider {
    .slick-list {
      @include g.mq(md) {
        padding-right: 24.5%;
        padding-right: max(50px, calc(100vw / 1600 * 340));
      }
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      height: auto !important;
      background-color: g.$white;
      position: relative;
      @include g.mq(md) {
        margin-right: 40px;
      }
    }
  }

  &__bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    @include g.mq(md) {
      margin-top: 60px;
    }
  }

  &__link-area {
    flex: 1;

    .c-link-btn01 {
      @include g.mq(md) {
        margin-right: 0;
      }
    }
  }

  //arrow
  &__arrow {
    display: none;

    @include g.mq(md) {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .slick-num {
      font-family: g.$ZenKaku;
      order: 2;
      font-size: g.rem(20);
      letter-spacing: 0.02em;
      color: g.$blue02;
    }

    .slick-next {
      right: -0;
      order: 3;
    }
    .slick-prev,
    .slick-next {
      position: static;
      position: relative;
      display: inline-block;
      width: 60px;
      height: 60px;
      background-color: g.$blue02;
      border-radius: 50%;
      top: 0;
      transform: translate(0, 0);
      transition: 0.4s ease-in-out;

      &:hover {
        opacity: 0.8;
      }

      &::before {
        content: "";
      }

      &::after {
        content: "";
        position: absolute;
        top: calc(50% - 2.5px);
        left: calc(50% - 10px);
        display: inline-block;
        width: 15px;
        height: 5px;
        border-bottom: 1px solid g.$white;
        border-right: 1px solid g.$white;
        transform: skew(45deg);
      }
    }

    .slick-prev {
      left: 0;
      order: 1;

      &::after {
        border-left: 1px solid g.$white;
        border-bottom: 1px solid g.$white;
        border-right: none;
        transform: skew(-45deg);
        left: calc(50% - 7.5px);
      }
    }
  }
}
