@use "../global/" as g;

.c-circle-arrow01 {
  position: absolute;
  right: 0;
  transition: 0.4s ease-in-out;

  &__arrow {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: g.$blue02;
    border-radius: 50%;

    @include g.mq(md) {
      width: 34px;
      height: 34px;
    }

    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 2.5px);
      left: calc(50% - 8.5px);
      display: inline-block;
      width: 12px;
      height: 5px;
      border-bottom: 1px solid g.$white;
      border-right: 1px solid g.$white;
      transform: skew(45deg);

      @include g.mq(md) {
        top: calc(50% - 2.5px);
        left: calc(50% - 10px);
        width: 15px;
        height: 5px;
      }
    }
  }
}
