@use "../global/" as g;

.c-circle-arrow03 {
  position: absolute;
  right: 0;
  transition: 0.4s ease-in-out;

  &__arrow {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: g.$blue02;
    border-radius: 50%;

    @include g.mq(md) {
      width: 34px;
      height: 34px;
    }

    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 4px);
      left: calc(50% - 6px);
      display: inline-block;
      width: 12px;
      height: 7px;
      background: url("../../images/common/icon_prev-arrow.svg") no-repeat
        center;
      background-size: cover;

      @include g.mq(md) {
        top: calc(50% - 5.5px);
        left: calc(50% - 7px);
        width: 14px;
        height: 9px;
      }
    }
  }
}
