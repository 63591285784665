@use "../../global/" as g;

.l-business {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 50px;
  background: url("../../images/top/img_business-bk.webp") no-repeat center
    bottom;
  background-size: 100%;
  background-color: g.$blue02;

  @include g.mq(md) {
    padding-top: 150px;
    padding-bottom: 120px;
  }

  &__inner {
    position: relative;
    z-index: 2;
  }

  //   bk パス
  &__bk-content01 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    top: -10.5vw;
    right: -25vw;
    transform: rotate(-6deg);

    @include g.mq(md) {
      top: -3.5vw;
      right: -22vw;
    }

    svg {
      // width: 100vw;
      aspect-ratio: 750 / 287;
      width: clamp(400px, calc(100vw / 1600 * 750), 750px);
    }

    // @include g.mq(lg) {
    //   top: -8%;
    //   left: -28%;
    // }
  }

  &__bk-content02 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    top: -5vw;
    right: -25vw;
    transform: rotate(-1deg);

    @include g.mq(md) {
      top: -1.5vw;
      right: -19vw;
    }
    svg {
      // width: 100vw;
      aspect-ratio: 786 / 158.5;
      // width: calc(100vw / 1600 * 800);
      width: clamp(400px, calc(100vw / 1600 * 786), 786px);
    }
  }
}
