@use "sass:math";
@use "../global/" as g;

/*!
component > cookiewrap
------------------------------
*/

#cookiewrap {
  position: fixed;
  bottom: 0;
  left: 0;
  background: g.$blue02;
  color: g.$white;
  z-index: 999;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  display: none;

  @include g.mq(md) {
    padding: 40px;
  }

  .inner {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    .close {
      position: absolute;
      right: 0;
      bottom: 0;
      text-align: right;
      font-size: rem(14);
      line-height: 1em;
      @include g.mq(md) {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
      }

      a {
        display: block;
        padding: 8px;
        border: 1px solid g.$white;
      }
    }
    .txt {
      font-size: rem(14);
      line-height: 1.8;
      padding-bottom: 45px;
      @include g.mq(md) {
        padding-bottom: 0;
        padding-right: 110px;
      }
    }
  }
}
