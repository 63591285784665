@use "../global/" as g;

.c-slist-dot{
    > li{
        position:relative;
        line-height: 1.7em;
        color: #232536;
        letter-spacing: 0.02em;
        padding-left: 22px;
        font-size: g.rem(14);
        margin-bottom: 5px;
        font-weight: 500;
        @include g.mq('md'){
            margin-bottom: 7px;
            padding-left: 18px;
            font-size: g.rem(16);
        }
        &:last-child{
            margin-bottom: 0;
        }
        &::before{
            position: absolute;
            content: '';
            left:0;
            top: 7px;
            width: 8px;
            height: 8px;
            background: #005BAC;
            border-radius: 100%;
            @include g.mq('md'){
                top: 10px;
            }
        }
    }
    &-group {
        display: flex;
        flex-wrap: wrap;
        @include g.mq('md'){
            flex-wrap: nowrap;
        }
        &.has-2block{
            @include g.mq('md'){
                justify-content: space-between;
            }
            .c-slist-dot{
                margin:0 10px 5px 0;
                padding-right: 5px;
                &:last-child{
                    margin: 0;
                    padding-right: 0;
                }
                @include g.mq('md'){
                    width: 46%;
                    margin: 0;
                    padding-right: 0;
                }
            }
        }
    }
    &.has-bullet{
        > li{
            margin-bottom: 5px;
            padding-left: 20px;
            font-weight: 400;
            @include g.mq('md') {
                padding-left: 26px;
                margin-bottom: 7px;
            }
            &::before{
                content: '※';
                font-size: g.rem(14);
                font-weight: 400;
                letter-spacing: 0.02em;
                color: #232536;
                width: auto;
                height: auto;
                background: none;
                top: 0;
                left: 0;
                @include g.mq('md') {
                    font-size: g.rem(16);
                }
            }
        } 
    }
}