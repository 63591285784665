@charset "UTF-8";
/*--------------------------------------------------------------
* フォントの読み込み
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* 使用font の変数
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* フォントサイズ の変数
----------------------------------------------------------------*/
/* pc
_______________________________________________________________________________________________________*/
/* sp
_______________________________________________________________________________________________________*/
/* =========================================================================================================
	共通定義する値
========================================================================================================= */
/* コンテンツ幅
_______________________________________________________________________________________________________*/
/* 使用色
_______________________________________________________________________________________________________*/
/* イージング
_______________________________________________________________________________________________________*/
/*
 * Easing function
 * Original：http://easings.net/
 */
/*--------------------------------------------------------------
* globalフォルダの_index.scss
----------------------------------------------------------------*/
.l-mv {
  position: relative;
  min-height: calc(100vh - 75px);
  background-color: #f8faf9;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .l-mv {
    min-height: auto;
    height: auto;
  }
}
@media screen and (min-width: 768px) {
  .l-mv__sp-bk {
    display: none;
  }
}
.l-mv__sp-bk-content01 {
  position: absolute;
  z-index: 1;
  bottom: -10vw;
  right: -72vw;
}
.l-mv__sp-bk-content01 svg {
  transform: rotate(-3deg);
  aspect-ratio: 479/150;
  width: clamp(479px, 29.9375vw, 479px);
}
.l-mv__sp-bk-content02 {
  position: absolute;
  z-index: 1;
  bottom: -16vw;
  right: -36vw;
}
.l-mv__sp-bk-content02 svg {
  transform: rotate(-7deg);
  aspect-ratio: 801/269;
  width: clamp(400px, 50.0625vw, 801px);
}
.l-mv__pc-bk {
  display: none;
}
@media screen and (min-width: 768px) {
  .l-mv__pc-bk {
    display: block;
  }
}
.l-mv__bk-content01 svg {
  aspect-ratio: 801/269;
  width: clamp(400px, 50.0625vw, 801px);
}
@media screen and (min-width: 768px) {
  .l-mv__bk-content01 {
    position: absolute;
    z-index: 1;
    top: -3vw;
    left: -18.5vw;
    transform: rotate(-1deg);
  }
}
@media screen and (min-width: 1025px) {
  .l-mv__bk-content01 {
    top: -3vw;
    left: -18.5vw;
  }
}
@media screen and (min-width: 1200px) {
  .l-mv__bk-content01 {
    top: -2vw;
    left: -18.5vw;
  }
}
@media screen and (min-width: 1400px) {
  .l-mv__bk-content01 {
    top: -1.5vw;
    left: -18.5vw;
  }
}
.l-mv__bk-content02 svg {
  aspect-ratio: 794.96/172.74;
  width: clamp(400px, 49.6875vw, 795px);
}
@media screen and (min-width: 768px) {
  .l-mv__bk-content02 {
    position: absolute;
    z-index: 1;
    top: -3.5vw;
    left: -9.5vw;
  }
}
@media screen and (min-width: 1025px) {
  .l-mv__bk-content02 {
    top: -3.5vw;
    left: -9.5vw;
  }
}
@media screen and (min-width: 1200px) {
  .l-mv__bk-content02 {
    top: -2.5vw;
    left: -9.5vw;
  }
}
@media screen and (min-width: 1400px) {
  .l-mv__bk-content02 {
    top: -2.5vw;
    left: -9.5vw;
  }
}

.ball {
  opacity: 0;
  visibility: hidden;
  animation: fadeIn 4s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  80% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.l-business {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 50px;
  background: url("../../images/top/img_business-bk.webp") no-repeat center bottom;
  background-size: 100%;
  background-color: #005bac;
}
@media screen and (min-width: 768px) {
  .l-business {
    padding-top: 150px;
    padding-bottom: 120px;
  }
}
.l-business__inner {
  position: relative;
  z-index: 2;
}
.l-business__bk-content01 {
  position: absolute;
  z-index: 1;
  top: -10.5vw;
  right: -25vw;
  transform: rotate(-6deg);
}
@media screen and (min-width: 768px) {
  .l-business__bk-content01 {
    top: -3.5vw;
    right: -22vw;
  }
}
.l-business__bk-content01 svg {
  aspect-ratio: 750/287;
  width: clamp(400px, 46.875vw, 750px);
}
.l-business__bk-content02 {
  position: absolute;
  z-index: 1;
  top: -5vw;
  right: -25vw;
  transform: rotate(-1deg);
}
@media screen and (min-width: 768px) {
  .l-business__bk-content02 {
    top: -1.5vw;
    right: -19vw;
  }
}
.l-business__bk-content02 svg {
  aspect-ratio: 786/158.5;
  width: clamp(400px, 49.125vw, 786px);
}

.l-product {
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: #f8faf9;
}
@media screen and (min-width: 768px) {
  .l-product {
    padding-top: 118px;
    padding-bottom: 180px;
  }
}
.l-product__top {
  position: relative;
  overflow: hidden;
}
.l-product__bottom {
  position: relative;
  z-index: 2;
}
.l-product__top-inner {
  position: relative;
  z-index: 2;
}
.l-product__bk-content01 {
  position: absolute;
  z-index: 1;
  bottom: -50vw;
  right: -60vw;
  transform: rotate(-15deg);
  display: none;
}
@media screen and (min-width: 768px) {
  .l-product__bk-content01 {
    display: block;
    bottom: -31vw;
    right: -18vw;
    transform: rotate(-15deg);
  }
}
@media screen and (min-width: 1200px) {
  .l-product__bk-content01 {
    display: block;
    bottom: -31vw;
    right: -14vw;
    transform: rotate(-15deg);
  }
}
.l-product__bk-content01 svg {
  aspect-ratio: 407/785;
  width: clamp(400px, 25.4375vw, 407px);
}
.l-product__bk-content02 {
  position: absolute;
  z-index: 1;
  bottom: -50vw;
  right: -30vw;
  transform: rotate(-11deg);
  display: none;
}
@media screen and (min-width: 768px) {
  .l-product__bk-content02 {
    display: block;
    bottom: -25vw;
    right: -7vw;
    transform: rotate(-11deg);
  }
}
.l-product__bk-content02 svg {
  aspect-ratio: 225/770;
  width: clamp(200px, 14.0625vw, 225px);
}
.l-product__bk-content03 {
  position: absolute;
  z-index: 1;
  bottom: -30vw;
  right: -17vw;
  transform: rotate(-47deg);
  display: none;
}
@media screen and (min-width: 768px) {
  .l-product__bk-content03 {
    display: block;
    bottom: -22vw;
    right: -17vw;
    transform: rotate(-47deg);
  }
}
.l-product__bk-content03 svg {
  aspect-ratio: 407/785;
  width: clamp(400px, 25.4375vw, 407px);
}
.l-product__bk-content04 {
  position: absolute;
  z-index: 1;
  bottom: -30vw;
  right: -8vw;
  transform: rotate(-36deg);
  display: none;
}
@media screen and (min-width: 768px) {
  .l-product__bk-content04 {
    display: block;
    bottom: -21vw;
    right: -8vw;
    transform: rotate(-31deg);
  }
}
.l-product__bk-content04 svg {
  aspect-ratio: 225/770;
  width: clamp(200px, 14.0625vw, 225px);
}

.l-case {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 60px;
  background: url("../../images/top/img_case-bk.webp") no-repeat right bottom;
  background-size: 100%;
  background-color: #005bac;
  z-index: 0;
}
@media screen and (min-width: 768px) {
  .l-case {
    display: block;
    padding-top: 73px;
    padding-bottom: 80px;
    background-size: 65%;
  }
}
@media screen and (min-width: 768px) {
  .l-case::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #f8faf9;
    width: 35%;
  }
}
.l-case__inner {
  position: relative;
  z-index: 2;
}
.l-case__bk {
  display: none;
}
@media screen and (min-width: 768px) {
  .l-case__bk {
    display: block;
  }
}
.l-case__bk-content01 {
  position: absolute;
  z-index: 1;
  bottom: -9vw;
  left: 0vw;
  transform: rotate(11deg);
}
@media screen and (min-width: 768px) {
  .l-case__bk-content01 {
    bottom: -9vw;
    left: 14vw;
    transform: rotate(11deg);
  }
}
.l-case__bk-content01 svg {
  aspect-ratio: 749/287;
  width: clamp(400px, 46.8125vw, 749px);
}
.l-case__bk-content02 {
  position: absolute;
  z-index: 1;
  bottom: -3vw;
  left: 0vw;
  transform: rotate(2deg);
  display: none;
}
@media screen and (min-width: 768px) {
  .l-case__bk-content02 {
    display: block;
    bottom: -3vw;
    left: 15vw;
  }
}
.l-case__bk-content02 svg {
  aspect-ratio: 795/172.8;
  width: clamp(400px, 49.6875vw, 795px);
}

.l-about {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: #f8faf9;
}
@media screen and (min-width: 768px) {
  .l-about {
    padding-top: 205px;
    padding-bottom: 166px;
  }
}
.l-about__bk-content01 {
  position: absolute;
  z-index: 1;
  bottom: -49vw;
  left: -41vw;
  transform: rotate(-139deg);
}
.l-about__bk-content01 svg {
  aspect-ratio: 1572/1556;
  width: clamp(400px, 98.25vw, 1572px);
}
.l-about__bk-content02 {
  position: absolute;
  z-index: 1;
  bottom: -46vw;
  left: -48vw;
  transform: rotate(-144deg);
}
.l-about__bk-content02 svg {
  aspect-ratio: 1502/1515;
  width: clamp(400px, 93.875vw, 1502px);
}

.l-company {
  position: relative;
  overflow: hidden;
  background: url("../../images/top/bk_company.webp") no-repeat right bottom;
  background-size: 100%;
  background-color: #005bac;
  padding-top: 50px;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .l-company {
    padding-top: 138px;
    padding-bottom: 125px;
  }
}
.l-company__inner {
  position: relative;
  z-index: 3;
}
.l-company__bk-content01 {
  position: absolute;
  z-index: 1;
  top: -11vw;
  right: -20vw;
  transform: rotate(1deg);
}
@media screen and (min-width: 768px) {
  .l-company__bk-content01 {
    top: -8vw;
    right: -16vw;
  }
}
.l-company__bk-content01 svg {
  aspect-ratio: 749/287;
  width: clamp(400px, 46.8125vw, 749px);
}
.l-company__bk-content02 {
  position: absolute;
  z-index: 1;
  top: -10vw;
  right: -30vw;
  transform: rotate(-1deg);
}
@media screen and (min-width: 768px) {
  .l-company__bk-content02 {
    top: -2vw;
    right: -19vw;
  }
}
.l-company__bk-content02 svg {
  aspect-ratio: 786/158.5;
  width: clamp(400px, 49.125vw, 786px);
}

.l-recruitment {
  position: relative;
  background: url("../../images/top/bk_recruitment.webp") no-repeat right bottom;
  background-size: 130%;
  background-position: right top;
  padding-top: 93px;
  padding-bottom: 85px;
}
@media screen and (min-width: 768px) {
  .l-recruitment {
    background-size: cover;
  }
}

.l-news {
  position: relative;
  overflow: hidden;
  background-size: 100%;
  background-color: #f8faf9;
  padding-top: 50px;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .l-news {
    padding-top: 174px;
    padding-bottom: 160px;
  }
}
.l-news__inner {
  position: relative;
  z-index: 3;
}
.l-news__bk-content01 {
  position: absolute;
  z-index: 1;
  bottom: -5vw;
  right: 3vw;
  transform: rotate(-11deg);
}
.l-news__bk-content01 svg {
  aspect-ratio: 531/2591;
  width: clamp(400px, 33.1875vw, 531px);
}
.l-news__bk-content02 {
  position: absolute;
  z-index: 1;
  bottom: 0vw;
  right: 3vw;
  transform: rotate(-11deg);
}
.l-news__bk-content02 svg {
  aspect-ratio: 388/2244;
  width: clamp(200px, 24.25vw, 2244px);
}

/*--------------------------------------------------------------
* layoutフォルダの_index.scss
----------------------------------------------------------------*/
/*!
component > cookiewrap
------------------------------
*/
#cookiewrap {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #005bac;
  color: #ffffff;
  z-index: 999;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  display: none;
}
@media screen and (min-width: 768px) {
  #cookiewrap {
    padding: 40px;
  }
}
#cookiewrap .inner {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
#cookiewrap .inner .close {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
  font-size: rem(14);
  line-height: 1em;
}
@media screen and (min-width: 768px) {
  #cookiewrap .inner .close {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}
#cookiewrap .inner .close a {
  display: block;
  padding: 8px;
  border: 1px solid #ffffff;
}
#cookiewrap .inner .txt {
  font-size: rem(14);
  line-height: 1.8;
  padding-bottom: 45px;
}
@media screen and (min-width: 768px) {
  #cookiewrap .inner .txt {
    padding-bottom: 0;
    padding-right: 110px;
  }
}

/*!
component > Loading
------------------------------
*/
.is-loadding .c_loading {
  opacity: 1;
  pointer-events: all;
}

.c_loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #535763;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
}
.c_loading__content {
  position: relative;
  -webkit-transform: translate(-15px, -15px);
  transform: translate(-15px, -15px);
}
.c_loading__item {
  background-color: #fff;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  margin-left: 15px;
  top: 4px;
  left: -7px;
}
.c_loading__item:first-child {
  -webkit-animation: ball-zig 0.7s 0s infinite linear;
  animation: ball-zig 0.7s 0s infinite linear;
}
.c_loading__item:last-child {
  -webkit-animation: ball-zag 0.7s 0s infinite linear;
  animation: ball-zag 0.7s 0s infinite linear;
}

@-webkit-keyframes ball-zig {
  33% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px);
  }
  66% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes ball-zig {
  33% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px);
  }
  66% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes ball-zag {
  33% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px);
  }
  66% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes ball-zag {
  33% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px);
  }
  66% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.c-icon-blank {
  position: relative;
}
.c-icon-blank::after {
  content: "";
  display: inline-block;
  background: url("../../images/common/icon_blank.svg") no-repeat center;
  background-size: cover;
  width: 20px;
  height: 15px;
}

.c-hamburger-menu {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 25px;
  cursor: pointer;
  transition: all 0.4s;
}
@media screen and (min-width: 768px) {
  .c-hamburger-menu {
    width: 40px;
    height: 30px;
  }
}
.c-hamburger-menu span {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #005bac;
  transition: all 0.4s;
}
.c-hamburger-menu__line01 {
  top: 0;
}
.c-hamburger-menu__line02 {
  top: 11px;
}
@media screen and (min-width: 768px) {
  .c-hamburger-menu__line02 {
    top: 13.5px;
  }
}
.c-hamburger-menu__line03 {
  bottom: 0px;
}
.is-nav-open .c-hamburger-menu__line01 {
  transform: translateY(11px) rotate(-315deg);
}
@media screen and (min-width: 768px) {
  .is-nav-open .c-hamburger-menu__line01 {
    transform: translateY(13.5px) rotate(-315deg);
  }
}
.is-nav-open .c-hamburger-menu__line02 {
  opacity: 0;
}
.is-nav-open .c-hamburger-menu__line03 {
  transform: translateY(-11px) rotate(315deg);
}
@media screen and (min-width: 768px) {
  .is-nav-open .c-hamburger-menu__line03 {
    transform: translateY(-13.5px) rotate(315deg);
  }
}

.c-page-top-btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 200;
  visibility: hidden;
  opacity: 0;
  transform: translateY(200px);
  transition: 0.4s ease-in-out;
}
@media screen and (min-width: 768px) {
  .c-page-top-btn {
    right: 50px;
    bottom: 20px;
  }
}
.c-page-top-btn.is-show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.is-nav-open .c-page-top-btn {
  visibility: hidden;
  opacity: 0;
  transform: translateY(200px);
}
.is-loadding .c-page-top-btn {
  visibility: hidden;
  opacity: 0;
  transform: translateY(200px);
}
.c-page-top-btn__body {
  display: block;
  background-color: #005bac;
  width: 50px;
  height: 50px;
  position: relative;
  transition: 0.3s ease-in;
}
@media screen and (min-width: 768px) {
  .c-page-top-btn__body {
    width: 80px;
    height: 80px;
  }
}
.c-page-top-btn__body::before {
  content: "";
  background: url("../../images/common/icon_arrow-white.svg") no-repeat center;
  background-size: contain;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}
@media screen and (min-width: 768px) {
  .c-page-top-btn__body::before {
    width: 30px;
    height: 30px;
  }
}

.c-content-width01 {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .c-content-width01 {
    max-width: 1240px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.c-content-width-mv {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .c-content-width-mv {
    padding-left: 65px;
    padding-right: 65px;
  }
}

.c-section-title {
  display: flex;
  flex-direction: column;
}
.c-section-title__en {
  text-transform: uppercase;
  font-family: "Josefin Slab", serif;
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.2;
}
@media screen and (min-width: 768px) {
  .c-section-title__en {
    font-size: 3.4375rem;
    font-size: clamp(1.875rem, 4.7413793103vw, 3.4375rem);
  }
}
.c-section-title__ja {
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: 1.125rem;
}
@media screen and (min-width: 768px) {
  .c-section-title__ja {
    font-size: 1.5rem;
    font-size: clamp(1.125rem, 2.0689655172vw, 1.5rem);
  }
}

.c-list-item01:hover img {
  transform: scale(1.1);
}
.c-list-item01:hover a {
  opacity: 1;
}
.c-list-item01:hover .c-circle-arrow01 {
  transform: translateX(5px);
}
.c-list-item01__img {
  overflow: hidden;
}
.c-list-item01__img img {
  transition: 0.4s ease-in-out;
}
.c-list-item01__body {
  background-color: #ffffff;
  letter-spacing: 0.02em;
  padding: 20px 20px 24px;
}
@media screen and (min-width: 768px) {
  .c-list-item01__body {
    padding: 36px 40px 46px;
  }
}
.c-list-item01__title {
  font-weight: 700;
  font-size: 1rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .c-list-item01__title {
    font-size: 1.25rem;
  }
}
.c-list-item01__text {
  line-height: 2;
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  .c-list-item01__text {
    font-size: 1rem;
  }
}

.c-list-item02:hover img {
  transform: scale(1.1);
}
.c-list-item02:hover a {
  opacity: 1;
}
.c-list-item02:hover .c-circle-arrow01 {
  transform: translateX(5px);
}
.c-list-item02__img {
  overflow: hidden;
}
.c-list-item02__img img {
  transition: 0.4s ease-in-out;
  object-fit: cover;
  aspect-ratio: 480/280;
}
.c-list-item02__body {
  background-color: #ffffff;
  letter-spacing: 0.02em;
  padding: 20px 20px 30px;
}
@media screen and (min-width: 768px) {
  .c-list-item02__body {
    padding: 32px 40px 55px;
  }
}
.c-list-item02__title {
  font-weight: 700;
  font-size: 1.125rem;
  position: relative;
  color: #005bac;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .c-list-item02__title {
    font-size: 1.25rem;
  }
}
.c-list-item02__text {
  line-height: 2;
  margin-top: 8px;
}
@media screen and (min-width: 768px) {
  .c-list-item02__text {
    font-size: 1rem;
    margin-top: 12px;
  }
}
.c-list-item02__category {
  margin-top: 16px;
  position: relative;
  padding-right: 34px;
}
.c-list-item02__facilities, .c-list-item02__product {
  color: #8e8e8e;
}

.c-circle-arrow01 {
  position: absolute;
  right: 0;
  transition: 0.4s ease-in-out;
}
.c-circle-arrow01__arrow {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #005bac;
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .c-circle-arrow01__arrow {
    width: 34px;
    height: 34px;
  }
}
.c-circle-arrow01__arrow::after {
  content: "";
  position: absolute;
  top: calc(50% - 2.5px);
  left: calc(50% - 8.5px);
  display: inline-block;
  width: 12px;
  height: 5px;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  transform: skew(45deg);
}
@media screen and (min-width: 768px) {
  .c-circle-arrow01__arrow::after {
    top: calc(50% - 2.5px);
    left: calc(50% - 10px);
    width: 15px;
    height: 5px;
  }
}

.c-circle-arrow02 {
  position: relative;
  right: 0;
  transition: 0.4s ease-in-out;
}
.c-circle-arrow02__arrow {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
}
.c-circle-arrow02__arrow::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 1.5px);
  background: url(../../images/common/icon_blank-white.svg) no-repeat center;
  background-size: cover;
  width: 20px;
  height: 15px;
  margin-left: 8px;
}

.c-circle-arrow03 {
  position: absolute;
  right: 0;
  transition: 0.4s ease-in-out;
}
.c-circle-arrow03__arrow {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #005bac;
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .c-circle-arrow03__arrow {
    width: 34px;
    height: 34px;
  }
}
.c-circle-arrow03__arrow::after {
  content: "";
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 6px);
  display: inline-block;
  width: 12px;
  height: 7px;
  background: url("../../images/common/icon_prev-arrow.svg") no-repeat center;
  background-size: cover;
}
@media screen and (min-width: 768px) {
  .c-circle-arrow03__arrow::after {
    top: calc(50% - 5.5px);
    left: calc(50% - 7px);
    width: 14px;
    height: 9px;
  }
}

.c-link-btn01 {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}
.c-link-btn01:hover a {
  opacity: 1;
}
.c-link-btn01:hover .c-link-btn01__link-box {
  background-color: #005bac;
  color: #ffffff;
  border: #ffffff 1px solid;
}
.c-link-btn01:hover .c-link-btn01__link-box::after {
  transform: skew(45deg) translateX(5px);
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
.c-link-btn01__link-box {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  color: #005bac;
  font-weight: 700;
  position: relative;
  font-size: 1rem;
  padding: 8px 0;
  border-radius: 35px;
  border: #005bac 1px solid;
  transition: 0.4s ease-in-out;
}
@media screen and (min-width: 768px) {
  .c-link-btn01__link-box {
    font-size: 1.125rem;
    padding: 16px 0;
  }
}
.c-link-btn01__link-box::after {
  content: "";
  position: absolute;
  top: calc(50% - 6px);
  right: 8.5%;
  display: inline-block;
  width: 27px;
  height: 10px;
  border-bottom: 1px solid #005bac;
  border-right: 1px solid #005bac;
  -webkit-transform: skew(45deg);
  transform: skew(45deg);
  transition: 0.4s ease-in-out;
}
.c-link-btn02 {
  max-width: 400px;
  width: 100%;
}
.c-link-btn02:hover a {
  opacity: 1;
}
.c-link-btn02:hover .c-link-btn02__link-box {
  background-color: #005bac;
  color: #ffffff;
  border: #005bac 1px solid;
}
.c-link-btn02:hover .c-link-btn02__link-box::after {
  background: url(../../images/common/icon_blank-blue.svg) no-repeat center;
}
.c-link-btn02__link-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: #005bac;
  border: #ffffff 1px solid;
  font-weight: 700;
  position: relative;
  font-size: 1rem;
  padding: 8px 0;
  border-radius: 35px;
  transition: 0.4s ease-in-out;
}
@media screen and (min-width: 768px) {
  .c-link-btn02__link-box {
    font-size: 1.125rem;
    padding: 16px 0;
  }
}
.c-link-btn02__link-box::after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 26px;
  top: calc(50% - 7.5px);
  background: url(../../images/common/icon_blank.svg) no-repeat center;
  background-size: cover;
  width: 20px;
  height: 15px;
  transition: 0.4s ease-in-out;
}

.c-sbtn__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: solid 1px #d1d4d7;
}
@media screen and (min-width: 768px) {
  .c-sbtn__link {
    min-width: 145px;
    padding-bottom: 15px;
  }
  .c-sbtn__link:hover .c-circle-arrow01__arrow {
    transform: translateX(5px);
  }
  .c-sbtn__link:hover .c-circle-arrow03__arrow {
    transform: translateX(-5px);
  }
}
.c-sbtn__link .c-circle-arrow01__arrow {
  transition: 0.4s ease-in-out;
}
.c-sbtn__link .c-circle-arrow01__arrow::after {
  top: calc(50% - 3px);
  left: calc(50% - 9px);
}
.c-sbtn__link .c-circle-arrow03__arrow {
  transition: 0.4s ease-in-out;
}
.c-sbtn__txt {
  color: #333;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  padding-right: 19px;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .c-sbtn__txt {
    font-size: 1.125rem;
  }
}
.c-sbtn__product {
  margin: 45px 0 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .c-sbtn__product {
    margin: 100px 0 0;
  }
}

.c-section-stitle .en {
  position: relative;
  text-transform: uppercase;
  font-family: "Josefin Slab", serif;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.2;
  color: #005BAC;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 4px;
}
@media screen and (min-width: 768px) {
  .c-section-stitle .en {
    padding-left: 26px;
    margin-bottom: 12px;
    font-size: 1.25rem;
    font-size: clamp(1.125rem, 1.724137931vw, 1.25rem);
  }
}
.c-section-stitle .en::before {
  position: absolute;
  content: "";
  border-radius: 100%;
  border: solid 2px #005BAC;
  width: 10px;
  height: 10px;
  top: 5px;
  left: 0;
}
@media screen and (min-width: 768px) {
  .c-section-stitle .en::before {
    border: solid 3px #005BAC;
    width: 16px;
    height: 16px;
    top: 2px;
    left: 0;
  }
}
.c-section-stitle .jp {
  display: block;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-size: 1.5rem;
  color: #232536;
  line-height: 1.5em;
}
@media screen and (min-width: 768px) {
  .c-section-stitle .jp {
    font-size: 2rem;
    font-size: clamp(1.5rem, 2.7586206897vw, 2rem);
  }
}
.c-section-stitle .c-section-title__en {
  color: #005BAC;
}
.c-section-stitle01 {
  position: relative;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.7em;
  padding-bottom: 10px;
  color: #005BAC;
}
@media screen and (min-width: 768px) {
  .c-section-stitle01 {
    padding-bottom: 16px;
    font-size: 2rem;
  }
}
.c-section-stitle01::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #005BAC 50%, #78C1F7 50%) no-repeat;
}
@media screen and (min-width: 768px) {
  .c-section-stitle01::after {
    bottom: -1px;
  }
}
.c-section-stitle01__txt {
  display: inline-block;
}
.c-section-stitle01.has-label .c-section-stitle01__txt {
  margin-right: 7px;
}
@media screen and (min-width: 768px) {
  .c-section-stitle01.has-label .c-section-stitle01__txt {
    margin-right: 17px;
  }
}
.c-section-stitle01.has-label .c-section-stitle01__label {
  bottom: 5px;
}
@media screen and (min-width: 768px) {
  .c-section-stitle01.has-label .c-section-stitle01__label {
    bottom: 7px;
    min-width: 85px;
  }
}
.c-section-stitle01.has-txtsmall .c-section-stitle01__txt {
  margin-right: 2px;
}
.c-section-stitle01__label {
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  border: solid 1px #005BAC;
  border-radius: 5px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .c-section-stitle01__label {
    padding: 4px 8px 5px;
  }
}
.c-section-stitle01__label .txt {
  display: inline-block;
  line-height: 1.2em;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #005BAC;
}
@media screen and (min-width: 768px) {
  .c-section-stitle01__label .txt {
    font-size: 1rem;
  }
}
.c-section-stitle01__txtsmall {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.7em;
}
@media screen and (min-width: 768px) {
  .c-section-stitle01__txtsmall {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  .c-section-stitle01__txtsmall {
    line-height: 2.77em;
  }
}
.c-section-stitle02 {
  color: #005BAC;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 1.7em;
}
@media screen and (min-width: 768px) {
  .c-section-stitle02 {
    font-size: 1.25rem;
  }
}
.c-section-stitle02.color01 {
  color: #232536;
}
.c-section-stitle03 {
  position: relative;
  color: #005BAC;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  margin-bottom: 5px;
  padding-left: 20px;
}
@media screen and (min-width: 768px) {
  .c-section-stitle03 {
    padding-left: 20px;
    margin-bottom: 10px;
    font-size: 1rem;
  }
}
.c-section-stitle03::before {
  position: absolute;
  content: "";
  top: 7px;
  left: 0;
  width: 8px;
  height: 8px;
  background: #005BAC;
}
@media screen and (min-width: 768px) {
  .c-section-stitle03::before {
    width: 10px;
    height: 10px;
    top: 8px;
  }
}

.c-stbl__row {
  display: flex;
  flex-wrap: wrap;
}
.c-stbl__row:first-child .c-stbl__head {
  border-top: solid 1px #d1d4d7;
}
@media screen and (min-width: 768px) {
  .c-stbl__row:first-child .c-stbl__head,
.c-stbl__row:first-child .c-stbl__content {
    border-top: solid 1px #d1d4d7;
  }
}
@media screen and (min-width: 768px) {
  .c-stbl__row {
    flex-wrap: nowrap;
  }
}
.c-stbl__head, .c-stbl__content {
  width: 100%;
  padding: 15px;
  border-bottom: solid 1px #d1d4d7;
}
.c-stbl__content {
  margin-top: -15px;
}
.p-logistics-system-specification__tbl .c-stbl__content {
  margin-top: 0px;
}
@media screen and (min-width: 768px) {
  .c-stbl__content {
    margin-top: 0;
  }
}
.c-stbl__head {
  border-bottom: none;
}
@media screen and (min-width: 768px) {
  .c-stbl__head {
    border-bottom: solid 1px #d1d4d7;
    width: 24%;
    padding: 39px 40px 38px;
  }
}
.c-stbl__head .head {
  font-family: "Noto Sans JP", sans-serif;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #005bac;
  line-height: 1.5em;
}
@media screen and (min-width: 768px) {
  .c-stbl__head .head {
    font-size: 1rem;
  }
}
.c-stbl__head.bg01 {
  background: #f8faf9;
}
@media screen and (min-width: 768px) {
  .c-stbl__content {
    width: 76%;
    padding: 41px 20px 37px;
  }
}
.c-stbl__content .desc {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #232536;
  line-height: 1.5em;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .c-stbl__content .desc {
    margin-bottom: 10px;
    font-size: 1rem;
  }
}
.c-stbl__content .desc:last-child {
  margin-bottom: 0;
}
.c-stbl .txt-medium {
  font-weight: 500;
}
.c-stbl__style01 .c-stbl__head {
  border-left: solid 1px #d1d4d7;
  border-right: solid 1px #d1d4d7;
  border-bottom: solid 1px #d1d4d7;
}
.c-stbl__style01 .c-stbl__content {
  border-left: solid 1px #d1d4d7;
  border-right: solid 1px #d1d4d7;
}
@media screen and (min-width: 768px) {
  .c-stbl__style01 .c-stbl__content {
    border-left: 0;
  }
}
.c-stbl__blocks {
  width: 100%;
}
.c-stbl__blocks.has-2block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .c-stbl__blocks.has-2block {
    flex-wrap: nowrap;
  }
}
.c-stbl__blocks.has-2block .c-stbl__block {
  width: 100%;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .c-stbl__blocks.has-2block .c-stbl__block {
    width: 48.3%;
    margin-bottom: 0;
  }
}
.c-stbl__block {
  width: 100%;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .c-stbl__block {
    margin-bottom: 20px;
  }
}
.c-stbl__block:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .c-stbl__block.w50 {
    width: 48.3%;
  }
}
@media screen and (min-width: 768px) {
  .c-stbl__block.w75 {
    width: 75.3%;
  }
}
@media screen and (min-width: 768px) {
  .c-stbl__block.w80 {
    width: 83.5%;
  }
}
.c-stbl__style02 {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}
.c-stbl__style02 th,
.c-stbl__style02 td {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  padding: 15px;
  border-spacing: 0;
  border-top: solid 1px #d1d4d7;
  border-right: solid 1px #d1d4d7;
  vertical-align: middle;
}
@media screen and (min-width: 768px) {
  .c-stbl__style02 th,
.c-stbl__style02 td {
    font-size: 1rem;
  }
}
.c-stbl__style02 th.color01,
.c-stbl__style02 td.color01 {
  color: #232536;
}
.c-stbl__style02 th {
  background: #f8faf9;
  color: #005bac;
  font-weight: 500;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .c-stbl__style02 th {
    padding: 15px 29px 15px;
  }
}
.c-stbl__style02 th:first-child {
  text-align: left;
  border-left: solid 1px #d1d4d7;
}
.c-stbl__style02 td {
  background: #fff;
  font-weight: 400;
  color: #232536;
}
@media screen and (min-width: 1200px) {
  .c-stbl__style02 td {
    padding: 15px 19px 15px;
  }
}
.c-stbl__style02 tr:first-child th {
  border-top: solid 1px #d1d4d7;
}
.c-stbl__style02 tr:last-child th,
.c-stbl__style02 tr:last-child td {
  border-bottom: solid 1px #d1d4d7;
}
.c-stbl__note {
  font-size: 0.875rem;
  font-weight: 400;
  color: #232536;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  margin: 10px 0 0;
}
@media screen and (min-width: 768px) {
  .c-stbl__note {
    font-size: 1rem;
    margin: 15px 0 0;
  }
}
.c-stbl__note.color01 {
  color: #ff0000;
}
.c-stbl__note01 {
  font-weight: 400;
  font-size: 0.75rem;
  color: #232536;
  letter-spacing: 0.02em;
  line-height: 2.67em;
  margin-bottom: 5px;
}
@media screen and (min-width: 768px) {
  .c-stbl__note01 {
    display: none;
  }
}
.c-stbl__note01.color02 {
  color: #8e8e8e;
}
.c-stbl__scroll {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 15px;
}
.c-stbl__scroll::-webkit-scrollbar {
  width: 100%;
  height: 3px;
}
.c-stbl__scroll::-webkit-scrollbar-track {
  background: #eee;
}
.c-stbl__scroll::-webkit-scrollbar-thumb {
  background: #005bac;
}
@media screen and (min-width: 768px) {
  .c-stbl__scroll {
    margin-bottom: 0;
    overflow: hidden;
    padding-bottom: 0;
  }
}
.c-stbl__scroll table {
  width: 1000px;
}
@media screen and (min-width: 768px) {
  .c-stbl__scroll table {
    width: 100%;
  }
}
.c-stbl__scroll figure {
  width: 767px;
  position: relative;
}
@media screen and (min-width: 576px) {
  .c-stbl__scroll figure {
    width: 100%;
  }
}
.c-stbl__scroll figure::before {
  content: "※ 横スクロールでご覧いただけます。";
  font-size: 0.75rem;
  color: #8e8e8e;
  position: absolute;
  top: 10px;
  left: 5px;
  line-height: 1;
}
@media screen and (min-width: 576px) {
  .c-stbl__scroll figure::before {
    content: "";
  }
}

.c-accordion__ttl {
  position: relative;
  background: #005bac;
  border: solid 1px #005bac;
  text-align: center;
  padding: 10px 32px 12px;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .c-accordion__ttl {
    margin-bottom: 17px;
    padding: 19px 65px 21px;
  }
  .c-accordion__ttl:hover {
    opacity: 0.7;
    cursor: pointer;
    transition: all 0.5s;
  }
}
.c-accordion__ttl::before, .c-accordion__ttl::after {
  position: absolute;
  content: "";
  background: #fff;
}
.c-accordion__ttl::before {
  width: 18px;
  height: 1px;
  right: 20px;
  top: 50%;
}
@media screen and (min-width: 768px) {
  .c-accordion__ttl::before {
    width: 27px;
    height: 2px;
    right: 40px;
  }
}
.c-accordion__ttl::after {
  width: 18px;
  height: 1px;
  right: 20px;
  top: calc(50% - 1px);
  transition: 0.4s ease-in-out;
  transform: rotate(90deg);
}
@media screen and (min-width: 768px) {
  .c-accordion__ttl::after {
    width: 27px;
    height: 2px;
    width: 27px;
    height: 2px;
    right: 40px;
    top: calc(50% + 1px);
  }
}
.c-accordion__ttl.is-open::after {
  opacity: 0;
  visibility: hidden;
  transform: rotate(0deg);
}
.c-accordion__ttl .ttl {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5em;
}
@media screen and (min-width: 768px) {
  .c-accordion__ttl .ttl {
    font-size: 1.125rem;
  }
}
.c-accordion__content {
  display: none;
}
.c-accordion .c-stbl__row:first-child .c-stbl__head,
.c-accordion .c-stbl__row:first-child .c-stbl__content {
  border-top: 0;
}

.c-slist01 {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .c-slist01 {
    flex-wrap: nowrap;
  }
}
.c-slist01__item {
  display: flex;
  flex-direction: column;
  margin: 0 0 50px 0;
  border-bottom: solid 1px #D1D4D7;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .c-slist01__item {
    margin: 0 2% 0 0;
    width: 32%;
  }
  .c-slist01__item img {
    transition: all 0.5s;
  }
  .c-slist01__item:hover img {
    transform: scale(1.2);
  }
  .c-slist01__item:hover .c-slist01__arrow {
    transform: translateX(5px);
  }
}
@media screen and (min-width: 1025px) {
  .c-slist01__item {
    margin: 0 3.45% 0 0;
    width: 22.414%;
  }
}
.c-slist01__item:last-child {
  margin: 0;
}
.c-slist01__img {
  position: relative;
  overflow: hidden;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .c-slist01__img {
    height: 146px;
  }
}
.c-slist01__img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.c-slist01__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 0;
  padding: 15px 0;
}
@media screen and (min-width: 768px) {
  .c-slist01__info {
    padding: 21px 0 17px;
    height: calc(100% - 146px);
  }
}
.c-slist01__info .c-circle-arrow01__arrow {
  transition: all 0.5s;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
@media screen and (min-width: 768px) {
  .c-slist01__info .c-circle-arrow01__arrow {
    width: 34px;
    height: 34px;
  }
}
.c-slist01__info .c-circle-arrow01__arrow::after {
  top: calc(50% - 3px);
  left: calc(50% - 10px);
}
.c-slist01__txt {
  display: inline-block;
  line-height: 1.4em;
  color: #333;
  font-weight: bold;
  letter-spacing: 0.02em;
  font-size: 1rem;
}
@media screen and (min-width: 1025px) {
  .c-slist01__txt {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 768px) {
  .c-slist01.line01 .c-slist01__info {
    padding: 30px 0 25px;
  }
}

.c-slist02 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .c-slist02 {
    justify-content: center;
  }
}
.c-slist02.no-desc .c-slist02__img {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .c-slist02.has-5item .c-slist02__item {
    padding: 16px 19px;
  }
}
@media screen and (min-width: 1025px) {
  .c-slist02.has-5item .c-slist02__item {
    width: 24.075%;
    min-height: 405px;
    margin: 0 1.205% 34px 0;
  }
}
@media screen and (min-width: 1025px) {
  .c-slist02.has-5item .c-slist02__item:last-child {
    margin: 0 0 34px 0;
  }
}
@media screen and (min-width: 768px) {
  .c-slist02.has-5item .c-slist02__item:nth-child(2n+2) {
    margin: 0 0 34px 0;
  }
  .c-slist02.has-5item .c-slist02__item:nth-child(2n+2)::after {
    content: none;
  }
}
@media screen and (min-width: 1025px) {
  .c-slist02.has-5item .c-slist02__item:nth-child(2n+2) {
    margin: 0 1.121% 34px 0;
  }
  .c-slist02.has-5item .c-slist02__item:nth-child(2n+2)::after {
    content: "";
  }
}
@media screen and (min-width: 1025px) {
  .c-slist02.has-5item .c-slist02__item:nth-child(4n+4) {
    margin: 0 0 34px 0;
  }
  .c-slist02.has-5item .c-slist02__item:nth-child(4n+4)::after {
    content: none;
  }
}
.c-slist02__item {
  position: relative;
  width: 89.66%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin: 0 auto 14px;
  border: solid 1px #2995E5;
}
.c-slist02__item * {
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 768px) {
  .c-slist02__item {
    padding: 16px 14px;
    width: 47%;
    margin: 0 13px 34px 0;
  }
}
@media screen and (min-width: 1025px) {
  .c-slist02__item {
    margin-bottom: 34px;
    width: 24.138%;
    margin: 0 1.121% 34px 0;
  }
}
@media screen and (min-width: 768px) {
  .c-slist02__item:nth-child(2n+2) {
    margin: 0 0 34px 0;
  }
  .c-slist02__item:nth-child(2n+2)::after {
    content: none;
  }
}
@media screen and (min-width: 1025px) {
  .c-slist02__item:nth-child(2n+2) {
    margin: 0 1.121% 34px 0;
  }
  .c-slist02__item:nth-child(2n+2)::after {
    content: "";
  }
}
@media screen and (min-width: 1025px) {
  .c-slist02__item:nth-child(4n+4) {
    margin: 0 0 34px 0;
  }
  .c-slist02__item:nth-child(4n+4)::after {
    content: none;
  }
}
.c-slist02__item::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #fff;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.c-slist02__item::after {
  position: absolute;
  content: "";
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  background: #2995E5;
  z-index: -1;
  width: 1px;
  height: calc(100% + 30px);
}
@media screen and (min-width: 768px) {
  .c-slist02__item::after {
    left: 0;
    top: 50%;
    width: 150%;
    height: 1px;
    transform: translate(0, -50%);
  }
}
.c-slist02__item:last-child {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .c-slist02__item:last-child {
    margin: 0 0 34px 0;
  }
}
.c-slist02__item:last-child::after {
  content: none;
}
.c-slist02__ttl {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.c-slist02__ttl .ttl,
.c-slist02__ttl .num {
  font-weight: bold;
  letter-spacing: 0.02em;
  display: inline-block;
}
.c-slist02__ttl .ttl {
  text-align: center;
  position: relative;
  color: #005BAC;
  font-size: 1.25rem;
  line-height: 1.54em;
  width: 100%;
}
.c-slist02__ttl .num {
  text-align: left;
  position: absolute;
  left: 0;
  top: -2px;
  color: #2995E5;
  font-family: "Josefin Slab", serif;
  font-size: 2.5rem;
  line-height: 1em;
}
.c-slist02__img {
  width: 100%;
  text-align: center;
  margin: 0 auto 15px;
}
@media screen and (min-width: 768px) {
  .c-slist02__img {
    margin: 0 auto 21px;
  }
}
.c-slist02__desc .desc {
  color: #232536;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.7em;
}
@media screen and (min-width: 768px) {
  .c-slist02__desc .desc {
    font-size: 1rem;
  }
}

.c-slist-anchors01 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .c-slist-anchors01 {
    justify-content: flex-start;
  }
}

.c-slist-anchor01 {
  margin: 0 0 25px 0;
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 0 20px 5px 0;
  border-bottom: solid 1px #005bac;
  width: 100%;
}
@media screen and (min-width: 576px) {
  .c-slist-anchor01 {
    width: 47%;
  }
}
@media screen and (min-width: 768px) {
  .c-slist-anchor01 {
    transition: all 0.5s;
    padding: 0 28px 4px 0;
    margin: 0 40px 36px 0;
    width: auto;
  }
  .c-slist-anchor01:hover {
    opacity: 0.7;
  }
}
.c-slist-anchor01:last-child {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .c-slist-anchor01:last-child {
    margin: 0 0 36px;
  }
}
.c-slist-anchor01::after {
  content: "";
  position: absolute;
  right: 0;
  top: calc(50% - 5px);
  -webkit-transform: translateY(-50%) rotate(135deg);
  transform: translateY(-50%) rotate(135deg);
  border-top: solid 1px #005bac;
  border-right: solid 1px #005bac;
  padding: 3px;
}
@media screen and (min-width: 768px) {
  .c-slist-anchor01::after {
    top: calc(50% - 6px);
    padding: 5px;
  }
}
.c-slist-anchor01__txt {
  display: inline-block;
  position: relative;
  color: #005bac;
  font-weight: bold;
  letter-spacing: 0.02em;
  font-size: 0.875rem;
}
@media screen and (min-width: 768px) {
  .c-slist-anchor01__txt {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .c-slist-anchor01:nth-child(6n+6) {
    margin-right: 0;
  }
}
.c-slist-anchor01.icon-pdf {
  width: auto;
}
.c-slist-anchor01.icon-pdf .c-slist-anchor01__txt {
  padding-right: 10px;
}
@media screen and (min-width: 768px) {
  .c-slist-anchor01.icon-pdf .c-slist-anchor01__txt {
    padding-right: 20px;
  }
}
.c-slist-anchor01.icon-pdf::after {
  border: 0;
  right: 0;
  top: 43%;
  transform: translateY(-50%);
  background: url(../../images/common/icon_pdf_01.svg) no-repeat center/contain;
  width: 20px;
  height: 21px;
}
@media screen and (min-width: 768px) {
  .c-slist-anchor01.icon-pdf::after {
    width: 26px;
    height: 27px;
  }
}

.c-slist-anchors02 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .c-slist-anchors02.has-5btn .c-slist-anchor02 {
    margin: 0 2px 2px 0;
  }
}
@media screen and (min-width: 1025px) {
  .c-slist-anchors02.has-5btn .c-slist-anchor02 {
    margin: 0 2px 15px 0;
  }
}
@media screen and (min-width: 768px) {
  .c-slist-anchors02.has-5btn .c-slist-anchor02:nth-child(3n+3) {
    margin-right: 0;
  }
}
.c-slist-anchors02.has-5btn .c-slist-anchor02:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .c-slist-anchors02.has-4btn .c-slist-anchor02 {
    margin: 0 2px 2px 0;
  }
}
@media screen and (min-width: 576px) {
  .c-slist-anchors02.has-4btn .c-slist-anchor02:nth-child(2n+2) {
    margin-right: 0;
  }
}
@media screen and (min-width: 1025px) {
  .c-slist-anchors02.has-4btn .c-slist-anchor02:nth-child(2n+2) {
    margin-right: 2px;
  }
}
@media screen and (min-width: 1025px) {
  .c-slist-anchors02.has-4btn .c-slist-anchor02 {
    width: 24.8%;
  }
}
@media screen and (min-width: 768px) {
  .c-slist-anchors02.has-4btn .c-slist-anchor02:nth-child(4n+4) {
    margin-right: 0;
  }
}
.c-slist-anchors02.has-4btn .c-slist-anchor02:last-child {
  margin-right: 0;
}

.c-slist-anchor02 {
  width: 100%;
  background: #fff;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px 5px;
  padding: 25px 15px;
}
.c-slist-anchor02:hover {
  opacity: 1;
}
@media screen and (min-width: 576px) {
  .c-slist-anchor02 {
    width: 47%;
  }
}
@media screen and (min-width: 768px) {
  .c-slist-anchor02 {
    margin: 0 2px 2px;
    padding: 20px 15px;
    min-height: 100px;
  }
  .c-slist-anchor02:hover .c-slist-anchor02__txt::after {
    transform: translateY(50%) rotate(135deg);
  }
}
@media screen and (min-width: 1025px) {
  .c-slist-anchor02 {
    width: 33.104%;
  }
}
.c-slist-anchor02__txt {
  color: #005bac;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  display: inline-block;
  position: relative;
  padding-right: 30px;
}
@media screen and (min-width: 768px) {
  .c-slist-anchor02__txt {
    line-height: 1.7em;
    padding-right: 28px;
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 1025px) {
  .c-slist-anchor02__txt {
    padding-right: 28px;
    padding-right: clamp(19px, 1.75vw, 28px);
  }
}
.c-slist-anchor02__txt::after {
  content: "";
  position: absolute;
  right: 0;
  top: calc(50% - 5px);
  -webkit-transform: translateY(-50%) rotate(135deg);
  transform: translateY(-50%) rotate(135deg);
  border-top: solid 1px #005bac;
  border-right: solid 1px #005bac;
  padding: 3px;
  transition: 0.4s ease-in-out;
}
@media screen and (min-width: 768px) {
  .c-slist-anchor02__txt::after {
    top: calc(50% - 5px);
    padding: 5px;
  }
}
.c-slist-anchor02.txt-l {
  text-align: left;
}

.c-slist-num01 {
  counter-reset: list-num;
}
.c-slist-num01 > li {
  color: #232536;
  position: relative;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  padding-left: 15px;
  font-weight: 400;
  margin-bottom: 12px;
  line-height: 1.5em;
}
@media screen and (min-width: 768px) {
  .c-slist-num01 > li {
    line-height: 2em;
    margin-bottom: 16px;
    font-size: 1rem;
    padding-left: 35px;
  }
}
.c-slist-num01 > li:last-child {
  margin-bottom: 0;
}
.c-slist-num01 > li::before {
  color: #232536;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  position: absolute;
  left: 0;
  top: -1px;
  counter-increment: list-num;
  content: counter(list-num) ".";
  line-height: 1.5em;
}
@media screen and (min-width: 768px) {
  .c-slist-num01 > li::before {
    font-size: 1rem;
    line-height: 2em;
    left: 6px;
  }
}
.c-slist-num01 > li.ttl,
.c-slist-num01 > li .ttl {
  font-weight: bold;
  line-height: 1.5em;
}
@media screen and (min-width: 768px) {
  .c-slist-num01 > li.ttl,
.c-slist-num01 > li .ttl {
    line-height: 2em;
  }
}
.c-slist-num01 > li .ttl {
  margin-bottom: 5px;
}
@media screen and (min-width: 768px) {
  .c-slist-num01 > li .ttl {
    margin-bottom: 7px;
  }
}
.c-slist-num01 > li .desc {
  color: #232536;
  font-weight: 400;
  letter-spacing: 0.02em;
}
.c-slist-num02 > li {
  color: #232536;
  position: relative;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  font-weight: 400;
  margin-bottom: 8px;
  display: inline-flex;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .c-slist-num02 > li {
    margin-bottom: 8px;
    font-size: 1rem;
    line-height: 1.9em;
  }
}
.c-slist-num02 > li:last-child {
  margin-bottom: 0;
}
.c-slist-num02 > li .num02 {
  display: inline-block;
  margin-right: 5px;
}
@media screen and (min-width: 768px) {
  .c-slist-num02 > li .num02 {
    margin-right: 10px;
  }
}
.c-slist-num03 > li {
  color: #232536;
  position: relative;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 1.5em;
  width: 100%;
  padding-left: 12px;
}
@media screen and (min-width: 768px) {
  .c-slist-num03 > li {
    margin-bottom: 8px;
    font-size: 1rem;
    padding-left: 16px;
  }
}
.c-slist-num03 > li:last-child {
  margin-bottom: 0;
}
.c-slist-num03 > li .num {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  color: #005BAC;
  font-weight: bold;
  width: auto;
}
.c-slist-num03 > li .ttl {
  display: inline-block;
  width: auto;
  padding-left: 5px;
}
.c-slist-num03.has-bg > li {
  background: #F8FAF9;
  padding: 10px 20px 10px 34px;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .c-slist-num03.has-bg > li {
    font-size: 1.125rem;
    padding: 17px 15px 15px 40px;
  }
}
.c-slist-num03.has-bg > li .num {
  left: 15px;
  top: 8px;
}
@media screen and (min-width: 768px) {
  .c-slist-num03.has-bg > li .num {
    left: 18px;
    top: 17px;
  }
}
.c-slist-num03.has-bg > li .ttl {
  padding-left: 0;
}
.c-slist-num03.has-bg > li.h100 {
  height: 100%;
}

.c-slist-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (min-width: 576px) {
  .c-slist-btn {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 768px) {
  .c-slist-btn {
    justify-content: center;
  }
}
.c-slist-btn .c-link-btn01 {
  max-width: 100%;
  margin: 0 0 20px;
}
@media screen and (min-width: 576px) {
  .c-slist-btn .c-link-btn01 {
    max-width: 49%;
  }
}
@media screen and (min-width: 768px) {
  .c-slist-btn .c-link-btn01 {
    margin: 0 20px;
    max-width: 400px;
    transition: all 0.5s ease;
  }
  .c-slist-btn .c-link-btn01:hover .c-link-btn01__link-box {
    background-color: #fff;
    color: #005BAC;
    border: solid 1px #005BAC;
  }
  .c-slist-btn .c-link-btn01:hover .c-link-btn01__link-box::after {
    border-color: #005BAC;
  }
}
.c-slist-btn .c-link-btn01:last-child {
  margin: 0;
}
@media screen and (min-width: 768px) {
  .c-slist-btn .c-link-btn01:last-child {
    margin: 0 20px;
  }
}
.c-slist-btn .c-link-btn01.icon-pdf .c-link-btn01__link-box::after, .c-slist-btn .c-link-btn01.icon-pdf01 .c-link-btn01__link-box::after {
  border: 0;
  width: 20px;
  height: 21px;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}
@media screen and (min-width: 768px) {
  .c-slist-btn .c-link-btn01.icon-pdf .c-link-btn01__link-box::after, .c-slist-btn .c-link-btn01.icon-pdf01 .c-link-btn01__link-box::after {
    right: 20px;
    width: 24px;
    height: 25px;
  }
}
@media screen and (min-width: 1025px) {
  .c-slist-btn .c-link-btn01.icon-pdf .c-link-btn01__link-box::after, .c-slist-btn .c-link-btn01.icon-pdf01 .c-link-btn01__link-box::after {
    right: 32px;
  }
}
.c-slist-btn .c-link-btn01.icon-pdf .c-link-btn01__link-box::after {
  background: url("../../images/common/icon_pdf.svg") no-repeat center/contain;
}
@media screen and (min-width: 768px) {
  .c-slist-btn .c-link-btn01.icon-pdf:hover .c-link-btn01__link-box::after {
    background: url("../../images/common/icon_pdf_01.svg") no-repeat center/contain;
  }
}
.c-slist-btn .c-link-btn01.icon-pdf01 .c-link-btn01__link-box::after {
  background: url("../../images/common/icon_pdf_01.svg") no-repeat center/contain;
}
@media screen and (min-width: 768px) {
  .c-slist-btn .c-link-btn01.icon-pdf01:hover .c-link-btn01__link-box::after {
    background: url("../../images/common/icon_pdf.svg") no-repeat center/contain;
  }
}
.c-slist-btn .c-link-btn01.icon-play .c-link-btn01__link-box::after {
  border: 0;
  background: url("../../images/common/icon_play.svg") no-repeat center/contain;
  width: 28px;
  height: 19px;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}
@media screen and (min-width: 768px) {
  .c-slist-btn .c-link-btn01.icon-play .c-link-btn01__link-box::after {
    right: 29px;
    width: 30px;
    height: 21px;
  }
}
@media screen and (min-width: 768px) {
  .c-slist-btn .c-link-btn01.icon-play:hover .c-link-btn01__link-box::after {
    background: url("../../images/common/icon_play_01.svg") no-repeat center/contain;
  }
}
@media screen and (min-width: 768px) {
  .c-slist-btn .c-link-btn01.icon-blank:hover .c-link-btn02__link-box {
    background-color: #005BAC;
    border-color: #fff;
    color: #fff;
  }
  .c-slist-btn .c-link-btn01.icon-blank:hover .c-link-btn02__link-box::after {
    background: url("../../images/common/icon_blank-white.svg") no-repeat center/contain;
  }
}
.c-slist-btn .c-link-btn01__link-box {
  background-color: #005BAC;
  border: solid 1px #005BAC;
  color: #fff;
  padding: 10px;
  letter-spacing: 0.02em;
  font-size: 1rem;
}
.c-slist-btn .c-link-btn01__link-box::after {
  border-color: #fff;
}
@media screen and (min-width: 768px) {
  .c-slist-btn .c-link-btn01__link-box {
    padding: 16px;
    font-size: 1.125rem;
  }
}

.c-text-link {
  color: #005bac;
  position: relative;
}
.c-text-link a {
  transition: 0.3s ease-in-out;
}
.c-text-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #005bac;
  transition: 0.3s ease-in-out;
}
.c-text-link:hover::after {
  opacity: 0.8;
}

.c-sframe-btns .c-link-btn01__link-box {
  background-color: #fff;
  border: solid 1px #fff;
  color: #005BAC;
  padding: 10px;
  letter-spacing: 0.02em;
  font-size: 1rem;
}
.c-sframe-btns .c-link-btn01__link-box::after {
  border-color: #005BAC;
}
@media screen and (min-width: 768px) {
  .c-sframe-btns .c-link-btn01__link-box {
    padding: 16px;
    font-size: 1.125rem;
  }
}
.c-sframe-btns.c-slist-btn {
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .c-sframe-btns.c-slist-btn {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1200px) {
  .c-sframe-btns.c-slist-btn {
    flex-wrap: inherit;
  }
}
.c-sframe-btns.c-slist-btn .c-link-btn01 {
  margin: 0 auto 20px;
  max-width: 320px;
}
@media screen and (min-width: 576px) {
  .c-sframe-btns.c-slist-btn .c-link-btn01 {
    margin: 0;
    max-width: 48%;
  }
  .c-sframe-btns.c-slist-btn .c-link-btn01:hover .c-link-btn01__link-box {
    background-color: #005BAC;
    color: #fff;
    border: solid 1px #fff;
  }
  .c-sframe-btns.c-slist-btn .c-link-btn01:hover .c-link-btn01__link-box::after {
    border-color: #fff;
  }
}
@media screen and (min-width: 768px) {
  .c-sframe-btns.c-slist-btn .c-link-btn01 {
    max-width: 260px;
    margin: 0 40px 20px 0;
  }
}
.c-sframe-btns.c-slist-btn .c-link-btn01:last-child {
  margin: 0 auto;
}
@media screen and (min-width: 576px) {
  .c-sframe-btns.c-slist-btn .c-link-btn01:last-child {
    margin: 0;
  }
}
.c-sframe-btns.c-slist-btn.has-4btn {
  justify-content: space-between;
}
.c-sframe-btns.c-slist-btn.has-4btn .c-link-btn01 {
  margin: 0 0 20px;
}
@media screen and (min-width: 576px) {
  .c-sframe-btns.c-slist-btn.has-4btn .c-link-btn01 {
    max-width: 48%;
  }
}
@media screen and (min-width: 1200px) {
  .c-sframe-btns.c-slist-btn.has-4btn .c-link-btn01 {
    max-width: 260px;
  }
}
@media screen and (min-width: 576px) {
  .c-sframe-btns.c-slist-btn.has-4btn .c-link-btn01:nth-last-child(-n+2) {
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .c-sframe-btns.c-slist-btn.has-4btn .c-link-btn01:nth-last-child(-n+2) {
    margin: 0 0 20px;
  }
}
.c-sframe-btns.c-slist-btn.has-4btn .c-link-btn01:last-child {
  margin: 0;
}
.c-sframe-btns.c-slist-btn.has-3btn {
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  .c-sframe-btns.c-slist-btn.has-3btn {
    justify-content: flex-start;
  }
}
.c-sframe-btns.c-slist-btn.has-3btn .c-link-btn01 {
  margin: 0 0 20px;
}
@media screen and (min-width: 576px) {
  .c-sframe-btns.c-slist-btn.has-3btn .c-link-btn01 {
    margin: 0 1% 20px;
    max-width: 48%;
  }
}
@media screen and (min-width: 1200px) {
  .c-sframe-btns.c-slist-btn.has-3btn .c-link-btn01 {
    max-width: 260px;
    margin: 0 40px 20px 0;
  }
}
.c-sframe-btns.c-slist-btn.has-3btn .c-link-btn01:last-child {
  margin: 0;
}
@media screen and (min-width: 768px) {
  .c-sframe-btns.c-slist-btn.has-3btn .c-link-btn01:last-child {
    margin: 0 0 20px;
  }
}
@media screen and (min-width: 768px) {
  .c-sframe-btns .c-link-btn01__link-box::after {
    top: calc(50% - 9px);
    right: 34px;
  }
}

.c-sframe {
  position: relative;
  background: #005BAC;
  padding: 25px 25px 40px;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .c-sframe {
    padding: 30px 30px 50px;
  }
}
@media screen and (min-width: 1200px) {
  .c-sframe {
    padding: 38px 80px 85px 80px;
  }
}
@media screen and (min-width: 1200px) {
  .c-sframe.equal-padding {
    padding: 38px 80px 42px;
  }
}
@media screen and (min-width: 1025px) {
  .c-sframe.equal-padding::after {
    right: -15px;
  }
}
.c-sframe * {
  position: relative;
  z-index: 1;
}
.c-sframe::after {
  position: absolute;
  content: "";
  bottom: 0;
  right: -20%;
  background: url(../../images/common/bg_frame.webp) no-repeat center/cover;
  width: 338px;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .c-sframe::after {
    width: 300px;
    right: 0;
  }
}
@media screen and (min-width: 1025px) {
  .c-sframe::after {
    width: 338px;
  }
}
.c-sframe__ttl01 {
  position: relative;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #fff;
  line-height: 1.7em;
  margin-bottom: 20px;
  padding-left: 15px;
}
@media screen and (min-width: 768px) {
  .c-sframe__ttl01 {
    padding-left: 22px;
    margin-bottom: 26px;
    font-size: 1.5rem;
  }
}
.c-sframe__ttl01::before {
  position: absolute;
  content: "";
  left: 0;
  top: 12px;
  border-radius: 100%;
  background: #fff;
  width: 8px;
  height: 8px;
}
@media screen and (min-width: 768px) {
  .c-sframe__ttl01::before {
    width: 10px;
    height: 10px;
    top: 16px;
  }
}
.c-sframe__ttl02 {
  border-bottom: solid 2px #fff;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.7em;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .c-sframe__ttl02 {
    padding-bottom: 17px;
    margin-bottom: 35px;
    font-size: 1.5rem;
  }
}
.c-sframe__txts > li {
  position: relative;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #fff;
  line-height: 1.7em;
  margin-bottom: 10px;
  padding-left: 20px;
}
@media screen and (min-width: 768px) {
  .c-sframe__txts > li {
    padding-left: 23px;
    margin-bottom: 8px;
    font-size: 1.25rem;
    line-height: 2.04em;
  }
}
.c-sframe__txts > li:last-child {
  margin-bottom: 0;
}
.c-sframe__txts > li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 8px;
  border-radius: 100%;
  background: #fff;
  width: 8px;
  height: 8px;
}
@media screen and (min-width: 768px) {
  .c-sframe__txts > li::before {
    width: 10px;
    height: 10px;
    top: 16px;
  }
}
.c-sframe__main.has-img {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .c-sframe__main.has-img {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}
.c-sframe__main.has-img .c-sframe__img {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 30px auto 0;
}
@media screen and (min-width: 768px) {
  .c-sframe__main.has-img .c-sframe__img {
    width: 30%;
    top: 5px;
    margin: 0 auto;
  }
}
.c-sframe__main.has-img .c-sframe__info {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .c-sframe__main.has-img .c-sframe__info {
    width: 70%;
    padding-right: 20px;
  }
}
@media screen and (min-width: 1025px) {
  .c-sframe__main.has-img .c-sframe__info {
    padding-right: 46px;
  }
}
.c-sframe__info {
  width: 100%;
}
.c-sframe__item {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .c-sframe__item {
    margin-bottom: 26px;
  }
}
.c-sframe__item:last-child {
  margin-bottom: 0;
}
.c-sframe__pics.has-2pic {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .c-sframe__pics.has-2pic {
    flex-wrap: nowrap;
  }
}
.c-sframe__pics.has-2pic .c-sframe__pic {
  margin: 0 auto 30px;
}
@media screen and (min-width: 768px) {
  .c-sframe__pics.has-2pic .c-sframe__pic {
    width: 48%;
    margin: 0;
  }
}
.c-sframe__pics.has-2pic .c-sframe__pic:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .c-sframe__pics.has-2pic .c-sframe__pic:last-child {
    margin: 0;
  }
}
.c-sframe__pics.has-3pic {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .c-sframe__pics.has-3pic {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
.c-sframe__pics.has-3pic .c-sframe__pic {
  width: 100%;
  margin: 0 auto 30px;
}
@media screen and (min-width: 576px) {
  .c-sframe__pics.has-3pic .c-sframe__pic {
    width: 45%;
  }
}
@media screen and (min-width: 768px) {
  .c-sframe__pics.has-3pic .c-sframe__pic {
    width: 30.6%;
    margin: 0 40px 0 0;
  }
}
.c-sframe__pics.has-3pic .c-sframe__pic:last-child {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .c-sframe__pics.has-3pic .c-sframe__pic:last-child {
    margin: 0;
  }
}
.c-slist-dot > li {
  position: relative;
  line-height: 1.7em;
  color: #232536;
  letter-spacing: 0.02em;
  padding-left: 22px;
  font-size: 0.875rem;
  margin-bottom: 5px;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .c-slist-dot > li {
    margin-bottom: 7px;
    padding-left: 18px;
    font-size: 1rem;
  }
}
.c-slist-dot > li:last-child {
  margin-bottom: 0;
}
.c-slist-dot > li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 7px;
  width: 8px;
  height: 8px;
  background: #005BAC;
  border-radius: 100%;
}
@media screen and (min-width: 768px) {
  .c-slist-dot > li::before {
    top: 10px;
  }
}
.c-slist-dot-group {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .c-slist-dot-group {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 768px) {
  .c-slist-dot-group.has-2block {
    justify-content: space-between;
  }
}
.c-slist-dot-group.has-2block .c-slist-dot {
  margin: 0 10px 5px 0;
  padding-right: 5px;
}
.c-slist-dot-group.has-2block .c-slist-dot:last-child {
  margin: 0;
  padding-right: 0;
}
@media screen and (min-width: 768px) {
  .c-slist-dot-group.has-2block .c-slist-dot {
    width: 46%;
    margin: 0;
    padding-right: 0;
  }
}
.c-slist-dot.has-bullet > li {
  margin-bottom: 5px;
  padding-left: 20px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .c-slist-dot.has-bullet > li {
    padding-left: 26px;
    margin-bottom: 7px;
  }
}
.c-slist-dot.has-bullet > li::before {
  content: "※";
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #232536;
  width: auto;
  height: auto;
  background: none;
  top: 0;
  left: 0;
}
@media screen and (min-width: 768px) {
  .c-slist-dot.has-bullet > li::before {
    font-size: 1rem;
  }
}

/*--------------------------------------------------------------
* componetフォルダの_index.scss
----------------------------------------------------------------*/
.js-fade-up {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
  transform: translateY(40px);
}
.js-fade-up.is-show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

/*--------------------------------------------------------------
* javascriptフォルダの_index.scss
----------------------------------------------------------------*/
.p-under-top__title-en {
  color: #005bac;
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .p-under-top__title-en {
    font-size: 4rem;
    font-size: clamp(2.125rem, 5.5172413793vw, 4rem);
  }
}
.p-under-top__title-ja {
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 8px;
  font-weight: 500 !important;
}
@media screen and (min-width: 768px) {
  .p-under-top__title-ja {
    font-size: 2.5rem;
    font-size: clamp(1.5rem, 3.4482758621vw, 2.5rem);
    margin-top: 17px;
  }
}

.p-under-top01__main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .p-under-top01__main {
    flex-wrap: nowrap;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .p-under-top01__main.no-title {
    align-items: flex-start;
  }
}
.p-under-top01__img {
  width: 100%;
  flex-shrink: 0;
  text-align: center;
  margin: 0 auto;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-under-top01__img {
    width: 48.276%;
    margin: 0;
    top: 4px;
  }
}
.p-under-top01__info {
  width: 100%;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .p-under-top01__info {
    width: 48.276%;
  }
}
.p-under-top01__info.has-margin {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__info.has-margin {
    margin-bottom: 0;
  }
}
.p-under-top01__title {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title {
    margin-bottom: 31px;
  }
}
.p-under-top01__title .en {
  position: relative;
  font-family: "Josefin Slab", serif;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: #005BAC;
  display: block;
  line-height: 1.7em;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title .en {
    top: 5px;
    font-size: 1.5rem;
  }
}
.p-under-top01__title .jp {
  font-size: 1.625rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #232536;
  display: block;
  line-height: 1.7em;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title .jp {
    font-size: 2.5rem;
    font-size: clamp(2rem, 3.4482758621vw, 2.5rem);
  }
}
.p-under-top01__desc {
  color: #232536;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 2em;
}
@media screen and (min-width: 768px) {
  .p-under-top01__desc {
    font-size: 1rem;
  }
}
.p-under-top01__title01 {
  font-size: 1.625rem;
  font-weight: 500;
  color: #005BAC;
  letter-spacing: 0.02em;
  line-height: 1.7em;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title01 {
    font-size: 2.5rem;
    margin-bottom: 17px;
  }
}
@media screen and (min-width: 768px) {
  .p-under-top01__title01.mb40 {
    margin-bottom: 41px;
  }
}
@media screen and (min-width: 768px) {
  .p-under-top01__title01.mb20 {
    margin-bottom: 20px;
  }
}
.p-under-top01__title01.has-logotxt {
  padding-left: 70px;
  margin-bottom: 8px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title01.has-logotxt {
    padding-left: 110px;
    margin-bottom: 18px;
  }
}
.p-under-top01__title01.has-logotxt::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: url(../../images/common/img_utop_main_logo_01.webp) no-repeat center/contain;
  width: 65px;
  height: 44px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title01.has-logotxt::before {
    width: 85px;
    height: 64px;
    top: 3px;
  }
}
.p-under-top01__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__list {
    margin-bottom: 26px;
  }
}
.p-under-top01__list.txt-2line .p-under-top01__icon {
  padding: 3px 2px;
  height: 60%;
}
.p-under-top01__list.txt-2line .p-under-top01__txt {
  padding: 10px 2px;
  height: 40%;
}
@media screen and (min-width: 768px) {
  .p-under-top01__list.txt-2line .p-under-top01__txt {
    padding: 3px 2px 2px;
  }
}
.p-under-top01__item {
  margin: 0 6px 10px 0;
  width: 50px;
  border: solid 1px #005BAC;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item {
    margin: 0 8px 10px 0;
    width: 62px;
  }
}
.p-under-top01__item:last-child {
  margin: 0 0 10px 0;
}
.p-under-top01__icon, .p-under-top01__txt {
  width: 100%;
  text-align: center;
}
.p-under-top01__icon {
  height: 64%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}
.p-under-top01__icon .icon {
  line-height: 0;
  display: block;
  margin: auto;
}
.p-under-top01__txt {
  height: 36%;
  background: #005BAC;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 2px;
}
.p-under-top01__txt .txt {
  color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: 0.02em;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-under-top01__txt .txt {
    font-size: 0.875rem;
  }
}
.p-under-top01__txt .txt-small01 {
  font-size: 0.6875rem;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-under-top01__txt .txt-small01 {
    font-size: 0.75rem;
  }
}
.p-under-top01__txt .txt-small02 {
  position: relative;
  display: block;
  font-size: 0.625rem;
  line-height: 1em;
}
@media screen and (min-width: 768px) {
  .p-under-top01__txt .txt-small02 {
    top: -2px;
  }
}
.p-under-top01__item.icon-01 .icon {
  width: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-01 .icon {
    width: 24px;
  }
}
.p-under-top01__item.icon-02 .icon {
  width: 27px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-02 .icon {
    width: 31px;
  }
}
.p-under-top01__item.icon-03 .icon {
  width: 40px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-03 .icon {
    width: 45px;
  }
}
.p-under-top01__item.icon-04 .icon {
  width: 24px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-04 .icon {
    width: 28px;
  }
}
.p-under-top01__item.icon-05 .icon {
  width: 26px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-05 .icon {
    width: 31px;
  }
}
.p-under-top01__item.icon-06 .icon {
  width: 34px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-06 .icon {
    width: 38px;
  }
}
.p-under-top01__item.icon-07 .icon {
  width: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-07 .icon {
    width: 24px;
  }
}
.p-under-top01__item.icon-08 .icon {
  width: 16px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-08 .icon {
    width: 20px;
  }
}
.p-under-top01__label {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__label {
    margin-bottom: 36px;
  }
}
.p-under-top01__label .txt {
  border: solid 1px #005BAC;
  color: #005BAC;
  display: inline-block;
  padding: 5px 12px 4px;
  line-height: 1.2em;
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .p-under-top01__label .txt {
    padding: 4px 9px 5px;
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .p-contact-content {
    display: flex;
    justify-content: space-between;
  }
}
.p-contact-content__left {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .p-contact-content__left {
    width: 46.5517241379%;
  }
}
.p-contact-content__text {
  margin-top: 20px;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .p-contact-content__text {
    margin-top: 29px;
    font-size: 1rem;
  }
}
.p-contact-content__right {
  flex: 1;
}
.p-contact-content__link-area {
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .p-contact-content__link-area {
    margin-top: 60px;
  }
}
@media screen and (min-width: 768px) {
  .p-contact-content__link-area .c-link-btn01 {
    margin-right: 50px;
  }
}

.p-under-box {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  margin-bottom: 60px;
  pointer-events: none;
}
.p-under-box:hover .p-under-box__img a {
  opacity: 1;
}
.p-under-box:hover .p-under-box__img img {
  transform: scale(1.1);
}
.p-under-box:hover .c-circle-arrow01__arrow {
  transform: translateX(5px);
}
@media screen and (min-width: 768px) {
  .p-under-box {
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 120px;
  }
}
.p-under-box.flex-start {
  align-items: flex-start;
}
.p-under-box:last-child {
  margin-bottom: 0 !important;
}
.p-under-box .c-section-title {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .p-under-box .c-section-title {
    margin-bottom: 28px;
    margin-bottom: clamp(10px, 2.4137931034vw, 28px);
  }
}
.p-under-box .c-section-title .c-section-title__en {
  color: #005bac;
}
@media screen and (min-width: 768px) {
  .p-under-box .c-section-title .c-section-title__en {
    margin-bottom: -4px;
  }
}
.p-under-box__img, .p-under-box__main {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-under-box__img, .p-under-box__main {
    width: 48.277%;
  }
}
.p-under-box__img {
  overflow: hidden;
}
.p-under-box__img a {
  display: block;
  pointer-events: auto;
}
.p-under-box__img img {
  transition: 0.4s ease-in-out;
}
.p-under-box__main {
  position: relative;
}
.p-under-box__main .c-sbtn__link {
  pointer-events: auto;
}
.p-under-box__img {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .p-under-box__img {
    margin-bottom: 0;
  }
}
.p-under-box__img .img {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-box__img .img {
    margin-bottom: 40px;
  }
}
.p-under-box__img .img.last {
  margin-bottom: 0;
}
.p-under-box .desc {
  color: #232536;
  line-height: 2em;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .p-under-box .desc {
    font-size: 1rem;
  }
}
.p-under-box__desc {
  font-size: 1rem;
  letter-spacing: 0.02em;
  color: #232536;
  margin-bottom: 20px;
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  .p-under-box__desc {
    margin-bottom: 55px;
    margin-bottom: clamp(1px, 4.7413793103vw, 55px);
    font-size: 1.125rem;
    margin-top: 24px;
  }
}
.p-under-box__reverse {
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-under-box__reverse {
    flex-direction: row;
  }
}
.p-under-box__reverse .p-under-box__desc {
  padding-right: 0;
}
.p-under-box__ttl {
  font-size: 1.5rem;
  font-weight: 500;
  color: #005bac;
  letter-spacing: 0.02em;
  line-height: 1.7em;
}
@media screen and (min-width: 768px) {
  .p-under-box__ttl {
    font-size: 1.625rem;
  }
}
@media screen and (min-width: 1200px) {
  .p-under-box__ttl {
    font-size: 2rem;
  }
}
.p-under-box.no-margin .p-under-box__main, .p-under-box.no-margin .p-under-box__desc {
  margin: 0;
}
.p-under-box.no-padding .p-under-box__main, .p-under-box.no-padding .p-under-box__desc {
  padding: 0;
}
.p-under-box__panel {
  margin-bottom: 15px;
}
.p-under-box__panel:last-child {
  margin-bottom: 0;
}
.p-under-box__panel .ttl, .p-under-box__panel .desc {
  font-size: 0.875rem;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .p-under-box__panel .ttl, .p-under-box__panel .desc {
    font-size: 1rem;
  }
}
.p-under-box__panel .ttl {
  font-weight: bold;
  color: #005BAC;
  line-height: 1.7em;
}
.p-under-box__panel .desc {
  color: #232536;
  line-height: 2em;
}
.p-under-box__panel .c-section-stitle02 {
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .p-under-box__panel .c-section-stitle02 {
    margin-bottom: 12px;
  }
}
.p-under-box__frame {
  padding: 15px;
  background: #F8FAF9;
}
@media screen and (min-width: 768px) {
  .p-under-box__frame {
    padding: 25px;
  }
}
.p-under-box__frame .desc {
  font-weight: 500;
  letter-spacing: 0.02em;
  font-size: 0.875rem;
  color: #232536;
}
@media screen and (min-width: 768px) {
  .p-under-box__frame .desc {
    line-height: 2em;
    font-size: 1rem;
  }
}
.p-under-box__label {
  display: inline-block;
  border: solid 1px #005BAC;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 2px 10px;
}
@media screen and (min-width: 768px) {
  .p-under-box__label {
    padding: 0 9px;
    margin-bottom: 12px;
  }
}
.p-under-box__label .txt {
  display: inline-block;
  line-height: 1.2em;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #005BAC;
}
@media screen and (min-width: 768px) {
  .p-under-box__label .txt {
    font-size: 1rem;
  }
}
.p-under-box__video {
  width: 100%;
  position: relative;
}
.p-under-box__video a {
  position: relative;
  display: block;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .p-under-box__video a img {
    transition: all 0.5s;
  }
  .p-under-box__video a:hover img {
    opacity: 0.7;
    transform: none;
  }
  .p-under-box__video a:hover::before {
    background: url(../../images/common/icon_youtube_01.svg) center center/contain no-repeat;
  }
}
.p-under-box__video a::before {
  content: "";
  display: block;
  width: 58px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  background: url(../../images/common/icon_youtube_02.svg) center center/contain no-repeat;
}
@media screen and (min-width: 768px) {
  .p-under-box__video a::before {
    width: 64px;
    height: 45px;
  }
}
.p-under-box__section {
  padding-top: 40px;
  margin-bottom: 42px;
  border-top: solid 1px #D1D4D7;
}
@media screen and (min-width: 768px) {
  .p-under-box__section {
    padding-top: 79px;
    margin-bottom: 84px;
  }
}
.p-under-box__section.no-padding {
  padding: 0;
}
.p-under-box__section.no-border {
  border: 0;
}
.p-under-box__section:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.p-under-box.has-label .p-under-box__ttl {
  position: relative;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .p-under-box.has-label .p-under-box__ttl {
    top: -2px;
    margin-bottom: 13px;
  }
}
.p-under-box.has-label .p-under-box__label {
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .p-under-box.has-label .p-under-box__label {
    margin-bottom: 24px;
  }
}
.p-under-box.has-label .p-under-box__desc {
  margin-top: 0;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .p-under-box.has-label .p-under-box__desc {
    margin-bottom: 36px;
  }
}
.p-under-box__bg01 {
  padding: 50px 30px;
  background: #005BAC;
}
@media screen and (min-width: 1200px) {
  .p-under-box__bg01 {
    padding: 60px 60px 58px;
  }
}
.p-under-box__bg01 .p-under-box {
  margin-bottom: 35px;
}
@media screen and (min-width: 768px) {
  .p-under-box__bg01 .p-under-box {
    margin-bottom: 40px;
  }
}
.p-under-box__bg01 .p-under-box__ttl, .p-under-box__bg01 .p-under-box__desc, .p-under-box__bg01 .c-sbtn__txt {
  color: #fff;
}
.p-under-box__bg01 .c-circle-arrow01__arrow {
  background: #fff;
}
.p-under-box__bg01 .c-circle-arrow01__arrow::after {
  border-color: #005BAC;
}
.p-under-box__bg01 .p-under-box__ttl {
  position: relative;
  margin-bottom: 5px;
}
@media screen and (min-width: 1200px) {
  .p-under-box__bg01 .p-under-box__ttl {
    top: -4px;
    margin-bottom: 0;
  }
}
.p-under-box__bg01 .p-under-box__desc {
  margin-top: 0;
  margin-bottom: 24px;
}
@media screen and (min-width: 1200px) {
  .p-under-box__bg01 .p-under-box__desc {
    margin-top: 5px;
    margin-bottom: 56px;
  }
}
.p-under-box__block {
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .p-under-box__block {
    margin-bottom: 15px;
  }
}
.p-under-box__block:last-child {
  margin-bottom: 0;
}
.p-under-box__block .desc {
  font-weight: 500;
  letter-spacing: 0.02em;
  font-size: 0.875rem;
  color: #232536;
  padding-left: 10px;
}
@media screen and (min-width: 768px) {
  .p-under-box__block .desc {
    padding-left: 17px;
    line-height: 2em;
    font-size: 1rem;
  }
}
.p-under-box-style01 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 {
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 1200px) {
  .p-under-box-style01 {
    align-items: flex-start;
  }
}
.p-under-box-style01 .c-section-stitle .en::before {
  top: 5px;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-section-stitle .en::before {
    top: 1px;
  }
}
.p-under-box-style01__reverse {
  flex-direction: column-reverse;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01__reverse {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .p-under-box-style01__reverse .p-under-box01__info {
    padding-left: 10px;
  }
}
.p-under-box-style01__img {
  width: 100%;
  flex-shrink: 0;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01__img {
    width: 47%;
  }
}
@media screen and (min-width: 1025px) {
  .p-under-box-style01__img {
    width: 48.276%;
  }
}
.p-under-box-style01__info {
  width: 100%;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01__info {
    width: 48%;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .p-under-box-style01__info {
    width: 43%;
  }
}
.p-under-box-style01__desc .desc {
  color: #232536;
  letter-spacing: 0.02em;
  font-weight: 400;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01__desc .desc {
    font-size: 1.125rem;
  }
}
.p-under-box-style01__desc .desc:last-child {
  margin-bottom: 0;
}
.p-under-box-style01:last-child {
  margin-bottom: 0 !important;
}
.p-under-box-style01 .c-slist-btn {
  justify-content: flex-start;
  margin: 24px 0 0;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-slist-btn {
    margin: 56px 0 0;
  }
}
.p-under-box-style01 .c-slist-btn .c-link-btn01 {
  margin: 0 0 10px;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-slist-btn .c-link-btn01 {
    margin: 0 0 10px;
  }
}
.p-under-box-style01 .c-slist-btn .c-link-btn01:last-child {
  margin: 0;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-slist-btn .c-link-btn01:last-child {
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-slist-btn .c-link-btn01__link-box {
    padding: 14px 16px 18px;
  }
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-slist-btn .c-link-btn01__link-box::after {
    top: calc(50% - 9px);
  }
}

.p-logistics-system-specification .c-section-stitle01 {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-logistics-system-specification .c-section-stitle01 {
    margin-bottom: 38px;
  }
}
@media screen and (min-width: 768px) {
  .p-logistics-system-specification .c-stbl__head {
    width: 22.2%;
    padding: 14px 30px;
  }
}
@media screen and (min-width: 768px) {
  .p-logistics-system-specification .c-stbl__content {
    width: 78.8%;
    padding: 14px 43px;
  }
}

.p-logistics-system-diagram .c-section-stitle01 {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-logistics-system-diagram .c-section-stitle01 {
    margin-bottom: 40px;
  }
}
.p-logistics-system-diagram__img {
  text-align: center;
  width: 100%;
  line-height: 0;
}

.p-logistics-system-station .c-section-stitle01 {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-logistics-system-station .c-section-stitle01 {
    margin-bottom: 41px;
  }
}
.p-logistics-system-station .desc {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #232536;
}
@media screen and (min-width: 768px) {
  .p-logistics-system-station .desc {
    font-size: 1rem;
  }
}

.p-logistics-system__list {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .p-logistics-system__list {
    flex-wrap: nowrap;
  }
}
.p-logistics-system__i {
  width: 100%;
  margin: 0 0 50px;
}
@media screen and (min-width: 768px) {
  .p-logistics-system__i {
    width: 31.035%;
    margin: 0 25px 0 0;
  }
  .p-logistics-system__i:nth-child(3n+3) {
    margin: 0;
  }
}
@media screen and (min-width: 1200px) {
  .p-logistics-system__i {
    margin: 0 40px 0 0;
  }
}
.p-logistics-system__i:last-child {
  margin: 0;
}
.p-logistics-system__iimg {
  text-align: center;
  margin: 0 auto 10px;
  line-height: 0;
}
@media screen and (min-width: 768px) {
  .p-logistics-system__iimg {
    margin: 0 auto 28px;
  }
}
.p-logistics-system__idesc {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #232536;
  line-height: 1.7em;
}
@media screen and (min-width: 768px) {
  .p-logistics-system__idesc {
    line-height: 2em;
    font-size: 1rem;
  }
}
.p-logistics-system__ittl {
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  margin-bottom: 2px;
  color: #005BAC;
  line-height: 1.7em;
}
@media screen and (min-width: 768px) {
  .p-logistics-system__ittl {
    margin-bottom: 13px;
    font-size: 1.25rem;
  }
}

.p-floor-system-product {
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: solid 1px #D1D4D7;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product {
    padding-bottom: 100px;
    margin-bottom: 100px;
  }
}
.p-floor-system-product:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.p-floor-system-product__imgs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__imgs {
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__imgs.flex-start {
    align-items: flex-start;
  }
}
.p-floor-system-product__img01 {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0 auto 30px;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__img01 {
    width: 48.277%;
    margin: 0;
  }
}
.p-floor-system-product__img02 {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__img02 {
    margin: 0;
    width: calc(51.723% - 40px);
  }
}
.p-floor-system-product__ttl01 {
  color: #232536;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 1.533em;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__ttl01 {
    margin-bottom: 28px;
    font-size: 1.5rem;
  }
}
.p-floor-system-product__ttl02 {
  position: relative;
  color: #005BAC;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  margin-bottom: 5px;
  padding-left: 20px;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__ttl02 {
    padding-left: 20px;
    margin-bottom: 10px;
    font-size: 1rem;
  }
}
.p-floor-system-product__ttl02::before {
  position: absolute;
  content: "";
  top: 7px;
  left: 0;
  width: 8px;
  height: 8px;
  background: #005BAC;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__ttl02::before {
    width: 10px;
    height: 10px;
    top: 8px;
  }
}
.p-floor-system-product__frame {
  border: solid 1px #D1D4D7;
  padding: 30px 80px;
}
.p-floor-system-product__frame .p-floor-system-product__imgs {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
* projectフォルダの_index.scss
----------------------------------------------------------------*/
@media screen and (min-width: 768px) {
  .p-mv-content {
    position: relative;
    height: auto;
  }
}
.p-mv-content__slide-lists {
  position: relative;
  overflow: hidden;
  aspect-ratio: 375/273;
}
@media screen and (min-width: 768px) {
  .p-mv-content__slide-lists {
    aspect-ratio: 1600/710;
  }
}
.p-mv-content__slide-lists::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../images/top/bk_mv-sp.svg") no-repeat center;
  background-position: top;
  background-size: cover;
  height: 18.6666666667vw;
}
@media screen and (min-width: 768px) {
  .p-mv-content__slide-lists::after {
    background: none;
    height: 0;
  }
}
.p-mv-content__message-area {
  position: relative;
  padding-left: 25px;
  padding-right: 35px;
}
@media screen and (min-width: 768px) {
  .p-mv-content__message-area {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    z-index: 2;
    top: 18%;
    left: 4.5%;
    max-width: 500px;
    width: clamp(250px, 31.25vw, 500px);
  }
}
.p-mv-content__sp-dots {
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 768px) {
  .p-mv-content__sp-dots {
    display: none;
  }
}
.p-mv-content__sp-dots .slick-dots {
  bottom: auto;
  top: -56px;
  width: auto;
  margin-right: -5px;
}
.p-mv-content__sp-dots .slick-dots li {
  margin: 0;
  width: 10px;
  height: 10px;
  padding: 0;
}
.p-mv-content__sp-dots .slick-dots li + li {
  margin-left: 10px;
}
.p-mv-content__sp-dots .slick-dots li button {
  width: 10px;
  height: 10px;
  padding: 0;
}
.p-mv-content__sp-dots .slick-dots li button:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #a8c1d7;
  opacity: 1;
}
.p-mv-content__sp-dots .slick-dots li.slick-active {
  width: 12px;
  height: 12px;
}
.p-mv-content__sp-dots .slick-dots li.slick-active button {
  width: 12px;
  height: 12px;
}
.p-mv-content__sp-dots .slick-dots li.slick-active button:before {
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border: 2px solid #2995e5;
  opacity: 1;
}
.p-mv-content__message {
  padding-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-mv-content__message {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .p-mv-content__slide {
    position: relative;
  }
}
.p-mv-content__slide-area .slick-dotted.slick-slider {
  margin-bottom: 0;
}
.p-mv-content__slide-lists {
  line-height: 0;
  /* font-size: 0; ← こちらでも、余白がなくなります */
}
.p-mv-content__slide-item .p-mv-content__thum-item {
  position: absolute;
  display: none;
}
.p-mv-content__thum {
  display: none;
}
@media screen and (min-width: 768px) {
  .p-mv-content__thum {
    display: block;
    margin-top: 60px;
    margin-top: clamp(30px, 3.75vw, 60px);
    padding-left: clamp(30px, 4.375vw, 70px);
  }
}
.p-mv-content__thum-list .slick-dots {
  position: static;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.p-mv-content__thum-list li {
  display: inline-block;
  width: auto;
  border-left: 2px solid #a8c1d7;
  position: relative;
  padding-left: 26px;
  padding-bottom: 44px;
  padding-bottom: clamp(20px, 2.75vw, 44px);
  line-height: 1;
  font-size: 0.875rem;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #8e8e8e;
}
.p-mv-content__thum-list li span {
  transform: translateY(-2px);
  transition: 0.4s ease-in-out;
  position: relative;
}
.p-mv-content__thum-list li span::after {
  content: "";
  display: inline-block;
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2995e5;
  transition: 0.4s ease-in-out;
  transform: scale(0, 1);
  transform-origin: right top;
}
.p-mv-content__thum-list li.slick-active {
  color: #2995e5;
  font-weight: 700;
}
.p-mv-content__thum-list li.slick-active span::after {
  animation: SlideIn 1.5s forwards;
}
.p-mv-content__thum-list li.slick-active::before {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border: 3px solid #2995e5;
}
.p-mv-content__thum-list li:nth-last-of-type(1) {
  border-left: none;
}
.p-mv-content__thum-list li:nth-last-of-type(1)::before {
  left: 1px;
}
.p-mv-content__thum-list li::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -1px;
  background-color: #a8c1d7;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: translateX(-50%);
}
.p-mv-content__scroll-mark {
  font-family: "Zen Kaku Gothic New", sans-serif;
  letter-spacing: 0.05em;
  position: relative;
  text-transform: uppercase;
  color: #005bac;
  writing-mode: vertical-lr;
  position: absolute;
  padding-left: 2px;
  left: 5.5%;
  bottom: 6.5%;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .p-mv-content__scroll-mark {
    font-size: 0.875rem;
    letter-spacing: 0.02em;
    left: auto;
    right: 1.5%;
    bottom: 6%;
  }
}
.p-mv-content__scroll-mark span {
  position: relative;
}
.p-mv-content__scroll-mark span::after {
  content: "";
  display: inline-block;
  margin-top: 6px;
  border-top: 8px solid #005bac;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  right: 30%;
}
@media screen and (min-width: 768px) {
  .p-mv-content__scroll-mark span::after {
    border-top: 10px solid #005bac;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
}
.p-mv-content__news {
  display: none;
}
@media screen and (min-width: 768px) {
  .p-mv-content__news {
    display: block;
    background-color: #f8faf9;
    pointer-events: none;
    position: relative;
  }
  .p-mv-content__news::before {
    content: "";
    background-color: #005bac;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    transition: 0.4s ease-in-out;
  }
  .p-mv-content__news:hover::before {
    width: 100%;
  }
  .p-mv-content__news:hover .p-mv-content__news-sign,
.p-mv-content__news:hover .p-mv-content__news-date,
.p-mv-content__news:hover .p-mv-content__news-title {
    color: #ffffff;
  }
}
.p-mv-content__news-item {
  padding: 24px 0;
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 125px 100px 1fr;
  font-family: "Zen Kaku Gothic New", sans-serif;
  letter-spacing: 0.02em;
}
.p-mv-content__news-sign {
  font-size: 1rem;
  transition: 0.4s ease-in-out;
}
.p-mv-content__news-date {
  color: #005bac;
  transition: 0.4s ease-in-out;
}
.p-mv-content__news-title {
  font-size: 1rem;
  pointer-events: auto;
}
.p-mv-content__news-title a {
  transition: 0.4s ease-in-out;
}
.p-mv-content__news-title:hover {
  color: #ffffff;
}
.p-mv-content__news-title:hover a {
  opacity: 1;
}
.p-mv-content__news-title:hover a::after {
  transform: skew(45deg) translateX(5px);
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
.p-mv-content__news-title a {
  display: block;
  width: 100%;
}
.p-mv-content__news-title a::after {
  content: "";
  position: absolute;
  top: calc(50% - 6px);
  right: 0;
  display: inline-block;
  width: 27px;
  height: 10px;
  border-bottom: 1px solid #005bac;
  border-right: 1px solid #005bac;
  transform: skew(45deg);
  transition: 0.4s ease-in-out;
}

@keyframes SlideIn {
  0% {
    transform: scale(0, 1);
  }
  /*初期状態では透明に*/
  50% {
    transform: scale(1, 1);
    transform-origin: right top;
  }
  70% {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 1);
    transform-origin: left top;
  }
}
@media screen and (min-width: 768px) {
  .p-business-content__header {
    display: flex;
  }
}
.p-business-content__title {
  color: #ffffff;
  flex: 1;
}
.p-business-content__header-text {
  color: #ffffff;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  line-height: 2;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .p-business-content__header-text {
    width: 60.3448275862%;
    font-size: 1.125rem;
    margin-top: 0;
  }
}
.p-business-content__main {
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .p-business-content__main {
    margin-top: 85px;
  }
}
.p-business-content__main-lists {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}
@media screen and (min-width: 768px) {
  .p-business-content__main-lists {
    column-gap: 3.4482758621%;
    row-gap: 40px;
  }
}
@media screen and (min-width: 768px) {
  .p-business-content__main-item {
    width: 48.275862069%;
    background-color: #ffffff;
  }
  .p-business-content__main-item:nth-of-type(3) .c-list-item01__body {
    padding-bottom: 14px;
  }
}
.p-business-content__link-area {
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .p-business-content__link-area {
    margin-top: 80px;
  }
}

.p-product-content {
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .p-product-content {
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 768px) {
  .p-product-content__title {
    align-items: center;
  }
}
.p-product-content__title-en {
  color: #005bac;
}
.p-product-content__top {
  margin-top: 36px;
}
@media screen and (min-width: 768px) {
  .p-product-content__top {
    margin-top: 75px;
  }
}
.p-product-content__service-lists {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}
@media screen and (min-width: 768px) {
  .p-product-content__service-lists {
    column-gap: 1px;
  }
}
@media screen and (min-width: 1025px) {
  .p-product-content__service-lists {
    gap: 1px;
  }
}
.p-product-content__service-item {
  color: #ffffff;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-product-content__service-item {
    width: calc((100% - 2px) / 2);
  }
}
@media screen and (min-width: 1025px) {
  .p-product-content__service-item {
    width: calc((100% - 3px) / 4);
  }
}
.p-product-content__service-item .c-list-item01__body {
  padding: 14px 16px 16px;
  background-color: #005bac;
}
@media screen and (min-width: 768px) {
  .p-product-content__service-item .c-list-item01__body {
    padding: 12px 30px 16px;
  }
}
.p-product-content__service-item .c-list-item01__title {
  line-height: 1.5;
}
.p-product-content__service-item .c-circle-arrow01 {
  top: calc(50% - 12px);
}
@media screen and (min-width: 768px) {
  .p-product-content__service-item .c-circle-arrow01 {
    top: calc(50% - 17px);
  }
}
.p-product-content__service-item .c-circle-arrow01__arrow {
  background-color: #ffffff;
}
.p-product-content__service-item .c-circle-arrow01__arrow:after {
  border-bottom: 1px solid #005bac;
  border-right: 1px solid #005bac;
}

.p-pick-up-content {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: #f0f3f6;
}
.p-pick-up-content__title {
  text-align: center;
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-size: clamp(1.125rem, 2.0689655172vw, 1.5rem);
  font-weight: 700;
  color: #005bac;
  letter-spacing: 0.02em;
}
.p-pick-up-content__title span {
  position: relative;
  padding-left: 40px;
}
.p-pick-up-content__title span::before {
  content: "";
  display: inline-block;
  width: 28px;
  height: 30px;
  background: url("../../images/common/icon_pickup.svg") no-repeat center;
  background-size: cover;
  margin-right: 6px;
  position: absolute;
  left: 0;
  top: 2px;
}
@media screen and (min-width: 768px) {
  .p-pick-up-content__title span::before {
    width: 35px;
    height: 36px;
  }
}
.p-pick-up-content__lists {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 40px;
}
@media screen and (min-width: 1200px) {
  .p-pick-up-content__lists {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 768px) {
  .p-pick-up-content__item {
    width: calc(50% - 20px);
  }
}
@media screen and (min-width: 1200px) {
  .p-pick-up-content__item {
    width: 31.0344827586%;
  }
}
.p-pick-up-content__item .c-list-item01__body {
  padding: 20px 20px 24px;
}
@media screen and (min-width: 768px) {
  .p-pick-up-content__item .c-list-item01__body {
    padding: 30px 30px 40px;
  }
}
.p-pick-up-content__item .c-list-item01__title {
  color: #005bac;
  min-height: 48px;
  display: flex;
  align-items: center;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-pick-up-content__item .c-list-item01__title {
    min-height: 60px;
  }
}
.p-pick-up-content__item .c-circle-arrow01 {
  top: calc(50% - 12px);
}
@media screen and (min-width: 768px) {
  .p-pick-up-content__item .c-circle-arrow01 {
    top: calc(50% - 17px);
  }
}

@media screen and (min-width: 768px) {
  .p-case-content__header {
    display: flex;
  }
}
.p-case-content__title {
  flex: 1;
  margin-top: 16px;
}
.p-case-content__title-en {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .p-case-content__title-en {
    color: #005bac;
  }
}
.p-case-content__title-ja {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .p-case-content__title-ja {
    color: #232536;
  }
}
.p-case-content__header-text {
  color: #ffffff;
  letter-spacing: 0.02em;
  line-height: 2;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .p-case-content__header-text {
    width: 60.3448275862%;
    font-size: 1.125rem;
    margin-top: 0;
  }
}
.p-case-content__main {
  margin-top: 32px;
}
@media screen and (min-width: 768px) {
  .p-case-content__main {
    margin-top: 54px;
  }
}
@media screen and (min-width: 768px) {
  .p-case-content__slider-area {
    margin-right: calc(50% - 50vw);
  }
}
.p-case-content__item .c-list-item02__category {
  position: static;
}
.p-case-content__item .c-circle-arrow01 {
  bottom: 20px;
  right: 20px;
}
@media screen and (min-width: 768px) {
  .p-case-content__item .c-circle-arrow01 {
    bottom: 32px;
    right: 38px;
  }
}
@media screen and (min-width: 768px) {
  .p-case-content__slider .slick-list {
    padding-right: 24.5%;
    padding-right: max(50px, 21.25vw);
  }
}
.p-case-content__slider .slick-track {
  display: flex;
}
.p-case-content__slider .slick-slide {
  height: auto !important;
  background-color: #ffffff;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-case-content__slider .slick-slide {
    margin-right: 40px;
  }
}
.p-case-content__bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .p-case-content__bottom {
    margin-top: 60px;
  }
}
.p-case-content__link-area {
  flex: 1;
}
@media screen and (min-width: 768px) {
  .p-case-content__link-area .c-link-btn01 {
    margin-right: 0;
  }
}
.p-case-content__arrow {
  display: none;
}
@media screen and (min-width: 768px) {
  .p-case-content__arrow {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
.p-case-content__arrow .slick-num {
  font-family: "Zen Kaku Gothic New", sans-serif;
  order: 2;
  font-size: 1.25rem;
  letter-spacing: 0.02em;
  color: #005bac;
}
.p-case-content__arrow .slick-next {
  right: 0;
  order: 3;
}
.p-case-content__arrow .slick-prev,
.p-case-content__arrow .slick-next {
  position: static;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  background-color: #005bac;
  border-radius: 50%;
  top: 0;
  transform: translate(0, 0);
  transition: 0.4s ease-in-out;
}
.p-case-content__arrow .slick-prev:hover,
.p-case-content__arrow .slick-next:hover {
  opacity: 0.8;
}
.p-case-content__arrow .slick-prev::before,
.p-case-content__arrow .slick-next::before {
  content: "";
}
.p-case-content__arrow .slick-prev::after,
.p-case-content__arrow .slick-next::after {
  content: "";
  position: absolute;
  top: calc(50% - 2.5px);
  left: calc(50% - 10px);
  display: inline-block;
  width: 15px;
  height: 5px;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  transform: skew(45deg);
}
.p-case-content__arrow .slick-prev {
  left: 0;
  order: 1;
}
.p-case-content__arrow .slick-prev::after {
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  border-right: none;
  transform: skew(-45deg);
  left: calc(50% - 7.5px);
}

@media screen and (min-width: 768px) {
  .p-about-content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-left: calc(50% - 50vw);
  }
}
.p-about-content__left {
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .p-about-content__left {
    margin-top: 0;
    width: 48.5507246377%;
  }
}
.p-about-content__img {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-about-content__right {
    width: 39.1304347826%;
  }
}
.p-about-content__title {
  margin-top: -7px;
}
.p-about-content__title-en {
  color: #005bac;
}
.p-about-content__right-text {
  margin-top: 20px;
  letter-spacing: 0.02em;
  line-height: 2;
}
@media screen and (min-width: 768px) {
  .p-about-content__right-text {
    margin-top: 29px;
    font-size: 1.125rem;
  }
}
.p-about-content__link-lists {
  margin-top: 55px;
}
.p-about-content__link-item {
  border-bottom: 1px solid #d1d4d7;
  padding-bottom: 20px;
}
.p-about-content__link-item + .p-about-content__link-item {
  margin-top: 44px;
}
.p-about-content__link-item:hover .c-circle-arrow01 {
  transform: translateX(5px);
}
.p-about-content__link-item-box {
  position: relative;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}

.p-company-content__title {
  color: #ffffff;
}
.p-company-content__link-lists {
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .p-company-content__link-lists {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
}
.p-company-content__link-item {
  border-bottom: 1px solid #ffffff;
  padding-bottom: 20px;
}
.p-company-content__link-item + .p-company-content__link-item {
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  .p-company-content__link-item + .p-company-content__link-item {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .p-company-content__link-item {
    width: 30.1724137931%;
    padding-bottom: 25px;
  }
}
.p-company-content__link-item:hover .c-circle-arrow01 {
  transform: translateX(5px);
}
.p-company-content__link-item-box {
  position: relative;
  font-weight: 700;
  font-size: 1.125rem;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.p-company-content__link-item-box .c-circle-arrow01__arrow {
  background-color: #ffffff;
}
.p-company-content__link-item-box .c-circle-arrow01__arrow::after {
  border-bottom: 1px solid #005bac;
  border-right: 1px solid #005bac;
}

.p-recruitment-content__title-en {
  color: #005bac;
}
.p-recruitment-content__text {
  margin-top: 24px;
  letter-spacing: 0.02em;
  line-height: 2;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .p-recruitment-content__text {
    margin-top: 46px;
    font-size: 1.125rem;
  }
}
.p-recruitment-content__link-area {
  margin-top: 55px;
}
.p-recruitment-content__link-area .c-link-btn02 {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .p-recruitment-content__link-area .c-link-btn02 {
    margin-right: auto;
    margin-left: 0;
  }
}
.p-recruitment-content__link-area .c-link-btn02:hover .c-link-btn02__link-box {
  color: #005bac;
  background-color: #ffffff;
  border-color: #005bac;
}
.p-recruitment-content__link-area .c-link-btn02:hover .c-link-btn02__link-box::after {
  background: url(../../images/common/icon_blank-blue.svg) no-repeat center;
}
.p-recruitment-content__link-area .c-link-btn02__link-box {
  color: #ffffff;
  background-color: #005bac;
  border: #005bac 1px solid;
  transition: 0.4s ease-in-out;
}
.p-recruitment-content__link-area .c-link-btn02__link-box::after {
  transition: 0.3s ease-in-out;
  background: url(../../images/common/icon_blank-white.svg) no-repeat center;
}

@media screen and (min-width: 768px) {
  .p-news-content {
    display: flex;
    justify-content: space-between;
  }
}
.p-news-content__left {
  flex: 1;
}
.p-news-content__right {
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .p-news-content__right {
    margin-top: 0;
    width: 74.1379310345%;
  }
}
.p-news-content__title-en {
  color: #005bac;
}
.p-news-content__link-item {
  border-bottom: 1px solid #d1d4d7;
  padding-bottom: 12px;
}
@media screen and (min-width: 768px) {
  .p-news-content__link-item {
    display: grid;
    grid-template-columns: 120px 1fr;
    padding-bottom: 20px;
  }
}
.p-news-content__link-item + .p-news-content__link-item {
  margin-top: 28px;
}
.p-news-content__link-item-date {
  color: #005bac;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .p-news-content__link-item-date {
    padding-left: 14px;
  }
}
.p-news-content__link-item-subject {
  padding-right: 50px;
  position: relative;
  color: #8e8e8e;
  font-size: 1rem;
  letter-spacing: 0.02em;
}
.p-news-content__link-item-subject:hover .c-circle-arrow01 {
  transform: translateX(5px);
}
.p-news-content__link-item-subject .c-circle-arrow01 {
  right: 15px;
}
.p-news-content__link-item-subject .c-circle-arrow01__arrow {
  background-color: transparent;
}
.p-news-content__link-item-subject .c-circle-arrow01__arrow::after {
  width: 30px;
  height: 8px;
  border-bottom: 1px solid #005bac;
  border-right: 1px solid #005bac;
  top: calc(50% - 4px);
  left: calc(50% - 15px);
}
.p-news-content__link-area {
  margin-top: 60px;
}
.p-news-content__link-area .c-link-btn01:hover .c-link-btn01__link-box {
  background-color: #ffffff;
  color: #005bac;
  border-color: #005bac;
}
.p-news-content__link-area .c-link-btn01:hover .c-link-btn01__link-box::after {
  border-bottom: 1px solid #005bac;
  border-right: 1px solid #005bac;
}
.p-news-content__link-area .c-link-btn01__link-box {
  background-color: #005bac;
  color: #ffffff;
}
.p-news-content__link-area .c-link-btn01__link-box::after {
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

/*--------------------------------------------------------------
* projectフォルダの_index.scss
----------------------------------------------------------------*/
.u-sp-db {
  display: block;
}
@media screen and (min-width: 768px) {
  .u-sp-db {
    display: inline-block;
  }
}

.u-sp-only {
  display: block;
}
@media screen and (min-width: 768px) {
  .u-sp-only {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .u-sp-tel-only {
    pointer-events: none;
  }
}

.u-pc-only {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-pc-only {
    display: block;
  }
}

@media screen and (min-width: 1025px) {
  .u-pc-only-lg {
    display: block;
  }
  .u-pc-only-lg .u-pc-only {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .u-pc-only-lg {
    display: inline;
  }
  .u-pc-only-lg .u-pc-only {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .u-pb-0 {
    padding-bottom: 0;
  }
}

.u-pt-24px {
  padding-top: 24px;
}
@media screen and (min-width: 768px) {
  .u-pt-24px {
    padding-top: 0;
  }
}

.u-border-none {
  border: none;
}

.u-small-text-nowrap {
  white-space: nowrap;
  transform: scale(0.8);
}
@media screen and (min-width: 768px) {
  .u-small-text-nowrap {
    transform: scale(1);
  }
}

.u-bg-transparent {
  background-color: transparent !important;
}

.u-left-10 {
  left: -15px;
}
@media screen and (min-width: 768px) {
  .u-left-10 {
    left: -10px;
  }
}

/*--------------------------------------------------------------
* utilityフォルダの_index.scss
----------------------------------------------------------------*/
.grecaptcha-badge {
  bottom: 100px !important;
}

/*--------------------------------------------------------------
* externalフォルダの_index.scss
----------------------------------------------------------------*/
/* =========================================================================================================
	* layout _l
========================================================================================================= */
/* =========================================================================================================
  * component _c
========================================================================================================= */
/* =========================================================================================================
* javascript //jsで操作される設定  _js
========================================================================================================= */
/* =========================================================================================================
	* project _p
========================================================================================================= */
/* =========================================================================================================
	* utility //調整用ファイル _u
========================================================================================================= */
/* =========================================================================================================
	* external //外部ライブラリーの上書き _ex
========================================================================================================= */