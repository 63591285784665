@use "../global/" as g;

.js-fade-up {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
  transform: translateY(40px);

  &.is-show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}
