@use "../../global/" as g;

.p-about-content {
  @include g.mq(md) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    //   gap: calc(170 / 1380 * 100%);
    margin-left: calc(50% - 50vw);
  }

  &__left {
    margin-top: 40px;
    @include g.mq(md) {
      margin-top: 0;
      width: calc(670 / 1380 * 100%);
    }
  }

  &__img {
    width: 100%;
  }

  &__right {
    @include g.mq(md) {
      width: calc(540 / 1380 * 100%);
    }
  }

  &__title {
    margin-top: -7px;
  }

  &__title-en {
    color: g.$blue02;
  }

  &__right-text {
    margin-top: 20px;
    letter-spacing: 0.02em;
    @include g.lh(18, 36);

    @include g.mq(md) {
      margin-top: 29px;
      font-size: g.rem(18);
    }
  }

  &__link-lists {
    margin-top: 55px;
  }

  &__link-item {
    border-bottom: 1px solid g.$border;
    padding-bottom: 20px;

    + .p-about-content__link-item {
      margin-top: 44px;
    }

    &:hover {
      .c-circle-arrow01 {
        transform: translateX(5px);
      }
    }
  }

  &__link-item-box {
    position: relative;
    font-size: g.rem(18);
    font-weight: 700;
    letter-spacing: 0.02em;
  }
}
