@use "../global/" as g;

.c-accordion {
  &__ttl {
    position: relative;
    background: #005bac;
    border: solid 1px #005bac;
    text-align: center;
    padding: 10px 32px 12px;
    margin-bottom: 15px;
    @include g.mq("md") {
      margin-bottom: 17px;
      padding: 19px 65px 21px;
      &:hover {
        opacity: 0.7;
        cursor: pointer;
        transition: all 0.5s;
      }
    }
    &::before,
    &::after {
      position: absolute;
      content: "";
      background: #fff;
    }
    &::before {
      width: 18px;
      height: 1px;
      right: 20px;
      top: 50%;

      // transform: translateY(-50%);
      @include g.mq("md") {
        width: 27px;
        height: 2px;
        right: 40px;
      }
    }
    &::after {
      width: 18px;
      height: 1px;
      right: 20px;
      top: calc(50% - 1px);
      transition: 0.4s ease-in-out;
      transform: rotate(90deg);
      @include g.mq("md") {
        width: 27px;
        height: 2px;
        width: 27px;
        height: 2px;
        right: 40px;
        top: calc(50% + 1px);
      }
    }
    &.is-open {
      &::after {
        opacity: 0;
        visibility: hidden;
        transform: rotate(0deg);
      }
    }
    .ttl {
      color: #fff;
      font-size: g.rem(16);
      font-weight: bold;
      line-height: 1.5em;

      @include g.mq("md") {
        font-size: g.rem(18);
      }
    }
  }
  &__content {
    display: none;
  }
  .c-stbl__row {
    &:first-child {
      .c-stbl__head,
      .c-stbl__content {
        border-top: 0;
      }
    }
  }
}
