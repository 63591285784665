@use "../global/" as g;

.c-list-item01 {
  &:hover {
    img {
      transform: scale(1.1);
    }

    a {
      opacity: 1;
    }

    .c-circle-arrow01 {
      transform: translateX(5px);
    }
  }
  &__img {
    overflow: hidden;
    img {
      transition: 0.4s ease-in-out;
    }
  }

  &__body {
    background-color: g.$white;
    letter-spacing: 0.02em;
    padding: 20px 20px 24px;

    @include g.mq(md) {
      padding: 36px 40px 46px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: g.rem(16);
    position: relative;

    @include g.mq(md) {
      font-size: g.rem(20);
    }
  }

  &__text {
    @include g.lh(16, 32);
    margin-top: 12px;

    @include g.mq(md) {
      font-size: g.rem(16);
    }
  }
}
