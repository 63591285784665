@use "../global/" as g;

.c-link-btn02 {
  max-width: 400px;
  width: 100%;

  &:hover {
    a {
      opacity: 1;
    }

    .c-link-btn02__link-box {
      background-color: g.$blue02;
      color: g.$white;
      border: g.$blue02 1px solid;

      &::after {
        background: url(../../images/common/icon_blank-blue.svg) no-repeat
          center;
      }
    }
  }

  &__link-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: g.$white;
    color: g.$blue02;
    border: g.$white 1px solid;
    font-weight: 700;
    position: relative;
    font-size: g.rem(16);
    padding: 8px 0;
    border-radius: 35px;
    transition: 0.4s ease-in-out;

    @include g.mq(md) {
      font-size: g.rem(18);
      padding: 16px 0;
    }

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      right: 26px;
      top: calc(50% - 7.5px);
      background: url(../../images/common/icon_blank.svg) no-repeat center;
      background-size: cover;
      width: 20px;
      height: 15px;
      transition: 0.4s ease-in-out;
    }
  }
}
