@use "../global/"as g;

.c-slist {
    &-num01 {
        counter-reset: list-num;

        >li {
            color: #232536;
            position: relative;
            font-size: g.rem(14);
            letter-spacing: 0.02em;
            padding-left: 15px;
            font-weight: 400;
            margin-bottom: 12px;
            line-height: 1.5em;

            @include g.mq('md') {
                line-height: 2em;
                margin-bottom: 16px;
                font-size: g.rem(16);
                padding-left: 35px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                color: #232536;
                font-size: g.rem(14);
                font-weight: bold;
                letter-spacing: 0.02em;
                position: absolute;
                left: 0;
                top: -1px;
                counter-increment: list-num;
                content: counter(list-num) '.';
                line-height: 1.5em;

                @include g.mq('md') {
                    font-size: g.rem(16);
                    line-height: 2em;
                    left: 6px;
                }
            }

            &.ttl,
            .ttl {
                font-weight: bold;
                line-height: 1.5em;

                @include g.mq('md') {
                    line-height: 2em;
                }
            }

            .ttl {
                margin-bottom: 5px;

                @include g.mq('md') {
                    margin-bottom: 7px;
                }
            }

            .desc {
                color: #232536;
                font-weight: 400;
                letter-spacing: 0.02em;
            }
        }
    }

    &-num02 {
        >li {
            color: #232536;
            position: relative;
            font-size: g.rem(14);
            letter-spacing: 0.02em;
            line-height: 1.5em;
            font-weight: 400;
            margin-bottom: 8px;
            display: inline-flex;
            width: 100%;

            @include g.mq('md') {
                margin-bottom: 8px;
                font-size: g.rem(16);
                line-height: 1.9em;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .num02 {
                display: inline-block;
                margin-right: 5px;

                @include g.mq('md') {
                    margin-right: 10px;
                }
            }
        }
    }

    &-num03 {
        >li {
            color: #232536;
            position: relative;
            font-size: g.rem(14);
            letter-spacing: 0.02em;
            font-weight: 500;
            margin-bottom: 8px;
            line-height: 1.5em;
            width: 100%;
            padding-left: 12px;

            @include g.mq('md') {
                margin-bottom: 8px;
                font-size: g.rem(16);
                padding-left: 16px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .num {
                position: absolute;
                left: 0;
                top: 0;
                display: inline-block;
                // margin-right: 5px;
                color: #005BAC;
                font-weight: bold;
                width: auto;

                @include g.mq('md') {
                    // margin-right: 10px;
                }
            }

            .ttl {
                display: inline-block;
                width: auto;
                padding-left: 5px;
            }
        }

        &.has-bg {
            >li {
                background: #F8FAF9;
                padding: 10px 20px 10px 34px;
                font-size: g.rem(16);
                @include g.mq('md') {
                    font-size: g.rem(18);
                    padding: 17px 15px 15px 40px;
                    // margin-bottom: 11px;
                }
                .num{
                    left: 15px;
                    top: 8px;
                    @include g.mq('md') {
                        left: 18px;
                        top: 17px;
                    }
                }
                .ttl{
                    padding-left: 0;
                }
                &.h100{
                    height: 100%;
                }
            }
        }
    }
}
