@use "../global/" as g;

.c-icon-blank {
  position: relative;

  &::after {
    content: "";
    display: inline-block;
    background: url("../../images/common/icon_blank.svg") no-repeat center;
    background-size: cover;
    width: 20px;
    height: 15px;
  }
}
