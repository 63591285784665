@use "../../global/" as g;

.l-product {
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: g.$gray03;

  @include g.mq(md) {
    padding-top: 118px;
    padding-bottom: 180px;
  }

  &__top {
    position: relative;
    overflow: hidden;
  }

  &__bottom {
    position: relative;
    z-index: 2;
  }

  &__top-inner {
    position: relative;
    z-index: 2;
  }

  //   bk パス
  &__bk-content01 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: -50vw;
    right: -60vw;
    transform: rotate(-15deg);
    display: none;

    @include g.mq(md) {
      display: block;
      bottom: -31vw;
      right: -18vw;
      transform: rotate(-15deg);
    }

    @include g.mq(xl) {
      display: block;
      bottom: -31vw;
      right: -14vw;
      transform: rotate(-15deg);
    }

    svg {
      // width: 100vw;
      aspect-ratio: 407 / 785;
      width: clamp(400px, calc(100vw / 1600 * 407), 407px);
    }

    // @include g.mq(lg) {
    //    bottom: -8%;
    //   left: -28%;
    // }
  }

  &__bk-content02 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: -50vw;
    right: -30vw;
    transform: rotate(-11deg);
    display: none;

    @include g.mq(md) {
      display: block;
      bottom: -25vw;
      right: -7vw;
      transform: rotate(-11deg);
    }
    svg {
      // width: 100vw;
      aspect-ratio: 225 / 770;
      // width: calc(100vw / 1600 * 800);
      width: clamp(200px, calc(100vw / 1600 * 225), 225px);
    }
  }

  // 下段
  &__bk-content03 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: -30vw;
    right: -17vw;
    transform: rotate(-47deg);
    display: none;

    @include g.mq(md) {
      display: block;
      bottom: -22vw;
      right: -17vw;
      transform: rotate(-47deg);
    }

    svg {
      // width: 100vw;

      aspect-ratio: 407 / 785;

      width: clamp(400px, calc(100vw / 1600 * 407), 407px);
    }

    // @include g.mq(lg) {
    //    bottom: -8%;
    //   left: -28%;
    // }
  }

  &__bk-content04 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: -30vw;
    right: -8vw;
    transform: rotate(-36deg);
    display: none;

    @include g.mq(md) {
      display: block;
      bottom: -21vw;
      right: -8vw;
      transform: rotate(-31deg);
    }
    svg {
      // width: 100vw;
      aspect-ratio: 225 / 770;
      // width: calc(100vw / 1600 * 800);
      width: clamp(200px, calc(100vw / 1600 * 225), 225px);
    }
  }
}
