@use "../../global/" as g;

.p-mv-content {
  @include g.mq(md) {
    position: relative;
    height: auto;
  }

  &__slide-lists {
    position: relative;
    overflow: hidden;
    aspect-ratio: 375 / 273;

    @include g.mq(md) {
      aspect-ratio: 1600 / 710;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("../../images/top/bk_mv-sp.svg") no-repeat center;
      background-position: top;
      background-size: cover;
      height: calc(100vw / 375 * 70);

      @include g.mq(md) {
        background: none;
        height: 0;
      }
    }
  }
  &__message-area {
    position: relative;
    padding-left: 25px;
    padding-right: 35px;

    @include g.mq(md) {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
      position: absolute;
      z-index: 2;
      top: 18%;
      left: 4.5%;
      max-width: 500px;
      width: clamp(250px, calc(100vw / 1600 * 500), 500px);
    }
  }

  // sp のdots
  &__sp-dots {
    display: flex;
    justify-content: flex-end;
    @include g.mq(md) {
      display: none;
    }

    .slick-dots {
      bottom: auto;
      top: -56px;
      width: auto;
      margin-right: -5px;

      li {
        margin: 0;
        width: 10px;
        height: 10px;
        padding: 0;

        + li {
          margin-left: 10px;
        }

        button {
          width: 10px;
          height: 10px;
          padding: 0;
          &:before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #a8c1d7;
            opacity: 1;
          }
        }

        &.slick-active {
          width: 12px;
          height: 12px;
          button {
            width: 12px;
            height: 12px;
            &:before {
              width: 12px;
              height: 12px;
              background-color: g.$white;
              border: 2px solid g.$blue;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__message {
    padding-bottom: 20px;

    @include g.mq(md) {
      padding-bottom: 0;
    }
  }

  &__slide {
    @include g.mq(md) {
      position: relative;
    }
  }

  &__slide-area {
    .slick-dotted.slick-slider {
      margin-bottom: 0;
    }
  }

  &__slide-lists {
    line-height: 0;
    /* font-size: 0; ← こちらでも、余白がなくなります */
  }

  &__slide-item {
    .p-mv-content__thum-item {
      position: absolute;
      display: none;
    }
  }

  &__thum {
    display: none;
    @include g.mq(md) {
      display: block;
      margin-top: 60px;
      margin-top: clamp(30px, calc(100vw / 1600 * 60), 60px);
      padding-left: clamp(30px, calc(100vw / 1600 * 70), 70px);
    }
  }

  &__thum-list {
    .slick-dots {
      position: static;
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    li {
      display: inline-block;
      width: auto;
      border-left: 2px solid #a8c1d7;
      position: relative;
      padding-left: 26px;
      padding-bottom: 44px;
      padding-bottom: clamp(20px, calc(100vw / 1600 * 44), 44px);
      line-height: 1;
      font-size: g.rem(14);
      font-family: g.$ZenKaku;
      font-weight: 500;
      letter-spacing: 0.02em;
      color: #8e8e8e;

      span {
        transform: translateY(-2px);
        transition: 0.4s ease-in-out;
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          display: block;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: g.$blue;
          transition: 0.4s ease-in-out;
          transform: scale(0, 1);
          transform-origin: right top;
        }
      }

      &.slick-active {
        color: g.$blue;
        font-weight: 700;

        span {
          &::after {
            animation: SlideIn 1.5s forwards;
          }
        }

        &::before {
          width: 18px;
          height: 18px;
          background-color: g.$white;
          border: 3px solid g.$blue;
        }
      }

      &:nth-last-of-type(1) {
        border-left: none;

        &::before {
          left: 1px;
        }
      }

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        left: -1px;
        background-color: #a8c1d7;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: translateX(-50%);
      }
    }
  }
  //   scrollのマーク

  &__scroll-mark {
    font-family: g.$ZenKaku;
    letter-spacing: 0.05em;
    position: relative;
    text-transform: uppercase;
    color: g.$blue02;
    writing-mode: vertical-lr;
    position: absolute;
    padding-left: 2px;
    left: 5.5%;
    bottom: 6.5%;
    font-size: g.rem(12);

    @include g.mq(md) {
      font-size: g.rem(14);
      letter-spacing: 0.02em;
      left: auto;
      right: 1.5%;
      bottom: 6%;
    }

    span {
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        margin-top: 6px;
        border-top: 8px solid g.$blue02;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        position: absolute;
        right: 30%;

        @include g.mq(md) {
          border-top: 10px solid g.$blue02;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
        }
      }
    }
  }

  // 新着newsの箇所

  &__news {
    display: none;
    @include g.mq(md) {
      display: block;
      background-color: g.$gray03;
      pointer-events: none;
      position: relative;

      &::before {
        content: "";
        background-color: g.$blue02;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        // width: 100%;
        width: 0;
        transition: 0.4s ease-in-out;
      }

      &:hover {
        &::before {
          width: 100%;
        }

        .p-mv-content__news-sign,
        .p-mv-content__news-date,
        .p-mv-content__news-title {
          color: g.$white;
        }
      }
    }
  }

  &__news-item {
    padding: 24px 0;
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 125px 100px 1fr;
    font-family: g.$ZenKaku;
    letter-spacing: 0.02em;
  }

  &__news-sign {
    font-size: g.rem(16);
    transition: 0.4s ease-in-out;
  }

  &__news-date {
    color: g.$blue02;
    transition: 0.4s ease-in-out;
  }

  &__news-title {
    font-size: g.rem(16);
    pointer-events: auto;

    a {
      transition: 0.4s ease-in-out;
    }

    &:hover {
      color: g.$white;
      a {
        opacity: 1;
        &::after {
          transform: skew(45deg) translateX(5px);
          border-bottom: 1px solid g.$white;
          border-right: 1px solid g.$white;
        }
      }
    }

    a {
      display: block;
      width: 100%;

      &::after {
        content: "";
        position: absolute;
        top: calc(50% - 6px);
        right: 0;
        display: inline-block;
        width: 27px;
        height: 10px;
        border-bottom: 1px solid g.$blue02;
        border-right: 1px solid g.$blue02;
        transform: skew(45deg);
        transition: 0.4s ease-in-out;
      }
    }
  }
}

@keyframes SlideIn {
  0% {
    transform: scale(0, 1);
  } /*初期状態では透明に*/
  50% {
    transform: scale(1, 1);
    transform-origin: right top;
  }
  70% {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 1);
    transform-origin: left top;
  }
}
