@use "../global/" as g;

.c-content-width01 {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;

  @include g.mq(md) {
    max-width: g.$pc-layout-width-inner;
    padding-left: 40px;
    padding-right: 40px;
  }
}
