@use "../global/" as g;

.u-pc-only-lg {
  @include g.mq(lg) {
    display: block;

    .u-pc-only {
      display: none;
    }
  }

  @include g.mq(xl) {
    display: inline;
    .u-pc-only {
      display: block;
    }
  }
}
