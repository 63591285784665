@use "../../global/" as g;

.p-pick-up-content {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: g.$gray;

  &__title {
    text-align: center;
    font-size: g.rem(24);
    @include g.clamp-fz(18, 24, 1160);
    font-weight: 700;
    color: g.$blue02;
    letter-spacing: 0.02em;

    span {
      position: relative;
      padding-left: 40px;

      &::before {
        content: "";
        display: inline-block;
        width: 28px;
        height: 30px;
        background: url("../../images/common/icon_pickup.svg") no-repeat center;
        background-size: cover;
        margin-right: 6px;
        position: absolute;
        left: 0;
        top: 2px;

        @include g.mq(md) {
          width: 35px;
          height: 36px;
        }
      }
    }
  }

  &__lists {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 40px;

    @include g.mq(xl) {
      flex-wrap: nowrap;
    }
  }

  &__item {
    @include g.mq(md) {
      width: calc(50% - 20px);
    }

    @include g.mq(xl) {
      width: calc(360 / 1160 * 100%);
    }
    .c-list-item01__body {
      padding: 20px 20px 24px;

      @include g.mq(md) {
        padding: 30px 30px 40px;
      }
    }
    .c-list-item01__title {
      color: g.$blue02;
      min-height: 48px;
      display: flex;
      align-items: center;
      @include g.lh(20, 30);

      @include g.mq(md) {
        min-height: 60px;
      }
    }

    .c-circle-arrow01 {
      top: calc(50% - 12px);

      @include g.mq(md) {
        top: calc(50% - 17px);
      }
    }
  }
}
