@use "../../global/" as g;

.p-floor-system-product{
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: solid 1px #D1D4D7;
    @include g.mq('md') {
        padding-bottom: 100px;
        margin-bottom: 100px;
    }
    
    &:last-child{
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
    }
    &__imgs{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;
        @include g.mq('md'){
            align-items: center;
            flex-wrap: nowrap;
            margin-bottom: 40px;
        }
        &.flex-start{
            @include g.mq('md') {
                align-items: flex-start;
            }
        }
    }
    &__img01{
        position: relative;
        width: 100%;
        text-align: center;
        margin: 0 auto 30px;
        @include g.mq('md'){
            width: 48.277%;
            margin: 0;
        }
    }
    &__img02{
        position: relative;
        width: 100%;
        margin: 0 auto;
        @include g.mq('md'){
            margin: 0;
            width: calc(51.723% - 40px);
        }
    }
    &__ttl01{
        color: #232536;
        font-size: g.rem(20);
        font-weight: bold;
        letter-spacing: 0.02em;
        line-height: 1.533em;
        margin-bottom: 20px;
        @include g.mq('md') {
            margin-bottom: 28px;
            font-size: g.rem(24);
        }
    }
    &__ttl02{
        position: relative;
        color: #005BAC;
        font-size: g.rem(14);
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: 1.5em;
        margin-bottom: 5px;
        padding-left: 20px;
        @include g.mq('md') {
            padding-left: 20px;
            margin-bottom: 10px;
            font-size: g.rem(16);
        }
        &::before{
            position: absolute;
            content:'';
            top: 7px;
            left: 0;
            width: 8px;
            height: 8px;
            background: #005BAC;
            @include g.mq('md') {
                width: 10px;
                height: 10px;
                top:8px;
            }
        }
    }
    &__frame{
        border: solid 1px #D1D4D7;
        padding: 30px 80px;
        .p-floor-system-product__imgs{
            margin-bottom: 0;
        }
    }
}
