@use "../global/" as g;

.c-list-item02 {
  &:hover {
    img {
      transform: scale(1.1);
    }

    a {
      opacity: 1;
    }

    .c-circle-arrow01 {
      transform: translateX(5px);
    }
  }
  &__img {
    overflow: hidden;
    img {
      transition: 0.4s ease-in-out;
      object-fit: cover;
      aspect-ratio: 480 / 280;
    }
  }

  &__body {
    background-color: g.$white;
    letter-spacing: 0.02em;
    padding: 20px 20px 30px;

    @include g.mq(md) {
      padding: 32px 40px 55px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: g.rem(18);
    position: relative;
    color: g.$blue02;
    line-height: 1.5;

    @include g.mq(md) {
      font-size: g.rem(20);
    }
  }

  &__text {
    @include g.lh(16, 32);
    margin-top: 8px;

    @include g.mq(md) {
      font-size: g.rem(16);
      margin-top: 12px;
    }
  }

  &__category {
    margin-top: 16px;
    position: relative;
    padding-right: 34px;
  }

  &__facilities,
  &__product {
    color: #8e8e8e;
  }
}
