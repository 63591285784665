@use "../../global/" as g;

.l-news {
  position: relative;
  overflow: hidden;
  background-size: 100%;
  background-color: g.$gray03;
  padding-top: 50px;
  padding-bottom: 60px;

  @include g.mq(md) {
    padding-top: 174px;
    padding-bottom: 160px;
  }

  &__inner {
    position: relative;
    z-index: 3;
  }

  //   bk パス
  &__bk-content01 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: -5vw;
    right: 3vw;
    transform: rotate(-11deg);

    svg {
      // width: 100vw;
      aspect-ratio: 531 / 2591;
      width: clamp(400px, calc(100vw / 1600 * 531), 531px);
    }

    // @include g.mq(lg) {
    //    bottom: -8%;
    //   left: -28%;
    // }
  }

  &__bk-content02 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: 0vw;
    right: 3vw;
    transform: rotate(-11deg);
    svg {
      // width: 100vw;
      aspect-ratio: 388 / 2244;
      // width: calc(100vw / 1600 * 800);
      width: clamp(200px, calc(100vw / 1600 * 388), 2244px);
    }
  }
}
