@use "../../global/" as g;

.l-about {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: g.$gray03;

  @include g.mq(md) {
    padding-top: 205px;
    padding-bottom: 166px;
  }

  &__bk {
  }

  &__bk-content01 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: -49vw;
    left: -41vw;
    transform: rotate(-139deg);

    svg {
      // width: 100vw;
      aspect-ratio: 1572 / 1556;
      width: clamp(400px, calc(100vw / 1600 * 1572), 1572px);
    }

    // @include g.mq(lg) {
    //    bottom: -8%;
    //   left: -28%;
    // }
  }

  &__bk-content02 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: -46vw;
    left: -48vw;
    transform: rotate(-144deg);
    svg {
      // width: 100vw;
      aspect-ratio: 1502 / 1515;
      // width: calc(100vw / 1600 * 800);
      width: clamp(400px, calc(100vw / 1600 * 1502), 1502px);
    }
  }
}
