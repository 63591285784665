@use "../global/" as g;

.c-sbtn {
  &__link {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: solid 1px #d1d4d7;
    @include g.mq("md") {
      min-width: 145px;
      padding-bottom: 15px;
      &:hover {
        .c-circle-arrow01__arrow {
          transform: translateX(5px);
        }

        .c-circle-arrow03__arrow {
          transform: translateX(-5px);
        }
      }
    }
    .c-circle-arrow01__arrow {
      transition: 0.4s ease-in-out;
      &::after {
        top: calc(50% - 3px);
        left: calc(50% - 9px);
      }
    }

    .c-circle-arrow03__arrow {
      transition: 0.4s ease-in-out;
    }
  }
  &__txt {
    color: #333;
    font-weight: bold;
    font-size: g.rem(16);
    letter-spacing: 0.02em;
    line-height: 1.5em;
    padding-right: 19px;
    margin-right: auto;
    @include g.mq("md") {
      font-size: g.rem(18);
    }
  }
  &__product {
    margin: 45px 0 0;
    text-align: center;
    @include g.mq("md") {
      margin: 100px 0 0;
    }
  }
}
