@use "../../global/" as g;
.p-logistics-system-specification{
    .c-section-stitle01{
        margin-bottom: 20px;
        @include g.mq('md'){
            margin-bottom: 38px;
        }
    }
    .c-stbl{
        &__head {
            @include g.mq('md'){
                width: 22.2%;
                padding: 14px 30px;
            }
        }
        &__content{
            @include g.mq('md'){
                width: 78.8%;
                padding: 14px 43px;
            }
        }
    }
}
.p-logistics-system-diagram{
    .c-section-stitle01{
        margin-bottom: 20px;
        @include g.mq('md'){
            margin-bottom: 40px;
        }
    }
    &__img{
        text-align: center;
        width: 100%;
        line-height: 0;
    }
}
.p-logistics-system-station {
    .c-section-stitle01 {
        margin-bottom: 20px;
        @include g.mq('md') {
            margin-bottom: 41px;
        }
    }
    .desc{
        font-size: g.rem(14);
        font-weight: 400;
        letter-spacing: 0.02em;
        color: #232536;
        @include g.mq('md'){
            font-size: g.rem(16);
        }
    }
}
.p-logistics-system{
    &__list{
        display: flex;
        flex-wrap: wrap;
        @include g.mq('md'){
            flex-wrap: nowrap;
        }
    }
    &__i{
        width : 100%;
        margin: 0 0 50px;
        @include g.mq('md'){
            width: 31.035%;
            margin: 0 25px 0 0;
            &:nth-child(3n + 3){
                margin: 0;
            }
        }
        @include g.mq('xl'){
            margin: 0 40px 0 0;
        }
        &:last-child{
            margin: 0;
        }
    }
    &__iimg{
        text-align: center;
        margin: 0 auto 10px;
        line-height: 0;
        @include g.mq('md'){
            margin: 0 auto 28px;
        }
    }
    &__idesc{
        font-size: g.rem(14);
        font-weight: 400;
        letter-spacing: 0.02em;
        color: #232536;
        line-height: 1.7em;
        @include g.mq('md'){
            line-height: 2em;
            font-size: g.rem(16);
        }
    }
    &__ittl{
        font-size: g.rem(18);
        font-weight: bold;
        letter-spacing: 0.02em;
        margin-bottom: 2px;
        color: #005BAC;
        line-height: 1.7em;
        @include g.mq('md'){
            margin-bottom: 13px;
            font-size: g.rem(20);
        }
    }
}
