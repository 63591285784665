@use "../../global/" as g;

.p-recruitment-content {
  &__title-en {
    color: g.$blue02;
  }

  &__text {
    margin-top: 24px;
    letter-spacing: 0.02em;
    @include g.lh(18, 36);
    font-size: g.rem(16);

    @include g.mq(md) {
      margin-top: 46px;
      font-size: g.rem(18);
    }
  }

  &__link-area {
    margin-top: 55px;

    .c-link-btn02 {
      margin: 0 auto;

      @include g.mq(md) {
        margin-right: auto;
        margin-left: 0;
      }

      &:hover {
        .c-link-btn02__link-box {
          color: g.$blue02;
          background-color: g.$white;
          border-color: g.$blue02;

          &::after {
            background: url(../../images/common/icon_blank-blue.svg) no-repeat
              center;
          }
        }
      }
    }

    .c-link-btn02__link-box {
      color: g.$white;
      background-color: g.$blue02;
      border: g.$blue02 1px solid;
      transition: 0.4s ease-in-out;

      &::after {
        transition: 0.3s ease-in-out;
        background: url(../../images/common/icon_blank-white.svg) no-repeat
          center;
      }
    }
  }
}
