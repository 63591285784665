@use "../global/" as g;

.c-link-btn01 {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;

  &:hover {
    a {
      opacity: 1;
    }
    .c-link-btn01__link-box {
      background-color: g.$blue02;
      color: g.$white;
      border: g.$white 1px solid;
      &::after {
        transform: skew(45deg) translateX(5px);
        border-bottom: 1px solid g.$white;
        border-right: 1px solid g.$white;
      }
    }
  }

  &__link-box {
    display: flex;
    justify-content: center;
    background-color: g.$white;
    color: g.$blue02;
    font-weight: 700;
    position: relative;
    font-size: g.rem(16);
    padding: 8px 0;
    border-radius: 35px;
    border: g.$blue02 1px solid;
    transition: 0.4s ease-in-out;

    @include g.mq(md) {
      font-size: g.rem(18);
      padding: 16px 0;
    }

    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 6px);
      right: 8.5%;
      display: inline-block;
      width: 27px;
      height: 10px;
      border-bottom: 1px solid #005bac;
      border-right: 1px solid #005bac;
      -webkit-transform: skew(45deg);
      transform: skew(45deg);
      transition: 0.4s ease-in-out;

      @include g.mq(md) {
      }
    }
  }
}
