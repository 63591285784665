@use "../global/" as g;

.c-circle-arrow02 {
  position: relative;
  right: 0;
  transition: 0.4s ease-in-out;

  &__arrow {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 34px;

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: calc(50% - 1.5px);
      background: url(../../images/common/icon_blank-white.svg) no-repeat center;
      background-size: cover;
      width: 20px;
      height: 15px;
      margin-left: 8px;
    }
  }
}
