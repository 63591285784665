@use "../../global/" as g;

.l-recruitment {
  position: relative;
  background: url("../../images/top/bk_recruitment.webp") no-repeat right bottom;
  background-size: 130%;
  background-position: right top;
  padding-top: 93px;
  padding-bottom: 85px;

  @include g.mq(md) {
    background-size: cover;
  }
}
