@use "../global/" as g;
.c-slist-anchors01 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include g.mq("md") {
    justify-content: flex-start;
  }
}
.c-slist-anchor01 {
  margin: 0 0 25px 0;
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 0 20px 5px 0;
  border-bottom: solid 1px #005bac;
  width: 100%;
  @include g.mq("sm") {
    width: 47%;
  }
  @include g.mq("md") {
    transition: all 0.5s;
    padding: 0 28px 4px 0;
    margin: 0 40px 36px 0;
    width: auto;
    &:hover {
      opacity: 0.7;
    }
  }

  &:last-child {
    margin: 0 auto;
    @include g.mq("md") {
      margin: 0 0 36px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: calc(50% - 5px);
    -webkit-transform: translateY(-50%) rotate(135deg);
    transform: translateY(-50%) rotate(135deg);
    border-top: solid 1px #005bac;
    border-right: solid 1px #005bac;
    padding: 3px;
    @include g.mq("md") {
      top: calc(50% - 6px);
      padding: 5px;
    }
  }
  &__txt {
    display: inline-block;
    position: relative;
    color: #005bac;
    font-weight: bold;
    letter-spacing: 0.02em;
    font-size: g.rem(14);
    @include g.mq("md") {
      font-size: g.rem(16);
    }
  }
  &:nth-child(6n + 6) {
    @include g.mq("md") {
      margin-right: 0;
    }
  }
  &.icon-pdf {
    width: auto;
    .c-slist-anchor01__txt{
      padding-right: 10px;
      @include g.mq('md'){
        padding-right: 20px;
      }
    }
    &::after {
      border: 0;
      right: 0;
      top: 43%;
      transform: translateY(-50%);
      background: url(../../images/common/icon_pdf_01.svg) no-repeat
        center/contain;
      width: 20px;
      height: 21px;
      @include g.mq("md") {
        width: 26px;
        height: 27px;
      }
    }
  }
}

.c-slist-anchors02 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &.has-5btn {
    .c-slist-anchor02 {
      @include g.mq("md") {
        margin: 0 2px 2px 0;
      }
      @include g.mq("lg") {
        margin: 0 2px 15px 0;
      }
      &:nth-child(3n + 3) {
        @include g.mq("md") {
          margin-right: 0;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.has-4btn {
    .c-slist-anchor02 {
      @include g.mq("md") {
        margin: 0 2px 2px 0;
      }
      &:nth-child(2n + 2) {
        @include g.mq("sm") {
          margin-right: 0;
        }
        @include g.mq("lg") {
          margin-right: 2px;
        }
      }
      @include g.mq("lg") {
        width: 24.8%;
      }
      &:nth-child(4n + 4) {
        @include g.mq("md") {
          margin-right: 0;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.c-slist-anchor02 {
  width: 100%;
  background: #fff;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px 5px;
  padding: 25px 15px;
  &:hover {
    opacity: 1;
  }
  @include g.mq("sm") {
    width: 47%;
  }
  @include g.mq("md") {
    margin: 0 2px 2px;
    padding: 20px 15px;
    min-height: 100px;
    &:hover {
      // opacity: 0.8;
      .c-slist-anchor02__txt {
        &::after {
          transform: translateY(50%) rotate(135deg);
        }
      }
    }
  }
  @include g.mq("lg") {
    width: 33.104%;
  }

  &__txt {
    color: #005bac;
    font-size: g.rem(16);
    font-weight: bold;
    letter-spacing: 0.02em;
    line-height: 1.5em;
    display: inline-block;
    position: relative;
    padding-right: 30px;
    @include g.mq("md") {
      line-height: 1.7em;
      padding-right: 28px;
      font-size: g.rem(18);
    }
    @include g.mq("lg") {
      @include g.clamp-pt(padding-right, 19, 28, 1600);
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: calc(50% - 5px);
      -webkit-transform: translateY(-50%) rotate(135deg);
      transform: translateY(-50%) rotate(135deg);
      border-top: solid 1px #005bac;
      border-right: solid 1px #005bac;
      padding: 3px;
      transition: 0.4s ease-in-out;
      @include g.mq("md") {
        top: calc(50% - 5px);
        padding: 5px;
      }
    }
  }
  &.txt-l {
    text-align: left;
  }
}
