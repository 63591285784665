@use "../../global/" as g;

.l-company {
  position: relative;
  overflow: hidden;
  background: url("../../images/top/bk_company.webp") no-repeat right bottom;
  background-size: 100%;
  background-color: g.$blue02;
  padding-top: 50px;
  padding-bottom: 60px;

  @include g.mq(md) {
    padding-top: 138px;
    padding-bottom: 125px;
  }

  &__inner {
    position: relative;
    z-index: 3;
  }

  &__bk {
  }

  &__bk-content01 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    top: -11vw;
    right: -20vw;
    transform: rotate(1deg);

    @include g.mq(md) {
      top: -8vw;
      right: -16vw;
    }

    svg {
      // width: 100vw;
      aspect-ratio: 749 / 287;
      width: clamp(400px, calc(100vw / 1600 * 749), 749px);
    }

    // @include g.mq(lg) {
    //    bottom: -8%;
    //   left: -28%;
    // }
  }

  &__bk-content02 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    top: -10vw;
    right: -30vw;
    transform: rotate(-1deg);
    @include g.mq(md) {
      top: -2vw;
      right: -19vw;
    }
    svg {
      // width: 100vw;
      aspect-ratio: 786 / 158.5;
      // width: calc(100vw / 1600 * 800);
      width: clamp(400px, calc(100vw / 1600 * 786), 786px);
    }
  }
}
