@use "../../global/" as g;

.p-news-content {
  @include g.mq(md) {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    flex: 1;
  }

  &__right {
    margin-top: 30px;

    @include g.mq(md) {
      margin-top: 0;
      width: calc(860 / 1160 * 100%);
    }
  }
  &__title-en {
    color: g.$blue02;
  }

  &__link-item {
    border-bottom: 1px solid g.$border;
    padding-bottom: 12px;

    @include g.mq(md) {
      display: grid;
      grid-template-columns: 120px 1fr;
      padding-bottom: 20px;
    }

    + .p-news-content__link-item {
      margin-top: 28px;
    }
  }

  &__link-item-date {
    color: g.$blue02;
    letter-spacing: 0.02em;

    @include g.mq(md) {
      padding-left: 14px;
    }
  }

  &__link-item-subject {
    padding-right: 50px;
    position: relative;
    color: #8e8e8e;
    font-size: g.rem(16);
    letter-spacing: 0.02em;

    &:hover {
      .c-circle-arrow01 {
        transform: translateX(5px);
      }
    }

    .c-circle-arrow01 {
      right: 15px;
    }

    .c-circle-arrow01__arrow {
      background-color: transparent;

      &::after {
        width: 30px;
        height: 8px;
        border-bottom: 1px solid g.$blue02;
        border-right: 1px solid g.$blue02;
        top: calc(50% - 4px);
        left: calc(50% - 15px);
      }
    }
  }

  //   ボタン

  &__link-area {
    margin-top: 60px;

    .c-link-btn01 {
      &:hover {
        .c-link-btn01__link-box {
          background-color: g.$white;
          color: g.$blue02;
          border-color: g.$blue02;

          &::after {
            border-bottom: 1px solid g.$blue02;
            border-right: 1px solid g.$blue02;
          }
        }
      }
    }

    .c-link-btn01__link-box {
      background-color: g.$blue02;
      color: g.$white;

      &::after {
        border-bottom: 1px solid g.$white;
        border-right: 1px solid g.$white;
      }
    }
  }
}
