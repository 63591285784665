@use "../global/" as g;

.c-hamburger-menu {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 25px;
  cursor: pointer;
  transition: all 0.4s;

  @include g.mq(md) {
    width: 40px;
    height: 30px;
  }

  span {
    display: inline-block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: g.$blue02;
    transition: all 0.4s;
  }

  &__line01 {
    top: 0;
  }

  &__line02 {
    top: 11px;

    @include g.mq(md) {
      top: 13.5px;
    }
  }

  &__line03 {
    bottom: 0px;
  }

  .is-nav-open & {
    &__line01 {
      transform: translateY(11px) rotate(-315deg);

      @include g.mq(md) {
        transform: translateY(13.5px) rotate(-315deg);
      }
    }
    &__line02 {
      opacity: 0;
    }
    &__line03 {
      transform: translateY(-11px) rotate(315deg);

      @include g.mq(md) {
        transform: translateY(-13.5px) rotate(315deg);
      }
    }
  }
}
