@use "../global/" as g;

.c-content-width-mv {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;

  @include g.mq(md) {
    padding-left: 65px;
    padding-right: 65px;
  }
}
