@use "../global/" as g;

.c-section-title {
  display: flex;
  flex-direction: column;

  &__en {
    text-transform: uppercase;
    font-family: g.$JosefinSlab;
    font-size: g.rem(30);
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.2;

    @include g.mq(md) {
      @include g.clamp-fz(30, 55, 1160);
    }
  }

  &__ja {
    font-weight: 700;
    letter-spacing: 0.05em;
    font-size: g.rem(18);

    @include g.mq(md) {
      @include g.clamp-fz(18, 24, 1160);
    }
  }
}
