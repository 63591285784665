@use "../../global/" as g;

.l-case {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 60px;
  background: url("../../images/top/img_case-bk.webp") no-repeat right bottom;
  background-size: 100%;
  background-color: g.$blue02;
  z-index: 0;

  @include g.mq(md) {
    display: block;
    padding-top: 73px;
    padding-bottom: 80px;
    background-size: 65%;
  }

  &::before {
    @include g.mq(md) {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: g.$gray03;
      width: calc(560 / 1600 * 100%);
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
  }

  &__bk {
    display: none;
    @include g.mq(md) {
      display: block;
    }
  }

  &__bk-content01 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: -9vw;
    left: 0vw;
    transform: rotate(11deg);

    @include g.mq(md) {
      bottom: -9vw;
      left: 14vw;
      transform: rotate(11deg);
    }

    svg {
      // width: 100vw;
      aspect-ratio: 749 / 287;
      width: clamp(400px, calc(100vw / 1600 * 749), 749px);
    }

    // @include g.mq(lg) {
    //    bottom: -8%;
    //   left: -28%;
    // }
  }

  &__bk-content02 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: -3vw;
    left: 0vw;
    transform: rotate(2deg);
    display: none;

    @include g.mq(md) {
      display: block;
      bottom: -3vw;
      left: 15vw;
    }
    svg {
      // width: 100vw;
      aspect-ratio: 795 / 172.8;
      // width: calc(100vw / 1600 * 800);
      width: clamp(400px, calc(100vw / 1600 * 795), 795px);
    }
  }
}
