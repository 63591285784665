@use "../../global/"as g;

.p-under-top {
  &__title-en {
    color: g.$blue02;
    font-size: g.rem(34);
    font-weight: 700;
    line-height: 1;

    @include g.mq(md) {
      @include g.clamp-fz(34, 64, 1160);
    }
  }

  &__title-ja {
    font-size: g.rem(24);
    line-height: 1;
    margin-top: 8px;
    font-weight: 500 !important;

    @include g.mq(md) {
      @include g.clamp-fz(24, 40, 1160);
      margin-top: 17px;
    }
  }
}

.p-under-top01 {
  &__main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include g.mq('md') {
      flex-wrap: nowrap;
      align-items: center;
    }

    &.no-title {
      @include g.mq('md') {
        align-items: flex-start;
      }
    }
  }

  &__img {
    width: 100%;
    flex-shrink: 0;
    text-align: center;
    margin: 0 auto;
    position: relative;

    @include g.mq('md') {
      width: 48.276%;
      margin: 0;
      top: 4px;
    }
  }

  &__info {
    width: 100%;
    margin-bottom: 0;

    @include g.mq('md') {
      width: 48.276%;
    }

    &.has-margin {
      margin-bottom: 30px;

      @include g.mq('md') {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    margin-bottom: 20px;

    @include g.mq('md') {
      margin-bottom: 31px;
    }

    .en {
      position: relative;
      font-family: g.$JosefinSlab;
      font-size: g.rem(20);
      font-weight: bold;
      letter-spacing: 0.02em;
      color: #005BAC;
      display: block;
      line-height: 1.7em;
      margin-bottom: 8px;

      @include g.mq('md') {
        top: 5px;
        font-size: g.rem(24);
      }
    }

    .jp {
      font-size: g.rem(26);
      font-weight: 500;
      letter-spacing: 0.02em;
      color: #232536;
      display: block;
      line-height: 1.7em;

      @include g.mq('md') {
        @include g.clamp-fz(32, 40, 1160);
      }
    }
  }

  &__desc {
    color: #232536;
    font-size: g.rem(14);
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 2em;

    @include g.mq('md') {
      font-size: g.rem(16);
    }
  }

  &__title01 {
    font-size: g.rem(26);
    font-weight: 500;
    color: #005BAC;
    letter-spacing: 0.02em;
    line-height: 1.7em;
    margin-bottom: 20px;

    @include g.mq('md') {
      font-size: g.rem(40);
      margin-bottom: 17px;
    }

    &.mb40 {
      @include g.mq('md') {
        margin-bottom: 41px;
      }
    }

    &.mb20 {
      @include g.mq('md') {
        margin-bottom: 20px;
      }
    }
    &.has-logotxt{
      padding-left: 70px;
      margin-bottom: 8px;
      @include g.mq('md'){
        padding-left: 110px;
        margin-bottom: 18px;
      }
      position: relative;
      &::before{
        position: absolute;
        content: '';
        left:0;
        top: 0;
        background: url(../../images/common/img_utop_main_logo_01.webp) no-repeat center/contain;
        width: 65px;
        height: 44px;
        @include g.mq('md'){
          width: 85px;
          height: 64px;
          top: 3px;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    @include g.mq('md') {
      margin-bottom: 26px;
    }

    &.txt-2line {
      .p-under-top01__icon {
        padding: 3px 2px;
        height: 60%;
      }

      .p-under-top01__txt {
        padding: 10px 2px;
        height: 40%;

        @include g.mq('md') {
          padding: 3px 2px 2px;
        }
      }
    }
  }

  &__item {
    margin: 0 6px 10px 0;
    width: 50px;
    border: solid 1px #005BAC;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @include g.mq('md') {
      margin: 0 8px 10px 0;
      width: 62px;
    }
    &:last-child {
      margin: 0 0 10px 0;
    }
  }

  &__icon,
  &__txt {
    width: 100%;
    text-align: center;
  }

  &__icon {
    height: 64%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;

    .icon {
      line-height: 0;
      display: block;
      margin: auto;
    }
  }

  &__txt {
    height: 36%;
    background: #005BAC;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 2px;

    .txt {
      color: #fff;
      font-family: 'Noto Sans JP', sans-serif;
      font-size: g.rem(12);
      font-weight: 500;
      line-height: 1.2em;
      letter-spacing: 0.02em;
      display: block;

      @include g.mq('md') {
        font-size: g.rem(14);
      }
    }

    .txt-small01 {
      font-size: g.rem(11);
      display: block;

      @include g.mq('md') {
        font-size: g.rem(12);
      }
    }

    .txt-small02 {
      position: relative;
      display: block;
      font-size: g.rem(10);
      line-height: 1em;

      @include g.mq('md') {
        top: -2px;
      }
    }
  }

  &__item {
    &.icon-01 {
      .icon {
        width: 20px;
        @include g.mq('md') {
          width: 24px;
        }
      }
    }

    &.icon-02 {
      .icon {
        width: 27px;
        @include g.mq('md') {
          width: 31px;
        }
      }
    }

    &.icon-03 {
      .icon {
        width: 40px;
        @include g.mq('md') {
          width: 45px;
        }
      }
    }

    &.icon-04 {
      .icon {
        width: 24px;
        @include g.mq('md') {
          width: 28px;
        }
      }
    }

    &.icon-05 {
      .icon {
        width: 26px;
        @include g.mq('md') {
          width: 31px;
        }
      }
    }

    &.icon-06 {
      .icon {
        width: 34px;
        @include g.mq('md') {
          width: 38px;
        }
      }
    }

    &.icon-07 {
      .icon {
        width: 20px;
       @include g.mq('md') {
        width: 24px;
       }
      }
    }

    &.icon-08 {
      .icon {
        width: 16px;
        @include g.mq('md') {
          width: 20px;
        }
      }
    }
  }

  &__label {
    margin-bottom: 20px;

    @include g.mq('md') {
      margin-bottom: 36px;
    }

    .txt {
      border: solid 1px #005BAC;
      color: #005BAC;
      display: inline-block;
      padding: 5px 12px 4px;
      line-height: 1.2em;
      border-radius: 5px;
      font-size: g.rem(14);
      font-weight: 500;
      letter-spacing: 0.02em;
      text-align: center;

      @include g.mq('md') {
        padding: 4px 9px 5px;
        font-size: g.rem(16);
      }
    }
  }
}