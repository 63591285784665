@use "../global/" as g;

.c-section-stitle {
  .en {
    position: relative;
    text-transform: uppercase;
    font-family: g.$JosefinSlab;
    font-size: g.rem(18);
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.2;
    color: #005BAC;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 4px;
    @include g.mq(md) {
      padding-left: 26px;
      margin-bottom: 12px;
      @include g.clamp-fz(18, 20, 1160);
    }
    &::before{
      position: absolute;
      content: '';
      border-radius: 100%;
      border: solid 2px #005BAC;
      width: 10px;
      height: 10px;
      top: 5px;
      left: 0;
      @include g.mq('md') {
        border: solid 3px #005BAC;
        width: 16px;
        height: 16px;
        top: 2px;
        left: 0;
      }
    }
  }
  .jp {
    display: block;
    font-weight: 500;
    letter-spacing: 0.05em;
    font-size: g.rem(24);
    color: #232536;
    line-height: 1.5em;
    @include g.mq(md) {
      @include g.clamp-fz(24, 32, 1160);
    }
  }

  .c-section-title__en{
    color: #005BAC;
  }
  &01{
    position: relative;
    font-size: g.rem(24);
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1.7em;
    padding-bottom: 10px;
    color: #005BAC;
  
    @include g.mq('md'){
      padding-bottom: 16px;
      font-size: g.rem(32);
    }
    &::after{
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #005BAC 50%, #78C1F7 50%) no-repeat;
      @include g.mq('md'){
        bottom: -1px;
      }
    }
    &__txt{
      display: inline-block;
    }
    &.has-label{
      .c-section-stitle01__txt{
        margin-right: 7px;
        @include g.mq('md'){
          margin-right: 17px;
        }
      }
      .c-section-stitle01__label{
        bottom: 5px;
        @include g.mq('md'){
          bottom: 7px;
          min-width: 85px;
        }
      }
    }
    &.has-txtsmall{
      .c-section-stitle01__txt{
        margin-right: 2px;
      }
    }
    &__label{
      margin-bottom: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 8px 10px;
      border: solid 1px #005BAC;
      border-radius: 5px;
      position: relative;
      @include g.mq('md'){
        padding: 4px 8px 5px;
      }
      .txt{
          display: inline-block;
          line-height: 1.2em;
          font-size: g.rem(14);
          font-weight: 500;
          letter-spacing: 0.02em;
          color: #005BAC;
      
          @include g.mq('md'){
              font-size: g.rem(16);
          }
      }
    }
    &__txtsmall{
      font-size: g.rem(16);
      font-weight: 500;
      letter-spacing: 0.02em;
      line-height: 1.7em;
      @include g.mq('md'){
        font-size: g.rem(20);
      }
      @include g.mq('xl') {
        line-height: 2.77em;
      }
    }
  }
  &02{
    color: #005BAC;
    font-size: g.rem(16);
    font-weight: bold;
    letter-spacing: 0.02em;
    line-height: 1.7em;
    @include g.mq('md'){
      font-size: g.rem(20);
    }
    &.color01{
      color: #232536;
    }
  }
  &03{
    position: relative;
    color: #005BAC;
    font-size: g.rem(14);
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1.5em;
    margin-bottom: 5px;
    padding-left: 20px;
    @include g.mq('md') {
        padding-left: 20px;
        margin-bottom: 10px;
        font-size: g.rem(16);
    }
    &::before{
        position: absolute;
        content:'';
        top: 7px;
        left: 0;
        width: 8px;
        height: 8px;
        background: #005BAC;
        @include g.mq('md') {
            width: 10px;
            height: 10px;
            top:8px;
        }
    }
  }
}
