@use "../../global/" as g;

.p-under {
    &-box {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        margin-bottom: 60px;
        pointer-events: none;

        &:hover {
            .p-under-box__img {
                a {
                    opacity: 1;
                }

                img {
                    transform: scale(1.1);
                }
            }

            .c-circle-arrow01__arrow {
                transform: translateX(5px);
            }
        }

        @include g.mq("md") {
            flex-direction: row;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-bottom: 120px;
        }
        &.flex-start{
            align-items: flex-start;
        }
        &:last-child {
            margin-bottom: 0!important;
        }

        .c-section-title {
            margin-bottom: 30px;

            @include g.mq("md") {
                @include g.clamp-pt(margin-bottom, 10, 28, 1160);
            }

            .c-section-title__en {
                color: #005bac;

                @include g.mq("md") {
                    margin-bottom: -4px;
                }
            }
        }

        &__img,
        &__main {
            width: 100%;

            @include g.mq("md") {
                width: 48.277%;
            }
        }

        &__img {
            overflow: hidden;

            a {
                display: block;
                pointer-events: auto;
            }

            img {
                transition: 0.4s ease-in-out;
            }
        }

        &__main {
            position: relative;
            .c-sbtn__link {
                pointer-events: auto;
            }
        }

        &__img {
            margin-bottom: 30px;

            @include g.mq("md") {
                margin-bottom: 0;
            }
            .img{
                margin-bottom: 20px;
                @include g.mq('md'){
                    margin-bottom: 40px;
                }
                &.last{
                    margin-bottom: 0;
                }
            }
        }
        .desc{
            color: #232536;
            line-height:2em;
            font-size: g.rem(14);
            letter-spacing: 0.02em;
            @include g.mq('md') {
                font-size: g.rem(16);
            }
        }
        &__desc {
            font-size: g.rem(16);
            letter-spacing: 0.02em;
            color: #232536;
            margin-bottom: 20px;
            margin-top: 12px;

            @include g.mq("md") {
                // @include g.clamp-pt(padding-right, 1, 55, 1160);
                @include g.clamp-pt(margin-bottom, 1, 55, 1160);
                font-size: g.rem(18);
                margin-top: 24px;
            }
        
        }

        &__reverse {
            flex-direction: column;

            @include g.mq("md") {
                flex-direction: row;
            }
            .p-under-box__desc {
                padding-right: 0;
            }
        }
        &__ttl{
            font-size: g.rem(24);
            font-weight: 500;
            color: #005bac;
            letter-spacing: 0.02em;
            line-height: 1.7em;
            @include g.mq('md'){
                font-size: g.rem(26);
            }
            @include g.mq('xl'){
                font-size: g.rem(32);
            }
        }
        &.no-margin{
            .p-under-box__main, .p-under-box__desc{
                margin: 0;
            }
        }
        &.no-padding{
            .p-under-box__main, .p-under-box__desc{
                padding: 0;
            }
        }
        &__panel{
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
            .ttl, .desc{
                font-size: g.rem(14);
                letter-spacing: 0.02em;
                @include g.mq('md') {
                    font-size: g.rem(16);
                }
            }
            .ttl{
                font-weight: bold;
                color: #005BAC;
                line-height: 1.7em;
            }
            .desc{
                color: #232536;
                line-height:2em;
            }
            .c-section-stitle02{
                margin-bottom: 8px;
                @include g.mq('md'){
                    margin-bottom: 12px;
                }
            }
        }
        &__frame{
            padding: 15px;
            background: #F8FAF9;
            
            @include g.mq('md'){
                padding: 25px;
            }
            .desc{
                font-weight: 500;
                letter-spacing: 0.02em;
                font-size: g.rem(14);
                color: #232536;
                @include g.mq('md'){
                    line-height: 2em;
                    font-size: g.rem(16);
                }
            }
        }
        &__label{
            display: inline-block;
            border: solid 1px #005BAC;
            background: #fff;
            margin-bottom: 10px;
            border-radius: 5px;
            padding: 2px 10px;
            @include g.mq('md'){
                padding: 0 9px;
                margin-bottom: 12px;
            }
            .txt{
                display: inline-block;
                line-height: 1.2em;
                font-size: g.rem(14);
                font-weight: 500;
                letter-spacing: 0.02em;
                color: #005BAC;
            
                @include g.mq('md'){
                    font-size: g.rem(16);
                }
            }
        }
        &__video{
            width: 100%;
            position: relative;
            a{
                position: relative;
                display: block;
                text-decoration: none;
                @include g.mq('md') {
                    img{
                        transition: all 0.5s;
                    }
                    &:hover{
                        img{
                            opacity: 0.7;
                            transform: none;
                        }
                        &::before{
                            background: url(../../images/common/icon_youtube_01.svg) center center/contain no-repeat;
                        }
                    }
                }
                &::before {
                    content: "";
                    display: block;
                    width: 58px;
                    height: 40px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    z-index: 1;
                    transform: translate(-50%,-50%);
                    transition: 0.3s ease-in-out;
                    background: url(../../images/common/icon_youtube_02.svg) center center/contain no-repeat;
                    @include g.mq('md'){
                        width: 64px;
                        height: 45px;
                    }
                }
            }
        }
        &__section{
            padding-top: 40px;
            margin-bottom: 42px;
            border-top: solid 1px #D1D4D7;
            @include g.mq('md'){
                padding-top: 79px;
                margin-bottom: 84px;
            }
           &.no-padding {
            padding: 0;
           }
           &.no-border{
            border: 0;
           }
           &:last-child{
            padding-bottom: 0;
            margin-bottom: 0;
           }
        }
        &.has-label{
            .p-under-box__ttl{
                position: relative;
                margin-bottom: 8px;
                @include g.mq('md'){
                    top: -2px;
                    margin-bottom: 13px;
                }
            }
            .p-under-box__label{
                margin-bottom: 10px;
                @include g.mq('md'){
                    margin-bottom: 24px;
                }
            }
            .p-under-box__desc{
                margin-top: 0;
                margin-bottom: 15px;
                @include g.mq('md'){
                    margin-bottom: 36px;
                }
            }
        }
        &__bg01{
            padding:50px 30px;
            background: #005BAC;

            @include g.mq('xl'){
                padding: 60px 60px 58px;
            }
            .p-under-box{
                margin-bottom: 35px;
                @include g.mq('md'){
                    margin-bottom: 40px;
                }
            }
            .p-under-box__ttl, .p-under-box__desc, .c-sbtn__txt{
                color: #fff;
            }
            .c-circle-arrow01__arrow{
                background: #fff;
                &::after{
                    border-color: #005BAC;
                }
            }
            .p-under-box__ttl{
                position: relative;
                margin-bottom: 5px;
                @include g.mq('xl'){
                    top: -4px;
                    margin-bottom: 0;
                }
            }
            .p-under-box__desc{
                margin-top: 0;
                margin-bottom: 24px;
                @include g.mq('xl'){
                    margin-top: 5px;
                    margin-bottom: 56px;
                }
            }
        }
        &__block{
            margin-bottom: 10px;
            @include g.mq('md'){
                margin-bottom: 15px;
            }
            &:last-child{
                margin-bottom: 0;
            }
            .desc{
                font-weight: 500;
                letter-spacing: 0.02em;
                font-size: g.rem(14);
                color: #232536;
                padding-left: 10px;
                @include g.mq('md'){
                    padding-left: 17px;
                    line-height: 2em;
                    font-size: g.rem(16);
                }
            }
        }
    }
    &-box-style01 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 60px;

        @include g.mq("md") {
            flex-direction: row;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-bottom: 100px;
        }
        @include g.mq('xl'){
            align-items: flex-start;
        }
        .c-section-stitle .en::before {
            top: 5px;

            @include g.mq('md') {
                top: 1px;
            }
        }

     
        &__reverse {
            flex-direction: column-reverse;

            @include g.mq("md") {
                flex-direction: row;
            }

            .p-under-box01__info {
                @include g.mq('md') {
                    padding-left: 10px;
                }
            }
        }

        &__img {
            width: 100%;
            flex-shrink: 0;

            @include g.mq('md') {
                width: 47%;
            }

            @include g.mq('lg') {
                width: 48.276%;
            }
        }

        &__info {
            width: 100%;
            margin-bottom: 30px;

            @include g.mq('md') {
                width: 48%;
                margin-bottom: 0;
            }

            @include g.mq('xl') {
                width: 43%;
            }
        }

        &__desc {
            .desc {
                color: #232536;
                letter-spacing: 0.02em;
                font-weight: 400;
                font-size: g.rem(16);

                @include g.mq('md') {
                    font-size: g.rem(18);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &:last-child {
            margin-bottom: 0!important;
        }

        .c-slist-btn {
            justify-content: flex-start;
            margin: 24px 0 0;
            @include g.mq('md'){
                margin: 56px 0 0;
            }
            .c-link-btn01 {
                margin: 0 0 10px;
                @include g.mq('md'){
                    margin: 0 0 10px;
                }
                &:last-child {
                    margin: 0;
                    @include g.mq('md'){
                        margin: 0;
                    }
                }
                
                &__link-box{
                    @include g.mq('md'){
                        padding: 14px 16px 18px;
                    }
                    &::after{
                        @include g.mq('md'){
                            top: calc(50% - 9px);
                        }
                    }
                }
            }
        }
    }
}
