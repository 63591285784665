@use "../../global/" as g;

.p-product-content {
  padding-bottom: 60px;
  @include g.mq(md) {
    padding-bottom: 80px;
  }
  &__title {
    @include g.mq(md) {
      align-items: center;
    }
  }

  &__title-en {
    color: g.$blue02;
  }

  &__top {
    margin-top: 36px;

    @include g.mq(md) {
      margin-top: 75px;
    }
  }

  &__service-lists {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;

    @include g.mq(md) {
      column-gap: 1px;
    }

    @include g.mq(lg) {
      gap: 1px;
    }
  }

  &__service-item {
    color: g.$white;
    width: 100%;

    @include g.mq(md) {
      width: calc((100% - 2px) / 2);
    }

    @include g.mq(lg) {
      width: calc((100% - 3px) / 4);
    }

    .c-list-item01__body {
      padding: 14px 16px 16px;
      background-color: g.$blue02;

      @include g.mq(md) {
        padding: 12px 30px 16px;
      }
    }

    .c-list-item01__title {
      @include g.lh(20, 30);
    }

    .c-circle-arrow01 {
      top: calc(50% - 12px);

      @include g.mq(md) {
        top: calc(50% - 17px);
      }
    }

    .c-circle-arrow01__arrow {
      background-color: g.$white;

      &:after {
        border-bottom: 1px solid g.$blue02;
        border-right: 1px solid g.$blue02;
      }
    }
  }
}
