@use "../../global/" as g;

.l-mv {
  position: relative;
  min-height: calc(100vh - 75px);
  background-color: g.$gray03;
  overflow: hidden;

  @include g.mq(md) {
    min-height: auto;
    height: auto;
  }

  // アニメーション
  &__sp-bk {
    @include g.mq(md) {
      display: none;
    }
  }

  &__sp-bk-content01 {
    svg {
      transform: rotate(-3deg);
      aspect-ratio: 479 / 150;
      width: clamp(479px, calc(100vw / 1600 * 479), 479px);
    }

    position: absolute;
    z-index: 1;
    // bottom: -44vw;
    // right: -158vw;
    bottom: -10vw;
    right: -72vw;
  }

  &__sp-bk-content02 {
    svg {
      transform: rotate(-7deg);
      aspect-ratio: 801 / 269;
      width: clamp(400px, calc(100vw / 1600 * 801), 801px);
    }

    position: absolute;
    z-index: 1;
    bottom: -16vw;
    right: -36vw;
  }

  &__pc-bk {
    display: none;

    @include g.mq(md) {
      display: block;
    }
  }

  //   bk パス
  &__bk-content01 {
    svg {
      // width: 100vw;
      aspect-ratio: 801 / 269;
      width: clamp(400px, calc(100vw / 1600 * 801), 801px);
    }
    @include g.mq(md) {
      position: absolute;
      z-index: 1;
      // top: -9vw;
      top: -3vw;
      left: -18.5vw;
      transform: rotate(-1deg);
    }

    // @include g.mq(lg) {
    //   top: -8%;
    //   left: -28%;
    // }
    @include g.mq(lg) {
      // top: -5.5vw;
      top: -3vw;
      left: -18.5vw;
    }

    @include g.mq(xl) {
      // top: -2.5vw;
      top: -2vw;
      left: -18.5vw;
    }

    @include g.mq(xxl) {
      top: -1.5vw;
      left: -18.5vw;
    }
  }

  &__bk-content02 {
    svg {
      // width: 100vw;
      aspect-ratio: 794.96 / 172.74;
      // width: calc(100vw / 1600 * 800);
      width: clamp(400px, calc(100vw / 1600 * 795), 795px);
    }
    @include g.mq(md) {
      position: absolute;
      z-index: 1;
      // top: -6.5vw;
      top: -3.5vw;
      left: -9.5vw;
    }

    @include g.mq(lg) {
      top: -3.5vw;
      left: -9.5vw;
    }

    @include g.mq(xl) {
      // top: -3.5vw;
      // left: -9.5vw;
      top: -2.5vw;
      left: -9.5vw;
    }

    @include g.mq(xxl) {
      top: -2.5vw;
      left: -9.5vw;
    }
  }
}
.ball {
  opacity: 0;
  visibility: hidden;
  animation: fadeIn 4s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  80% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
