@use "../../global/" as g;

.p-contact-content {
  @include g.mq(md) {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    color: g.$white;

    @include g.mq(md) {
      width: calc(540 / 1160 * 100%);
    }
  }
  
  &__text {
    margin-top: 20px;
    letter-spacing: 0.02em;

    @include g.mq(md) {
      margin-top: 29px;
      font-size: g.rem(16);
    }
  }

  &__right {
    flex: 1;
  }

  //   ボタン

  &__link-area {
    margin-top: 30px;

    @include g.mq(md) {
      margin-top: 60px;
    }

    .c-link-btn01 {
      @include g.mq(md) {
        margin-right: 50px;
      }
    }
  }
}
