@use "sass:math";
@use "../global" as g;

/*!
component > Loading
------------------------------
*/

.is-loadding .c_loading {
  opacity: 1;
  pointer-events: all;
}
.c_loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #535763;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  &__content {
    position: relative;
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px);
  }
  &__item {
    background-color: #fff;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    margin-left: 15px;
    top: 4px;
    left: -7px;
    &:first-child {
      -webkit-animation: ball-zig 0.7s 0s infinite linear;
      animation: ball-zig 0.7s 0s infinite linear;
    }
    &:last-child {
      -webkit-animation: ball-zag 0.7s 0s infinite linear;
      animation: ball-zag 0.7s 0s infinite linear;
    }
  }
}

@-webkit-keyframes ball-zig {
  33% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px);
  }

  66% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes ball-zig {
  33% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px);
  }

  66% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@-webkit-keyframes ball-zag {
  33% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px);
  }

  66% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes ball-zag {
  33% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px);
  }

  66% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
