@use "../global/" as g;
.c-sframe-btns{
    .c-link-btn01__link-box {
        background-color: #fff;
        border: solid 1px #fff;
        color: #005BAC;
        padding: 10px;
        letter-spacing: 0.02em;
        font-size: g.rem(16);
        &::after{
            border-color: #005BAC;
        }
        @include g.mq('md') {
            padding: 16px;
            font-size: g.rem(18);
        }
    }
    &.c-slist-btn {
        justify-content: space-between;
        flex-wrap: wrap;
        @include g.mq('md'){
            justify-content: flex-start;
        }
        @include g.mq('xl'){
            flex-wrap: inherit;
        }
        .c-link-btn01{
            margin: 0 auto 20px;
            max-width: 320px;
            @include g.mq('sm'){
                margin: 0;
                max-width: 48%;
                &:hover{
                    .c-link-btn01__link-box{
                        background-color: #005BAC;
                        color: #fff;
                        border: solid 1px #fff;
                        &::after{
                            border-color: #fff;
                        }
                    }
                }
            }
            @include g.mq('md'){
                max-width: 260px;
                margin: 0 40px 20px 0;
            }
            &:last-child{
                margin: 0 auto;
                @include g.mq('sm'){
                    margin: 0;
                }
            }
        }
        &.has-4btn{
            justify-content:space-between;
            .c-link-btn01{
                margin: 0 0 20px;
                @include g.mq('sm'){
                    max-width: 48%;
                }
                @include g.mq('xl'){
                    max-width: 260px;
                }
                &:nth-last-child(-n + 2){
                   @include g.mq('sm') {
                    margin:0;
                   }
                   @include g.mq('md') {
                    margin: 0 0 20px;
                   }
                }
                &:last-child{
                    margin: 0;
                }
            }
        }
        &.has-3btn{
            justify-content: center;
            @include g.mq('xl') {
                justify-content: flex-start;
            }
            .c-link-btn01{
                margin: 0 0 20px;
                @include g.mq('sm'){
                    margin: 0 1% 20px;
                    max-width: 48%;
                }
                @include g.mq('xl'){
                    max-width: 260px;
                    margin: 0 40px 20px 0;
                }
                &:last-child{
                    margin: 0;
                    @include g.mq('md') {
                        margin: 0 0 20px;
                    }
                }
            }
        }
    }
    .c-link-btn01__link-box::after{
        @include g.mq('md') {
            top: calc(50% - 9px);
            right: 34px;
        }
    }
}
.c-sframe{
    position: relative;
    background: #005BAC;
    padding: 25px 25px 40px;
    overflow: hidden;
    @include g.mq('md') {
        padding: 30px 30px 50px;
    }
    @include g.mq('xl'){
        padding:38px 80px 85px 80px;
    }
    &.equal-padding{
        @include g.mq('xl'){
            padding:38px 80px 42px;
        }
        &::after{
            @include g.mq('lg') {
                right: -15px;
            }
        }
    }
    *{
        position: relative;
        z-index: 1;
    }
    &::after{
        position: absolute;
        content:'';
        bottom: 0;
        right: -20%;
        background: url(../../images/common/bg_frame.webp) no-repeat center/cover;
        width: 338px;
        height: 100%;
        @include g.mq('md') {
            width: 300px;
            right: 0;
        }
        @include g.mq('lg') {
            width: 338px;
        }
    }
    &__ttl01{
        position: relative;
        font-size: g.rem(20);
        font-weight: 500;
        letter-spacing: 0.02em;
        color: #fff;
        line-height: 1.7em;
        margin-bottom: 20px;
        padding-left: 15px;
        @include g.mq('md'){
            padding-left: 22px;
            margin-bottom: 26px;
            font-size: g.rem(24);
        }
        &::before{
            position: absolute;
            content: '';
            left:0;
            top: 12px;
            border-radius: 100%;
            background: #fff;
            width: 8px;
            height: 8px;
            @include g.mq('md'){
                width: 10px;
                height: 10px;
                top: 16px;
            }
        }
    }
    &__ttl02{
        border-bottom: solid 2px #fff;
        color: #fff;
        font-size: g.rem(20);
        font-weight: 500;
        letter-spacing: 0.05em;
        line-height: 1.7em;
        margin-bottom: 15px;
        padding-bottom: 15px;
        @include g.mq('md') {
            padding-bottom: 17px;
            margin-bottom: 35px;
            font-size: g.rem(24);
        }
    }
    &__txts{
        > li{
            position: relative;
            font-size: g.rem(14);
            font-weight: 500;
            letter-spacing: 0.02em;
            color: #fff;
            line-height: 1.7em;
            margin-bottom: 10px;
            padding-left: 20px;
            @include g.mq('md'){
                padding-left: 23px;
                margin-bottom: 8px;
                font-size: g.rem(20);
                line-height: 2.04em;
            }
            &:last-child{
                margin-bottom: 0;
            }
            &::before{
                position: absolute;
                content: '';
                left:0;
                top: 8px;
                border-radius: 100%;
                background: #fff;
                width: 8px;
                height: 8px;
                @include g.mq('md'){
                    width: 10px;
                    height: 10px;
                    top: 16px;
                }
            }
        }
    }
    &__main{
        &.has-img{
            display: flex;
            flex-wrap: wrap;
            @include g.mq('md'){
                justify-content: space-between;
                flex-wrap: nowrap;
            }
            .c-sframe__img{
                position: relative;
                width: 100%;
                text-align: center;
                margin: 30px auto 0;
                @include g.mq('md'){
                    width: 30%;
                    top: 5px;
                    margin: 0 auto;
                }
            }
            .c-sframe__info{
                width: 100%;
                @include g.mq('md'){
                    width: 70%;
                    padding-right: 20px;
                }
                @include g.mq('lg'){
                    padding-right: 46px;
                }
            }
        }
    }
    &__info{
        width: 100%;
    }
    &__item{
        margin-bottom: 20px;
        @include g.mq('md'){
            margin-bottom: 26px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    &__pics{
        &.has-2pic{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @include g.mq('md'){
                flex-wrap: nowrap;
            }
            .c-sframe__pic{
                margin: 0 auto 30px;
                @include g.mq('md'){
                    width: 48%;
                    margin: 0;
                }  
                &:last-child{
                    margin-bottom: 0;
                    @include g.mq('md') {
                        margin: 0;
                    }
                }    
            }
        }
        &.has-3pic{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @include g.mq('md'){
                flex-wrap: nowrap;
                justify-content: flex-start;
            }
            .c-sframe__pic{
                width: 100%;
                margin: 0 auto 30px;
                @include g.mq('sm'){
                    width: 45%;
                }
                @include g.mq('md'){
                    width: 30.6%;
                    margin: 0 40px 0 0;
                } 
                &:last-child{
                    margin: 0 auto;
                    @include g.mq('md'){
                        margin: 0;
                    }
                }     
            }
        }
    }
    &__pic{

    }
}